import React, { useState } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MenuItem from './menuItem';
import Styles from './styles';

interface Props {
  text: string;
  icon: JSX.Element;
  items: {
    key: string;
    text: string;
    link: string;
    icon: JSX.Element;
  }[];
}

const ExpandableMenuItem: React.FC<Props> = (props: Props) => {
  const { text, icon, items } = props;

  const [isOpen, setIsOpen] = useState(false);

  const classes = Styles();

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemIcon className={classes.menuItemIcon}>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" className={classes.nestedList} disablePadding>
          {items.map((child) => (
            <MenuItem
              nested
              key={child.key}
              icon={child.icon}
              link={child.link}
              text={child.text}
            />
          ))}
        </List>
      </Collapse>{' '}
    </>
  );
};

export default ExpandableMenuItem;
