/**
 * actions types
 */
export enum SpeciesTypes {
  SPECIES_REQUEST = '@species/SPECIES_REQUEST',
  SPECIES_SUCCESS = '@species/SPECIES_SUCCESS',
  SPECIES_FAILURE = '@species/SPECIES_FAILURE',
}

/**
 * data types
 */
export interface Species {
  externalId: string;
  portugueseName: string;
}

/**
 * state type
 */
export interface SpeciesState {
  readonly data: Species[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
}
