import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Link, useHistory } from 'react-router-dom';
import LinkImg from '@material-ui/core/Link';
import { Container, Button, Typography, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as AuthAction from '../../store/modules/auth/actions';
import * as UsersAction from '../../store/modules/users/actions';
import { Credentials, AuthState } from '../../store/modules/auth/types';
import { ApplicationState } from '../../store';
import Styles from './styles';
import logoImg from '../../assets/logoOpenTuna.png';
import WrongCredentials from './components/WrongCredentials';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import { UsersState } from '../../store/modules/users/types';

interface StateProps {
  auth: AuthState;
  users: UsersState;
}

interface DispatchProps {
  signResquest(user: Credentials): void;
  removeMe(): void;
}

type Props = StateProps & DispatchProps;

const Login: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { t } = useTranslation();
  const { signResquest, auth, users, removeMe } = props;
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(async () => {
    signResquest({ username, password });
  }, [username, password, signResquest]);

  useEffect(() => {
    if (auth.signed) {
      history.push('/redirect');
    } else {
      removeMe();
    }
  }, [auth.signed, history, removeMe]);

  return (
    <div className={styles.root}>
      <Container className={styles.content}>
        <LinkImg href="/" className={styles.link}>
          <img className={styles.img} src={logoImg} alt="OpenTuna" />
        </LinkImg>

        <Form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
          <Typography variant="h3" color="primary">
            {t('login')}
          </Typography>
          <Typography variant="h5" color="primary" className={styles.textLower}>
            {t('exclusive_access_fishing_owners')}
          </Typography>

          {auth.error && <WrongCredentials />}

          <div className={styles.inputDiv}>
            <TextField
              autoFocus
              fullWidth
              name="email"
              label={t('email')}
              type="email"
              onChange={(e) => setUsername(e.target.value)}
            />

            <TextField
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className={styles.bottomDiv}>
            <Link className={styles.a} to="/forgotpassword">
              {t('forget_passowrd')}
            </Link>

            {!auth.loading && !users.userLoading && (
              <Button
                className={styles.button}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('login')}
              </Button>
            )}
            {auth.loading && <LoaderTailSpin />}
          </div>
        </Form>
      </Container>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    auth: state.auth,
    users: state.users,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...AuthAction, ...UsersAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
