import api from './api';
import { getAccessToken } from '../localStorageService';
import { Password } from '../../store/modules/password/types';

export const passwordRequest = (email: string) =>
  api.post('/password/send-forgot-password-token', { email });

export const changePasswordRequest = (newPassword: Password) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return api.post(
    '/password/reset-password',
    { oldPassword: newPassword.keypass, ...newPassword },
    config
  );
};

export const resetPasswordRequest = (paramsPassword: Password) => {
  const {
    keypass: oldPassword,
    newPassword,
    newPasswordConfirmation,
  } = paramsPassword;

  return api.post(`password/reset-forgotten-password?token=${oldPassword}`, {
    newPassword,
    newPasswordConfirmation,
  });
};
