import { action } from 'typesafe-actions';
import {
  ErrorTypes,
  UserErrors,
  FilterErrors,
  PasswordErrors,
  PublicGeneratorContentErrors,
  HomeContentErrors,
  AboutContentErrors,
  SpeciesContentErrors,
  ByCatchContentErrors,
} from './types';

export const userViolation = (userErrors: UserErrors) =>
  action(ErrorTypes.USER_VIOLATION, userErrors);
export const clearUserViolation = () => action(ErrorTypes.CLEAR_USER_VIOLATION);

export const filterViolation = (filterErros: FilterErrors) =>
  action(ErrorTypes.FILTER_VIOLATION, filterErros);
export const clearFilterViolation = () =>
  action(ErrorTypes.CLEAR_FILTER_VIOLATION);

export const passwordViolation = (forgotErrors: PasswordErrors) =>
  action(ErrorTypes.PASSWORD_VIOLATION, forgotErrors);
export const clearPasswordViolation = () =>
  action(ErrorTypes.CLEAR_PASSWORD_VIOLATION);

export const publicGeneratorContentViolation = (
  textErrors: PublicGeneratorContentErrors
) => action(ErrorTypes.PUBLIC_GENERATOR_CONTENT_VIOLATION, textErrors);
export const clearPublicGeneratorContentViolation = () =>
  action(ErrorTypes.CLEAR_PUBLIC_GENERATOR_CONTENT_VIOLATION);

export const homeContentViolation = (textErrors: HomeContentErrors) =>
  action(ErrorTypes.HOME_CONTENT_VIOLATION, textErrors);
export const clearHomeContentViolation = () =>
  action(ErrorTypes.CLEAR_HOME_CONTENT_VIOLATION);

export const aboutContentViolation = (textErrors: AboutContentErrors) =>
  action(ErrorTypes.ABOUT_CONTENT_VIOLATION, textErrors);
export const clearAboutContentViolation = () =>
  action(ErrorTypes.CLEAR_ABOUT_CONTENT_VIOLATION);

export const byCatchContentViolation = (textErrors: ByCatchContentErrors) =>
  action(ErrorTypes.BYCATCH_CONTENT_VIOLATION, textErrors);
export const clearByCatchContentViolation = () =>
  action(ErrorTypes.CLEAR_BYCATCH_CONTENT_VIOLATION);

export const speciesContentViolation = (textErrors: SpeciesContentErrors) =>
  action(ErrorTypes.SPECIES_CONTENT_VIOLATION, textErrors);
export const clearSpeciesContentViolation = () =>
  action(ErrorTypes.CLEAR_SPECIES_CONTENT_VIOLATION);
