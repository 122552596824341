import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  detachedColor: {
    backgroundColor: theme.palette.background.paper,
  },
  map: {
    maxWidth: '70%',
    marginBottom: '3rem',
    boxShadow:
      '0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
  },
  chartContainer: {
    width: '100%',
    height: '60rem',
  },
  mainSpecieschartContainer: {
    width: '100%',
    height: '50rem',
  },
  cardsRow: {
    margin: '10px 0px 10px',
  },
  card: {
    width: '85%',
    height: '10rem',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.2) !important',
  },
  cardContent: {
    height: '90%',
    padding: '5px',
  },
  cardTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardTitleIcon: {
    maxHeight: '2rem',
  },
  cardValue: {
    fontSize: '3rem',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  globalFishingWatchMap: {
    margin: 'auto',
    width: '95%',
    height: '80vh',
  },
}));

export default Styles;
