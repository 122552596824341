import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormHandles } from '@unform/core';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { UsersState, User } from '../../../store/modules/users/types';
import TopBoxContainer from '../../../components/TopBoxContainer';
import Styles from './styles';
import * as UsersAction from '../../../store/modules/users/actions';
import { ApplicationState } from '../../../store';
import LoaderTailSpin from '../../../components/LoaderTailSpin';
import { ErrorState } from '../../../store/modules/errors/types';
import TitleLine from '../../../components/TitleLine';
import ConfirmationBox from '../../../components/ConfirmationBox';
import { Vessel, VesselState } from '../../../store/modules/vessel/types';
import * as VesselActions from '../../../store/modules/vessel/actions';

interface StateProps {
  users: UsersState;
  errors: ErrorState;
  vessels: VesselState;
}

interface DispatchProps {
  getUserRequest(id: string): void;
  updateUserRequest(user: User): void;
  clearUserViolation(): void;
  clearUserState(): void;
  vesselRequest(): void;
}

type Props = StateProps & DispatchProps;

const UpdateUser: React.FC<Props> = (props: Props) => {
  const {
    users,
    errors,
    getUserRequest,
    updateUserRequest,
    clearUserState,
    clearUserViolation,
    vessels,
    vesselRequest,
  } = props;
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const styles = Styles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [ownedVessels, setOwnedVessel] = useState<Vessel[]>([]);

  const [vesselsIdValue, setVesselsIdValue] = useState<string[]>([]);

  const urlParams = useParams();
  const userId = Object.values(urlParams)[0] as string;

  const userNotFind = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            title={t('alert')}
            message={errors.user.notFound}
            okLabel={t('back')}
            okAction={() => {
              history.goBack();
              onClose();
            }}
          />
        );
      },
      onClickOutside: () => {
        history.goBack();
      },
    });
  }, [errors.user.notFound, history, t]);

  useEffect(() => {
    if (users.userSuccess) {
      history.goBack();
      clearUserState();
    } else if (errors.user.notFound) {
      clearUserState();
      userNotFind();
    }
  }, [
    clearUserState,
    errors.user.notFound,
    history,
    userNotFind,
    users.userSuccess,
  ]);

  useEffect(() => {
    clearUserState();
    clearUserViolation();
    vesselRequest();
  }, [clearUserState, clearUserViolation, vesselRequest]);

  useEffect(() => {
    getUserRequest(userId);
    setName(users.user.name);
    setEmail(users.user.email);
    setRole(users.user.role);
  }, [
    getUserRequest,
    userId,
    users.user.email,
    users.user.name,
    users.user.role,
  ]);

  useEffect(() => {
    if (users.user.ownedVessels) {
      setVesselsIdValue(users.user.ownedVessels.map((e) => e.externalId));
    }
  }, [users.user.ownedVessels]);

  useEffect(() => {
    setOwnedVessel(
      vesselsIdValue.map((id) =>
        vessels.vessels.find((vessel) => vessel.externalId === id)
      ) as Vessel[]
    );
  }, [vessels.vessels, vesselsIdValue]);

  const handleSubmit = useCallback(async () => {
    const user: User = {
      id: userId,
      name,
      email,
      role,
      ownedVessels,
    };

    await updateUserRequest(user);
  }, [email, name, ownedVessels, role, updateUserRequest, userId]);

  return (
    <div className={styles.root}>
      <TitleLine title={t('users')} />
      <TopBoxContainer title={t('update_user')}>
        {users.userLoading && <LoaderTailSpin />}
        <div>
          {errors.user.role && (
            <p className={styles.errorMessage}>{errors.user.role}</p>
          )}
          <Form
            noValidate
            ref={formRef}
            className={styles.internContent}
            onSubmit={handleSubmit}
          >
            <div className={styles.dividerContainer}>
              <div className={styles.formStyles}>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  id="name"
                  value={name}
                  name="name"
                  label={t('name')}
                  type="text"
                  fullWidth
                  required
                  error={errors.user.name !== ''}
                  helperText={errors.user.name}
                />
              </div>
              <div className={styles.formStyles}>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  value={email}
                  name="email"
                  label={t('email')}
                  type="text"
                  fullWidth
                  required
                  error={errors.user.email !== ''}
                  helperText={errors.user.email}
                />
              </div>
            </div>
            <div className={styles.perfilOptions}>
              {users.myUser.role === 'superadmin' && (
                <Grid item sm={6} md={8}>
                  <RadioGroup
                    value={role}
                    aria-label="role"
                    name="customized-radios"
                    className={styles.radioButton}
                    onChange={(button) => {
                      setRole(button.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="admin"
                      control={<Radio color="primary" />}
                      label={t('admin')}
                    />
                    <FormControlLabel
                      value="shipowner"
                      control={<Radio color="primary" />}
                      label={t('shipowner')}
                    />
                  </RadioGroup>
                </Grid>
              )}
              {role === 'shipowner' && (
                <Grid item sm={6} md={8}>
                  <FormControl className={styles.formControl}>
                    <InputLabel id="input-specie">Embarcações</InputLabel>
                    <Select
                      labelId="specie-select-label"
                      id="specie-select-id"
                      multiple
                      value={vesselsIdValue}
                      onChange={(e) => {
                        setVesselsIdValue(e.target.value as string[]);
                      }}
                      renderValue={() => (
                        <div className={styles.chips}>
                          {vesselsIdValue.map((value) => (
                            <Chip
                              key={value}
                              label={
                                vessels.vessels.find(
                                  (e) => e.externalId === value
                                )?.name
                              }
                              className={styles.chip}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {vessels.vessels.map((row) => (
                        <MenuItem key={row.externalId} value={row.externalId}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.user.ownedVessels && (
                      <p className={styles.errorMessage}>
                        {errors.user.ownedVessels}
                      </p>
                    )}
                  </FormControl>
                </Grid>
              )}
            </div>
            {!users.userLoading && (
              <Button
                type="submit"
                variant="contained"
                className={styles.button}
              >
                {t('save')}
              </Button>
            )}
          </Form>
        </div>
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    users: state.users,
    errors: state.errors,
    vessels: state.vessel,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UsersAction, ...VesselActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
