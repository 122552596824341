import { action } from 'typesafe-actions';
import { FisheryByCatchDataTypes, FisheryData } from './types';

export const fisheryByCatchDataRequest = () =>
  action(FisheryByCatchDataTypes.FISHERY_BYCATCH_DATA_REQUEST);

export const fisheryByCatchDataSuccess = (data: FisheryData[]) =>
  action(FisheryByCatchDataTypes.FISHERY_BYCATCH_DATA_SUCCESS, data);

export const fisheryByCatchDataFailure = () =>
  action(FisheryByCatchDataTypes.FISHERY_BYCATCH_DATA_FAILURE);
