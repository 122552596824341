import React from 'react';
import {
  TableBody as MaterialTableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Description from '@material-ui/icons/Description';
import Styles from '../styles';

interface Props {
  rows: any[];
  hasActions?: {
    edit?: boolean;
    delete?: boolean;
    print?: boolean;
  };
  updateItem: (id: string) => void;
  deleteItem: (id: string) => void;
  printFile: (id: string) => void;
}

const TableBody: React.FC<Props> = (props: Props) => {
  const { rows, hasActions, updateItem, deleteItem, printFile } = props;
  const styles = Styles();

  return (
    <MaterialTableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          {Object.keys(row)
            .filter((item) => item !== 'id')
            .map((item, index) => {
              return (
                <TableCell
                  className={styles.cell}
                  // TODO: Remove inline style: issue debt code #173
                  style={{ fontSize: '1.6rem' }}
                  key={`${row.id + index + row[item]}`}
                >
                  {row[item]}
                </TableCell>
              );
            })}

          {hasActions && (
            <TableCell
              className={styles.cell}
              key="actions"
              // TODO: Remove inline style: issue debt code #173
              style={{ fontSize: '1.6rem' }}
            >
              <div className={styles.iconBox}>
                {hasActions.edit && (
                  <IconButton
                    className={styles.icon}
                    onClick={() => updateItem(row.id)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {hasActions.print && (
                  <IconButton
                    className={styles.icon}
                    onClick={() => printFile(row.id)}
                  >
                    <Description />
                  </IconButton>
                )}
                {hasActions.delete && (
                  <IconButton
                    className={styles.icon}
                    onClick={() => deleteItem(row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </TableCell>
          )}
        </TableRow>
      ))}
    </MaterialTableBody>
  );
};

export default TableBody;
