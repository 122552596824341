import { action } from 'typesafe-actions';
import { FisheryDataTypes, FisheryData } from './types';

export const fisheryDataRequest = () =>
  action(FisheryDataTypes.FISHERY_DATA_REQUEST);

export const fisheryDataSuccess = (data: FisheryData[]) =>
  action(FisheryDataTypes.FISHERY_DATA_SUCCESS, data);

export const fisheryDataFailure = () =>
  action(FisheryDataTypes.FISHERY_DATA_FAILURE);
