/**
 * actions types
 */
export enum MainSpeciesTypes {
  MAIN_SPECIES_REQUEST = '@chart/MAIN_SPECIES_REQUEST',
  MAIN_SPECIES_SUCCESS = '@chart/MAIN_SPECIES_SUCCESS',
  MAIN_SPECIES_FAILURE = '@chart/MAIN_SPECIES_FAILURE',
}

export type {
  SpeciesDistributionData,
  SpeciesDistributionState,
} from '../../types';
