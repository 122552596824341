import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {},
  title: {
    flexGrow: 1,
  },
  toolBar: {
    backgroundColor: theme.palette.background.paper,
  },
  navLink: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 400,
    textTransform: 'none',
  },
}));
