import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: (props: { color: string }) => ({
    marginTop: 'calc(2%)',
    paddingBottom: 'calc(2%)',
    bottom: '0',
    backgroundColor: props.color,
  }),
  footer: {
    flexFlow: 'wrap-reverse',
  },
  footerTitle: {
    fontSize: '2.5rem',
    marginTop: '20px',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  partnerLine: {
    flexGrow: 1,
    marginBottom: '20px',
  },
  partner: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginBottom: '25px',
    },
  },
}));
