import { action } from 'typesafe-actions';
import { FishingTripTypes, FishingTrip } from './types';

export const fishingTripsRequest = (page: number) =>
  action(FishingTripTypes.FISHING_TRIP_REQUEST, page);

export const fishingTripsSuccess = (count: number, data: FishingTrip[]) =>
  action(FishingTripTypes.FISHING_TRIP_SUCCESS, { count, data });

export const fishingTripsFailure = () =>
  action(FishingTripTypes.FISHING_TRIP_FAILURE);
