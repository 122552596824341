import { call, put } from 'redux-saga/effects';
import { fisheryBetDataSuccess, fisheryBetDataFailure } from './actions';
import { getFisheryBetDataChart } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryBetDataChart() {
  try {
    const response = yield call(getFisheryBetDataChart);
    if (response.status === 200) {
      yield put(fisheryBetDataSuccess(response.data.data));
    } else if (response.status === 401) {
      yield put(fisheryBetDataFailure());
    } else {
      yield put(fisheryBetDataFailure());
    }
  } catch (err) {
    yield put(fisheryBetDataFailure());
  }
}
