import { all, takeLatest } from 'redux-saga/effects';
import { AuthTypes } from './auth/types';
import { signIn, logout } from './auth/sagas';
import { UsersTypes } from './users/types';
import {
  getUser,
  loadUsers,
  addUser,
  updateUser,
  deleteUser,
  getMe,
} from './users/sagas';
import { ReportTypes } from './report/types';
import { requestSpecies } from './species/sagas';
import { requestReport, requestCsv } from './report/sagas';
import { MainSpeciesTypes } from './dataProvider/homeData/mainSpecies/types';
import { loadMainSpecies } from './dataProvider/homeData/mainSpecies/sagas';
import { FisheryDataTypes } from './dataProvider/homeData/fisheryData/types';
import { loadFisheryDataChart } from './dataProvider/homeData/fisheryData/sagas';
import {
  passwordRequest,
  passwordChangeRequest,
  passwordResetRequest,
} from './password/sagas';
import { PasswordTypes } from './password/types';
import { SpeciesTypes } from './species/types';
import { FiltersTypes } from './filters/types';
import { HomeContentTypes } from './content/home/types';
import { getHomeContent, setHomeContent } from './content/home/sagas';
import { AboutContentTypes } from './content/about/types';
import { getAboutContent, setAboutContent } from './content/about/sagas';
import { PublicDataGeneratorContentTypes } from './content/publicDataGenerator/types';
import {
  getPublicDataGeneratorContent,
  setPublicDataGeneratorContent,
} from './content/publicDataGenerator/sagas';
import { FisheryByCatchDataTypes } from './dataProvider/byCatch/fisheryData/types';
import { FisheryByCatchProductionDataTypes } from './dataProvider/byCatch/fisheryProduction/types';
import { ByCatchSpeciesDistributionTypes } from './dataProvider/byCatch/speciesDistribution/types';
import {
  getFilter,
  loadFilters,
  addFilter,
  updateFilter,
  deleteFilter,
} from './filters/sagas';
import { FisheryProductionDataTypes } from './dataProvider/homeData/fisheryProduction/types';
import { loadFisheryProductionData } from './dataProvider/homeData/fisheryProduction/sagas';
import { HomeHeatmapTypes } from './dataProvider/homeData/heatmap/types';
import { getHomeHeatmap } from './dataProvider/homeData/heatmap/sagas';
import { FisherySwoDataTypes } from './dataProvider/swoData/fisheryData/types';
import { FisherySwoProductionDataTypes } from './dataProvider/swoData/fisheryProduction/types';
import { loadFisherySwoDataChart } from './dataProvider/swoData/fisheryData/sagas';
import { loadFisherySwoProductionData } from './dataProvider/swoData/fisheryProduction/sagas';
import { SwoHeatmapTypes } from './dataProvider/swoData/heatmap/types';
import { getSwoHeatmap } from './dataProvider/swoData/heatmap/sagas';
import { FisheryBetDataTypes } from './dataProvider/betData/fisheryData/types';
import { FisheryBetProductionDataTypes } from './dataProvider/betData/fisheryProduction/types';
import { loadFisheryBetDataChart } from './dataProvider/betData/fisheryData/sagas';
import { loadFisheryBetProductionData } from './dataProvider/betData/fisheryProduction/sagas';
import { BetHeatmapTypes } from './dataProvider/betData/heatmap/types';
import { getBetHeatmap } from './dataProvider/betData/heatmap/sagas';
import { FisheryAlbDataTypes } from './dataProvider/albData/fisheryData/types';
import { FisheryAlbProductionDataTypes } from './dataProvider/albData/fisheryProduction/types';
import { AlbHeatmapTypes } from './dataProvider/albData/heatmap/types';
import { getAlbHeatmap } from './dataProvider/albData/heatmap/sagas';
import { loadFisheryAlbDataChart } from './dataProvider/albData/fisheryData/sagas';
import { loadFisheryAlbProductionData } from './dataProvider/albData/fisheryProduction/sagas';
import { FisheryYftDataTypes } from './dataProvider/yftData/fisheryData/types';
import { FisheryYftProductionDataTypes } from './dataProvider/yftData/fisheryProduction/types';
import { YftHeatmapTypes } from './dataProvider/yftData/heatmap/types';
import { getYftHeatmap } from './dataProvider/yftData/heatmap/sagas';
import { loadFisheryYftDataChart } from './dataProvider/yftData/fisheryData/sagas';
import { loadFisheryYftProductionData } from './dataProvider/yftData/fisheryProduction/sagas';
import { loadFisheryByCatchDataChart } from './dataProvider/byCatch/fisheryData/sagas';
import { loadFisheryByCatchProductionData } from './dataProvider/byCatch/fisheryProduction/sagas';
import { loadFisheryByCatchSpeciesDistribution } from './dataProvider/byCatch/speciesDistribution/sagas';
import { getfishingTrips } from './fishingTrip/sagas';
import { FishingTripTypes } from './fishingTrip/types';
import { getPdf } from './logbook/sagas';
import { LogbookTypes } from './logbook/types';
import { getVessels } from './vessel/sagas';
import { VesselTypes } from './vessel/types';
import { ByCatchHeatmapTypes } from './dataProvider/byCatch/heatmap/types';
import { getByCatchHeatmap } from './dataProvider/byCatch/heatmap/sagas';
import { SwoContentTypes } from './content/swo/types';
import { getSwoContent, setSwoContent } from './content/swo/sagas';
import { ByCatchContentTypes } from './content/byCatch/types';
import { getByCatchContent, setByCatchContent } from './content/byCatch/sagas';
import { YftContentTypes } from './content/yft/types';
import { getYftContent, setYftContent } from './content/yft/sagas';
import { BetContentTypes } from './content/bet/types';
import { getBetContent, setBetContent } from './content/bet/sagas';
import { AlbContentTypes } from './content/alb/types';
import { getAlbContent, setAlbContent } from './content/alb/sagas';

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.SIGN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_FAILURE, logout),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(UsersTypes.GET_USER_REQUEST, getUser),
    takeLatest(PasswordTypes.PASSWORD_REQUEST, passwordRequest),
    takeLatest(PasswordTypes.CHANGE_PASSWORD_REQUEST, passwordChangeRequest),
    takeLatest(PasswordTypes.RESET_PASSWORD_REQUEST, passwordResetRequest),
    takeLatest(UsersTypes.LIST_USERS_REQUEST, loadUsers),
    takeLatest(UsersTypes.ADD_USER, addUser),
    takeLatest(UsersTypes.UPDATE_USER, updateUser),
    takeLatest(UsersTypes.DELETE_USER, deleteUser),
    takeLatest(UsersTypes.GET_ME_REQUEST, getMe),
    takeLatest(FiltersTypes.GET_FILTER_REQUEST, getFilter),
    takeLatest(FiltersTypes.LIST_FILTERS_REQUEST, loadFilters),
    takeLatest(FiltersTypes.ADD_FILTER, addFilter),
    takeLatest(FiltersTypes.UPDATE_FILTER, updateFilter),
    takeLatest(FiltersTypes.DELETE_FILTER, deleteFilter),
    takeLatest(SpeciesTypes.SPECIES_REQUEST, requestSpecies),
    takeLatest(ReportTypes.LOAD_REQUEST_REPORT, requestReport),
    takeLatest(MainSpeciesTypes.MAIN_SPECIES_REQUEST, loadMainSpecies),
    takeLatest(FisheryDataTypes.FISHERY_DATA_REQUEST, loadFisheryDataChart),
    takeLatest(
      FisheryProductionDataTypes.FISHERY_PRODUCTION_REQUEST,
      loadFisheryProductionData
    ),
    takeLatest(
      FisherySwoDataTypes.FISHERY_SWO_DATA_REQUEST,
      loadFisherySwoDataChart
    ),
    takeLatest(
      FisherySwoProductionDataTypes.FISHERY_SWO_PRODUCTION_REQUEST,
      loadFisherySwoProductionData
    ),
    takeLatest(SwoHeatmapTypes.SWO_HEATMAP_REQUEST, getSwoHeatmap),
    takeLatest(
      FisheryBetDataTypes.FISHERY_BET_DATA_REQUEST,
      loadFisheryBetDataChart
    ),
    takeLatest(
      FisheryBetProductionDataTypes.FISHERY_BET_PRODUCTION_REQUEST,
      loadFisheryBetProductionData
    ),
    takeLatest(BetHeatmapTypes.BET_HEATMAP_REQUEST, getBetHeatmap),
    takeLatest(
      FisheryAlbDataTypes.FISHERY_ALB_DATA_REQUEST,
      loadFisheryAlbDataChart
    ),
    takeLatest(
      FisheryAlbProductionDataTypes.FISHERY_ALB_PRODUCTION_REQUEST,
      loadFisheryAlbProductionData
    ),
    takeLatest(AlbHeatmapTypes.ALB_HEATMAP_REQUEST, getAlbHeatmap),
    takeLatest(
      FisheryYftDataTypes.FISHERY_YFT_DATA_REQUEST,
      loadFisheryYftDataChart
    ),
    takeLatest(
      FisheryYftProductionDataTypes.FISHERY_YFT_PRODUCTION_REQUEST,
      loadFisheryYftProductionData
    ),
    takeLatest(YftHeatmapTypes.YFT_HEATMAP_REQUEST, getYftHeatmap),
    takeLatest(
      FisheryByCatchDataTypes.FISHERY_BYCATCH_DATA_REQUEST,
      loadFisheryByCatchDataChart
    ),
    takeLatest(
      FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_REQUEST,
      loadFisheryByCatchProductionData
    ),
    takeLatest(
      ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_REQUEST,
      loadFisheryByCatchSpeciesDistribution
    ),
    takeLatest(ReportTypes.LOAD_REQUEST_CSV, requestCsv),
    takeLatest(
      PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_REQUEST,
      getPublicDataGeneratorContent
    ),
    takeLatest(FishingTripTypes.FISHING_TRIP_REQUEST, getfishingTrips),
    takeLatest(LogbookTypes.LOGBOOK_PDF_REQUEST, getPdf),
    takeLatest(
      PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_SUBMIT,
      setPublicDataGeneratorContent
    ),
    takeLatest(VesselTypes.VESSEL_REQUEST, getVessels),
    takeLatest(HomeContentTypes.HOME_CONTENT_REQUEST, getHomeContent),
    takeLatest(HomeContentTypes.HOME_CONTENT_SUBMIT, setHomeContent),
    takeLatest(AboutContentTypes.ABOUT_CONTENT_REQUEST, getAboutContent),
    takeLatest(AboutContentTypes.ABOUT_CONTENT_SUBMIT, setAboutContent),
    takeLatest(ByCatchHeatmapTypes.BYCATCH_HEATMAP_REQUEST, getByCatchHeatmap),
    takeLatest(HomeHeatmapTypes.HOME_HEATMAP_REQUEST, getHomeHeatmap),
    takeLatest(SwoContentTypes.SWO_REQUEST, getSwoContent),
    takeLatest(SwoContentTypes.SWO_SUBMIT, setSwoContent),
    takeLatest(YftContentTypes.YFT_REQUEST, getYftContent),
    takeLatest(YftContentTypes.YFT_SUBMIT, setYftContent),
    takeLatest(BetContentTypes.BET_REQUEST, getBetContent),
    takeLatest(BetContentTypes.BET_SUBMIT, setBetContent),
    takeLatest(AlbContentTypes.ALB_REQUEST, getAlbContent),
    takeLatest(AlbContentTypes.ALB_SUBMIT, setAlbContent),
    takeLatest(ByCatchContentTypes.BYCATCH_CONTENT_REQUEST, getByCatchContent),
    takeLatest(ByCatchContentTypes.BYCATCH_CONTENT_SUBMIT, setByCatchContent),
  ]);
}
