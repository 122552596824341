import { makeStyles, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const Styles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#fff',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    nestedList: {
      maxHeight: '25vh',
      overflowY: 'auto',

      // Scroll Bar styles
      '&::-webkit-scrollbar': {
        width: '1rem',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey',
      },
    },
    itemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export default Styles;
