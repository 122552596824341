import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    dialogContent: {
      '& .MuiDialog-paperFullWidth': {
        display: 'contents',
      },
    },
    internContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    dividerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(5),
    },
    formStyles: {
      width: '45%',
      margin: theme.spacing(1),
    },
    button: {
      width: theme.spacing(15),
      alignSelf: 'flex-end',
      marginRight: theme.spacing(5),
      color: 'white',
      backgroundColor: theme.palette.info.main,
      padding: theme.spacing(1, 3),

      '& .MuiButton-label': {
        fontWeight: 'bold',
      },
    },
    roles: {
      display: 'flex-root',
      alignItems: 'flex-end',
      paddingLeft: theme.spacing(5),
      margin: theme.spacing(0.5),
    },
    radioButton: {
      display: 'contents',
    },
    formControl: {
      margin: theme.spacing(0.3),
      minWidth: 120,
      width: '100%',

      specie: {
        bottom: '0',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 1,
      color: 'white',
      backgroundColor: theme.palette.info.main,
      fontSize: '1.8rem',
    },
    errorMessage: {
      fontSize: '13px',
      color: 'red',
    },
  })
);

export default Styles;
