/**
 * actions types
 */
export enum LogbookTypes {
  LOGBOOK_PDF_REQUEST = '@logbook/LOGBOOK_PDF_REQUEST',
  LOGBOOK_PDF_SUCCESS = '@logbook/LOGBOOK_PDF_SUCCESS',
  LOGBOOK_PDF_FAILURE = '@logbook/LOGBOOK_PDF_FAILURE',
}

/**
 * state type
 */

export interface LogbookState {
  readonly loading: boolean;
  readonly error: boolean;
}
