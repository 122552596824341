import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import BaseForm from '../../../components/BaseForm';
import * as ByCatchContentAction from '../../../store/modules/content/byCatch/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { ErrorState } from '../../../store/modules/errors/types';
import TextEditor from '../../../components/TextEditor';
import {
  ByCatchContent,
  ByCatchContentState,
} from '../../../store/modules/content/byCatch/types';
import { ApplicationState } from '../../../store';

interface StateProps {
  errors: ErrorState;
  byCatchContent: ByCatchContentState;
}

interface DispatchProps {
  byCatchContentRequest(): void;
  clearByCatchContentViolation(): void;
  byCatchContentSubmit(data: ByCatchContent): void;
}

type Props = StateProps & DispatchProps;

const ByCatchContentManagement: React.FC<Props> = (props: Props) => {
  const {
    errors,
    byCatchContent,
    byCatchContentRequest,
    byCatchContentSubmit,
    clearByCatchContentViolation,
  } = props;

  const [title, setTitle] = useState('');
  const [introductoryText, setIntroductoryText] = useState('');
  const [lineChartText, setLineChartText] = useState('');
  const [mainSpeciesChartText, setMainSpeciesChartText] = useState('');
  const [heatMapText, setHeatMapText] = useState('');

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // TODO: Codedebt: Handle with empty texts #241
  // Submit
  const onSubmit = useCallback(async () => {
    const data: ByCatchContent = {
      title,
      introductoryText,
      lineChartText,
      mainSpeciesChartText,
      heatMapText,
    };

    byCatchContentSubmit(data);
    clearByCatchContentViolation();
  }, [
    title,
    introductoryText,
    lineChartText,
    mainSpeciesChartText,
    heatMapText,
    byCatchContentSubmit,
    clearByCatchContentViolation,
  ]);

  useEffect(() => {
    byCatchContentRequest();
    clearByCatchContentViolation();
    setTitle(byCatchContent.content.title);
    setIntroductoryText(byCatchContent.content.introductoryText);
    setMainSpeciesChartText(byCatchContent.content.mainSpeciesChartText);
    setLineChartText(byCatchContent.content.lineChartText);
    setHeatMapText(byCatchContent.content.heatMapText);
  }, [
    byCatchContentRequest,
    clearByCatchContentViolation,
    byCatchContent.content.title,
    byCatchContent.content.introductoryText,
    byCatchContent.content.mainSpeciesChartText,
    byCatchContent.content.lineChartText,
    byCatchContent.content.heatMapText,
  ]);

  useEffect(() => {
    setLoading(byCatchContent.loading && !byCatchContent.error);
  }, [byCatchContent.error, byCatchContent.loading]);

  return (
    <BaseForm
      loading={loading}
      pageTitle={t('content_manager')}
      formTitle={t('bycatch')}
      buttonLabel={t('save')}
      onSubmit={onSubmit}
    >
      <TextField
        label={t('title_label')}
        value={title}
        fullWidth
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        required
        error={errors.byCatchContent.title !== ''}
        helperText={errors.byCatchContent.title}
      />

      <TextEditor
        required
        label={t('introductory_text_label')}
        textValue={introductoryText}
        setTextValue={setIntroductoryText}
        error={errors.byCatchContent.introductoryText}
      />

      <TextEditor
        required
        label={t('main_species_chart_text_label')}
        textValue={mainSpeciesChartText}
        setTextValue={setMainSpeciesChartText}
        error={errors.byCatchContent.mainSpeciesChartText}
      />

      <TextEditor
        required
        label={t('line_chart_text_label')}
        textValue={lineChartText}
        setTextValue={setLineChartText}
        error={errors.byCatchContent.lineChartText}
      />

      <TextEditor
        required
        label={t('heat_map_text_label')}
        textValue={heatMapText}
        setTextValue={setHeatMapText}
        error={errors.byCatchContent.heatMapText}
      />
    </BaseForm>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    byCatchContent: state.byCatchContent,
    errors: state.errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...ByCatchContentAction,
      ...ErrorsAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ByCatchContentManagement);
