import { call, put } from 'redux-saga/effects';
import { yftHeatmapSuccess, yftHeatmapFailure } from './actions';
import { getYftHeatmap as getYftHeatmapApi } from '../../../../../services/api/dataProviderApi';

export function* getYftHeatmap() {
  try {
    const response = yield call(getYftHeatmapApi);
    if (response.status === 200) {
      yield put(yftHeatmapSuccess(response.data.data));
    }
  } catch (err) {
    yield put(yftHeatmapFailure());
  }
}
