import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: theme.spacing(28),
      height: theme.spacing(6),
      backgroundColor: theme.palette.primary.light,
      borderRadius: '3px',
      position: 'relative',
      top: '20px',

      '& p': {
        color: '#FFFFFF',
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    internContent: {
      backgroundColor: '#FFFFFF',
      padding: theme.spacing(5),
      marginLeft: '20px',
      borderRadius: '5px',
      boxShadow:
        '0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
    },
  })
);

export default Styles;
