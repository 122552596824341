import { action } from 'typesafe-actions';
import { ByCatchContent, ByCatchContentTypes } from './types';

export const byCatchContentSubmit = (content: ByCatchContent) =>
  action(ByCatchContentTypes.BYCATCH_CONTENT_SUBMIT, content);
export const byCatchContentRequest = () =>
  action(ByCatchContentTypes.BYCATCH_CONTENT_REQUEST);
export const byCatchContentLoaded = (content: ByCatchContent) =>
  action(ByCatchContentTypes.BYCATCH_CONTENT_LOADED, content);
export const byCatchContentFailure = () =>
  action(ByCatchContentTypes.BYCATCH_CONTENT_FAILURE);
