import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { Filter } from '../../../../../store/modules/filters/types';

interface Props {
  filters: Filter[];
  setSelectedFilter: (filter: Filter) => void;
}

const PreConfigFilter: React.FC<Props> = (props: Props) => {
  const { filters, setSelectedFilter } = props;
  const styles = Styles();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedFilter = filters.find(
      (e) => e.id === (event.target.value as string)
    );
    if (selectedFilter !== undefined) {
      setSelectedFilter(selectedFilter);
    }
  };

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      className={styles.root}
    >
      <FormControl className={styles.formControl}>
        <InputLabel id="label">{t('select')}</InputLabel>
        <Select
          labelId="label"
          id="select-filter"
          defaultValue=""
          onChange={handleChange}
        >
          {filters.map((filter) => (
            <MenuItem key={filter.id} value={filter.id}>
              {filter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default PreConfigFilter;
