import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import * as AboutContentAction from '../../../store/modules/content/about/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { ApplicationState } from '../../../store';
import {
  AboutContentState,
  AboutContent,
} from '../../../store/modules/content/about/types';
import { ErrorState } from '../../../store/modules/errors/types';
import TextEditor from '../../../components/TextEditor';
import BaseForm from '../../../components/BaseForm';

interface StateProps {
  errors: ErrorState;
  aboutContent: AboutContentState;
}

interface DispatchProps {
  aboutContentRequest(): void;
  clearAboutContentViolation(): void;
  aboutContentSubmit(data: AboutContent): void;
}

type Props = StateProps & DispatchProps;

const AboutContentManagement: React.FC<Props> = (props: Props) => {
  const {
    errors,
    aboutContent,
    aboutContentSubmit,
    aboutContentRequest,
    clearAboutContentViolation,
  } = props;

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // TODO: Codedebt: Handle with empty texts #241
  // submit
  const onSubmit = useCallback(async () => {
    const data: AboutContent = {
      title,
      text,
    };

    aboutContentSubmit(data);
    clearAboutContentViolation();
  }, [text, title, clearAboutContentViolation, aboutContentSubmit]);

  useEffect(() => {
    aboutContentRequest();
    clearAboutContentViolation();
    setTitle(aboutContent.content.title);
    setText(aboutContent.content.text);
  }, [
    aboutContentRequest,
    clearAboutContentViolation,
    aboutContent.content.title,
    aboutContent.content.text,
  ]);

  useEffect(() => {
    setLoading(aboutContent.loading && !aboutContent.error);
  }, [aboutContent.error, aboutContent.loading]);

  return (
    <BaseForm
      loading={loading}
      pageTitle={t('content_manager')}
      formTitle={t('about')}
      buttonLabel={t('save')}
      onSubmit={onSubmit}
    >
      <TextField
        label={t('title_label')}
        value={title}
        fullWidth
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        required
        error={errors.aboutContent.title !== ''}
        helperText={errors.aboutContent.title}
      />

      <TextEditor
        required
        label={t('text_label')}
        textValue={text}
        setTextValue={setText}
        error={errors.aboutContent.text}
      />
    </BaseForm>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    aboutContent: state.aboutContent,
    errors: state.errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...AboutContentAction,
      ...ErrorsAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutContentManagement);
