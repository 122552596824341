import { call, put } from 'redux-saga/effects';
import {
  fisheryBetProductionDataSuccess,
  fisheryBetProductionDataFailure,
} from './actions';
import { getFisheryBetProductionData } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryBetProductionData() {
  try {
    const response = yield call(getFisheryBetProductionData);
    if (response.status === 200) {
      yield put(fisheryBetProductionDataSuccess(response.data));
    } else if (response.status === 401) {
      yield put(fisheryBetProductionDataFailure());
    } else {
      yield put(fisheryBetProductionDataFailure());
    }
  } catch (err) {
    yield put(fisheryBetProductionDataFailure());
  }
}
