import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      alignSelf: 'flex-end',
      marginRight: theme.spacing(5),
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(1, 3),

      '& .MuiButton-label': {
        fontWeight: 'bold',
      },
    },
  })
);

export default Styles;
