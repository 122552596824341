/**
 * actions types
 */
export enum HomeContentTypes {
  HOME_CONTENT_SUBMIT = '@homeContent/HOME_CONTENT_SUBMIT',
  HOME_CONTENT_REQUEST = '@homeContent/HOME_CONTENT_REQUEST',
  HOME_CONTENT_LOADED = '@homeContent/HOME_CONTENT_SUCCESS',
  HOME_CONTENT_FAILURE = '@homeContent/HOME_CONTENT_FAILURE',
}

/**
 * data types
 */
export interface HomeContent {
  title: string;
  introductoryText: string;
  lineChartText: string;
  mainSpeciesChartTitle: string;
  mainSpeciesChartText: string;
  heatMapTitle: string;
  heatMapText: string;
}

/**
 * state type
 */
export interface HomeContentState {
  readonly content: HomeContent;
  readonly loaded: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
