import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontSize: '3.5rem',
      color: 'rgba(0, 0, 0, 0.54)',
      margin: '2rem',
      borderBottom: '1px solid #BDBDBD',
      letterSpacing: '0.25px',
    },
    formTitleBox: {
      backgroundColor: theme.palette.primary.light,
      '& h2': {
        fontSize: '2.0rem',
        fontWeight: 'bold',
      },
    },
    form: {
      paddingBottom: theme.spacing(2),
    },
    submitButton: {
      float: 'right',
      backgroundColor: theme.palette.info.main,
    },
  })
);

export default Styles;
