import { call, put } from 'redux-saga/effects';
import i18n from '../../../i18n';
import { LogbookTypes } from './types';
import { logbookPdfSuccess, logbookPdfFailure } from './actions';
import { getPdf as getPdfApi } from '../../../services/api/logbookApi';

export function* getPdf({
  payload,
}: {
  type: typeof LogbookTypes.LOGBOOK_PDF_REQUEST;
  payload: string;
}) {
  try {
    const response = yield call(getPdfApi, payload);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${i18n.t('board_map')}_${payload}.pdf`);
      document.body.appendChild(link); // Required for this to work in FireFox
      link.click();

      yield put(logbookPdfSuccess());
    }
  } catch (err) {
    yield put(logbookPdfFailure());
  }
}
