import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Grid, useTheme } from '@material-ui/core';
import Footer from '../../components/Footer';
import ContentBlock from '../../components/ContentBlock';
import { ApplicationState } from '../../store';
import * as AboutContentAction from '../../store/modules/content/about/actions';
import { AboutContentState } from '../../store/modules/content/about/types';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import ErrorComponent from '../../components/ErrorComponent';

interface StateProps {
  aboutContent: AboutContentState;
}

interface DispatchProps {
  aboutContentRequest(): void;
}

type Props = StateProps & DispatchProps;

const About: React.FC<Props> = (props: Props) => {
  const { aboutContent, aboutContentRequest } = props;
  const theme = useTheme();

  useEffect(() => {
    aboutContentRequest();
  }, [aboutContentRequest]);

  return (
    <>
      {aboutContent.loading && <LoaderTailSpin />}
      {aboutContent.error && <ErrorComponent />}
      {aboutContent.loaded && (
        <Grid item container direction="column" alignItems="center">
          <ContentBlock
            title={aboutContent.content.title}
            topText={aboutContent.content.text}
          />
          <Footer color={theme.palette.background.paper} />
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    aboutContent: state.aboutContent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AboutContentAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(About);
