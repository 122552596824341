import { action } from 'typesafe-actions';
import { FisheryYftProductionDataTypes, FisheryProductionData } from './types';

export const fisheryYftProductionDataRequest = () =>
  action(FisheryYftProductionDataTypes.FISHERY_YFT_PRODUCTION_REQUEST);

export const fisheryYftProductionDataSuccess = (data: FisheryProductionData) =>
  action(FisheryYftProductionDataTypes.FISHERY_YFT_PRODUCTION_SUCCESS, data);

export const fisheryYftProductionDataFailure = () =>
  action(FisheryYftProductionDataTypes.FISHERY_YFT_PRODUCTION_FAILURE);
