/* Imports */
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { SpeciesDistributionData } from '../../../store/modules/dataProvider/types';

am4core.useTheme(am4themesAnimated);

interface Props {
  data: SpeciesDistributionData[];
}

const TreeMapDataChart: React.FC<Props> = (props: Props) => {
  const { data } = props;

  useEffect(() => {
    const chart = am4core.create('chartdiv', am4charts.TreeMap);

    // Locale defition for numbers formating
    chart.language.locale = am4lang_pt_BR;

    // this makes initial fade in effect
    chart.hiddenState.properties.opacity = 0;

    // enable navigation
    chart.navigationBar = new am4charts.NavigationBar();
    chart.zoomable = false;

    chart.data = data;

    /* Define data fields */
    chart.dataFields.name = 'species';
    chart.dataFields.value = 'weight';

    const level1 = chart.seriesTemplates.create('0');
    const level1_column = level1.columns.template;
    level1_column.column.cornerRadius(10, 10, 10, 10);
    level1_column.fillOpacity = 0.8;
    level1_column.stroke = am4core.color('#fff');
    level1_column.strokeWidth = 5;
    level1_column.strokeOpacity = 1;

    const level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
    level1_bullet.locationY = 0.5;
    level1_bullet.locationX = 0.5;
    level1_bullet.label.text = '{name}';
    level1_bullet.label.fill = am4core.color('#fff');
    level1_bullet.fontSize = '1.1em';

    chart.legend = new am4charts.Legend();
  }, [data]);
  return <div id="chartdiv" style={{ width: '100%', height: '100%' }} />;
};

export default TreeMapDataChart;
