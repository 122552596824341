import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@material-ui/core';
import Styles from './styles';
import Logo from '../../assets/logoOpenTuna.png';

const Navbar: React.FC = () => {
  const classes = Styles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar className={classes.toolBar}>
      <Link href="/" className={classes.link}>
        <img src={Logo} height={50} alt="logo" />
      </Link>
      <Typography variant="h6" className={classes.title} />
      <nav className={classes.navLink}>
        <Link href="/about" className={classes.link}>
          {t('about')}
        </Link>
        <Button onClick={handleClick} className={classes.link}>
          {t('species_plural')}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link href="/species/bet" className={classes.link}>
              {t('bet')}
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link href="/species/alb" className={classes.link}>
              {t('alb')}
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link href="/species/yft" className={classes.link}>
              {t('yft')}
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link href="/species/swo" className={classes.link}>
              {t('swo')}
            </Link>
          </MenuItem>
        </Menu>
        <Link href="/bycatch" className={classes.link}>
          {t('bycatch')}
        </Link>
        <Link href="/public-data-generator" className={classes.link}>
          {t('database')}
        </Link>
        <Link href="/redirect" className={classes.link}>
          {t('restrict_access')}
        </Link>
      </nav>
    </Toolbar>
  );
};
export default Navbar;
