import { Reducer } from 'redux';
import {
  FisheryProductionDataState,
  FisheryByCatchProductionDataTypes,
} from './types';

const INITIAL_STATE: FisheryProductionDataState = {
  data: {
    catchingFish: 0,
    fishingThrows: 0,
    fishingTrips: 0,
    totalCatch: 0,
  },
  loading: false,
  error: false,
};

const reducer: Reducer<FisheryProductionDataState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_REQUEST:
      return { ...state, loading: true };
    case FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
