import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@material-ui/core';
import { Form } from '@unform/web';
import { useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import * as SpeciesAction from '../../../store/modules/species/actions';
import * as FilterAction from '../../../store/modules/filters/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import TopBoxContainer from '../../../components/TopBoxContainer';
import Styles from './styles';
import TitleLine from '../../../components/TitleLine';
import { ApplicationState } from '../../../store';
import { SpeciesState } from '../../../store/modules/species/types';
import Period from '../../../components/Period';
import Variables from '../../../components/Variables';
import Groupers from '../../../components/Groupers';
import { Filter, FiltersState } from '../../../store/modules/filters/types';
import LoaderTailSpin from '../../../components/LoaderTailSpin';
import { ErrorState } from '../../../store/modules/errors/types';
import ConfirmationBox from '../../../components/ConfirmationBox';

interface OwnProps {
  isEdit?: boolean;
}

interface StateProps {
  species: SpeciesState;
  filters: FiltersState;
  errors: ErrorState;
}

interface DispatchProps {
  speciesResquest(): void;
  getFilterRequest(id: string): void;
  addNewFilterRequest(filter: Filter): void;
  updateFilterRequest(filter: Filter): void;
  clearFilterState(): void;
  clearFilterViolation(): void;
}

type Props = DispatchProps & StateProps & OwnProps;

const FormFilter: React.FC<Props> = (props: Props) => {
  // from imports
  const history = useHistory();
  const urlParams = useParams();
  const { t } = useTranslation();
  const styles = Styles();

  // props
  const {
    isEdit = false,
    getFilterRequest,
    speciesResquest,
    addNewFilterRequest,
    updateFilterRequest,
    filters,
    species,
    errors,
    clearFilterState,
    clearFilterViolation,
  } = props;

  const filterNotFind = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            title={t('alert')}
            message={errors.filter.notFound}
            okLabel={t('back')}
            okAction={() => {
              history.goBack();
              onClose();
            }}
          />
        );
      },
      onClickOutside: () => {
        history.goBack();
      },
    });
  }, [errors.filter.notFound, history, t]);

  // callback on filters change
  useEffect(() => {
    if (filters.filterSuccess) {
      history.goBack();
      clearFilterState();
    } else if (errors.filter.notFound) {
      clearFilterState();
      filterNotFind();
    }
  }, [
    clearFilterState,
    errors.filter.notFound,
    filterNotFind,
    filters.filterSuccess,
    history,
  ]);

  // equivalent at componentDidMount
  useEffect(() => {
    clearFilterState();
    clearFilterViolation();
    speciesResquest();
  }, [speciesResquest, clearFilterViolation, clearFilterState]);

  const filterId = Object.values(urlParams)[0] as string;

  useEffect(() => {
    if (isEdit) {
      getFilterRequest(filterId);
    }
  }, [filterId, getFilterRequest, isEdit]);

  // defining variable to form
  const [nameFilter, setNameFilter] = useState(filters.filter.name);
  const [period, setPeriod] = useState(filters.filter.period);
  const [groupers, setGroupers] = useState(filters.filter.groupers);
  const [variables, setVariables] = useState(filters.filter.variables);

  useEffect(() => {
    setNameFilter(filters.filter.name);
    setPeriod(filters.filter.period);
    setGroupers(filters.filter.groupers);
    setVariables(filters.filter.variables);
  }, [
    filters.filter.groupers,
    filters.filter.name,
    filters.filter.period,
    filters.filter.variables,
  ]);

  // submit
  const handleSubmit = useCallback(async () => {
    const upsertFilter: Filter = {
      name: nameFilter,
      period,
      groupers,
      variables,
    };
    if (isEdit) {
      updateFilterRequest({
        ...upsertFilter,
        id: filterId,
      } as Filter);
    } else {
      addNewFilterRequest(upsertFilter);
    }
  }, [
    addNewFilterRequest,
    filterId,
    groupers,
    isEdit,
    nameFilter,
    period,
    updateFilterRequest,
    variables,
  ]);

  return (
    <div style={{ height: 'fit-content', marginBottom: '30px' }}>
      <TitleLine title={t('config_public_filter')} />
      <TopBoxContainer
        title={isEdit ? t('update_filter') : t('register_filter')}
      >
        <div className={styles.root}>
          <Form noValidate onSubmit={handleSubmit}>
            <div className={styles.nameFilter}>
              <TextField
                id="name"
                label={t('name_filter')}
                value={nameFilter}
                onChange={(e) => {
                  setNameFilter(e.target.value);
                }}
                required
                fullWidth
                error={errors.filter.name !== ''}
                helperText={errors.filter.name}
              />
              <p className={styles.p}>{t('description_filter')}</p>
            </div>

            <div className={styles.period}>
              <Period
                title={t('period')}
                period={period}
                setPeriodFilter={setPeriod}
                errors={errors.filter.period}
              />
            </div>

            <div className={styles.groupers}>
              <Groupers
                title={t('groupers')}
                options={species.data}
                groupers={groupers}
                setGroupersFilter={setGroupers}
                errors={errors.filter.groupers}
              />
            </div>

            <div className={styles.variables}>
              <Variables
                title={t('variables')}
                variables={variables}
                setVariablesFilter={setVariables}
                errors={errors.filter.variables}
              />
            </div>

            {filters.filterLoading && <LoaderTailSpin />}
            <div>
              {!filters.filterLoading && (
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  type="submit"
                >
                  {t('save')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    filters: state.filters,
    species: state.species,
    errors: state.errors,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { ...SpeciesAction, ...FilterAction, ...ErrorsAction },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormFilter);
