/**
 * actions types
 */
export enum FisheryBetDataTypes {
  FISHERY_BET_DATA_REQUEST = '@chart/FISHERY_BET_DATA_REQUEST',
  FISHERY_BET_DATA_SUCCESS = '@chart/FISHERY_BET_DATA_SUCCESS',
  FISHERY_BET_DATA_FAILURE = '@chart/FISHERY_BET_DATA_FAILURE',
}

export type { FisheryData, FisheryDataState } from '../../types';
