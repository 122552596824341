import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: '5px',
    },
    title: {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    contents: {
      marginTop: '3rem',
    },
    generateButtonBox: {
      margin: theme.spacing(1),
      textAlign: 'right',
      marginTop: theme.spacing(5),
    },
  })
);

export default Styles;
