/**
 * actions types
 */
export enum FishingTripTypes {
  FISHING_TRIP_REQUEST = '@fishingTrip/FISHING_TRIP_REQUEST',
  FISHING_TRIP_SUCCESS = '@fishingTrip/FISHING_TRIP_SUCCESS',
  FISHING_TRIP_FAILURE = '@fishingTrip/FISHING_TRIP_FAILURE',
}

/**
 * data types
 */
export interface FishingTrip {
  createdAt: Date;
  updatedAt: Date;
  externalId: string;
  vesselExternalId: string;
  skipperExternalId: string;
  shipOwnerExternalId: string;
  portDeparture: string;
  dateDeparture: Date;
  portArrival: string;
  dateArrival: Date;
  targetSpecies: string[];
  vessel: Entity;
  skipper: Entity;
  shipowner: Entity;
}

export interface Entity {
  createdAt: Date;
  updatedAt: Date;
  externalId: string;
  name: string;
}

/**
 * state type
 */
export interface FishingTripState {
  readonly data: FishingTrip[];
  readonly count: number;
  readonly loading: boolean;
  readonly error: boolean;
}
