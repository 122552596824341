import { call, put } from 'redux-saga/effects';
import { ReportTypes } from './types';
import i18n from '../../../i18n';
import {
  requestPublicData,
  requestCsvData,
} from '../../../services/api/reportApi';
import { loadSuccessReport, loadFailure, loadSuccessCsv } from './actions';
import { Filter } from '../filters/types';
import { filterViolation } from '../errors/action';
import { filterViolationParser } from '../../../utils/errorParser';

export function* requestReport({
  payload,
}: {
  type: typeof ReportTypes.LOAD_REQUEST_REPORT;
  payload: Filter;
}) {
  try {
    const response = yield call(requestPublicData, payload);
    if (response.status === 200) {
      yield put(loadSuccessReport(response.data.data, response.data.count));
    } else {
      yield put(loadFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(loadFailure());
      yield put(filterViolation(filterViolationParser(err.response.data)));
    } else {
      yield put(loadFailure());
    }
  }
}

export function* requestCsv({
  payload,
}: {
  type: typeof ReportTypes.LOAD_REQUEST_CSV;
  payload: Filter;
}) {
  const getCsvName = (): string =>
    `${i18n.t('csv')}_${Date.now().toString().substr(-5)}.csv`;

  try {
    const response = yield call(requestCsvData, payload);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', getCsvName());
      document.body.appendChild(link); // Required for this to work in FireFox
      link.click();

      yield put(loadSuccessCsv());
    }
  } catch (err) {
    yield put(loadFailure());
  }
}
