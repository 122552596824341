import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../../store';
import * as AlbContentActions from '../../../store/modules/content/alb/actions';
import * as ErrorsActions from '../../../store/modules/errors/action';
import SpeciesContentManagement from './index';

const mapStateToProps = (state: ApplicationState) => {
  return {
    errors: state.errors,
    speciesContent: state.albContent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      speciesContentSubmit: AlbContentActions.albContentSubmit,
      speciesContentRequest: AlbContentActions.albContentRequest,
      clearSpeciesContentViolation: ErrorsActions.clearSpeciesContentViolation,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeciesContentManagement);
