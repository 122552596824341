import { Reducer } from 'redux';
import { SpeciesContentState, BetContentTypes } from './types';

const INITIAL_STATE: SpeciesContentState = {
  content: {
    title: '',
    introductoryText: '',
    lineChartText: '',
    heatMapText: '',
  },
  loaded: false,
  loading: false,
  error: false,
};

const reducer: Reducer<SpeciesContentState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case BetContentTypes.BET_SUBMIT:
    case BetContentTypes.BET_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: false,
      };
    case BetContentTypes.BET_LOADED:
      return {
        ...state,
        content: action.payload,
        loaded: true,
        loading: false,
        error: false,
      };
    case BetContentTypes.BET_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
