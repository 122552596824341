import { call, put } from 'redux-saga/effects';
import { YftContentTypes, SpeciesContent } from './types';
import {
  getYftContent as getYftContentApi,
  setYftContent as setYftContentApi,
} from '../../../../services/api/contentApi';

import { yftContentLoaded, yftContentFailure } from './actions';

import { speciesContentViolationParser } from '../../../../utils/errorParser';
import { speciesContentViolation } from '../../errors/action';

export function* setYftContent({
  payload,
}: {
  type: typeof YftContentTypes.YFT_SUBMIT;
  payload: SpeciesContent;
}) {
  try {
    const response = yield call(setYftContentApi, payload);

    if (response.status === 200) {
      yield put(yftContentLoaded(response.data));
    } else {
      yield put(yftContentFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(yftContentFailure());
      yield put(
        speciesContentViolation(
          speciesContentViolationParser(err.response.data)
        )
      );
    }

    yield put(yftContentFailure());
  }
}

export function* getYftContent() {
  try {
    const response = yield call(getYftContentApi);
    if (response.status === 200) {
      yield put(yftContentLoaded(response.data));
    } else {
      yield put(yftContentFailure());
    }
  } catch (err) {
    yield put(yftContentFailure());
  }
}
