import api from './api';

// Home
export const getMainSpeciesChart = () => api.get('/home-data/main-species');

export const getFisheryDataChart = () => api.get('/home-data/fishery-data');

export const getFisheryProductionData = () =>
  api.get('/home-data/fishery-production');

// Swo
export const getFisherySwoDataChart = () => api.get('/swo-data/fishery-data');

export const getFisherySwoProductionData = () =>
  api.get('/swo-data/fishery-production');

export const getSwoHeatmap = () => api.get('/swo-data/heat-map');

// Bet
export const getFisheryBetDataChart = () => api.get('/bet-data/fishery-data');

export const getFisheryBetProductionData = () =>
  api.get('/bet-data/fishery-production');

export const getBetHeatmap = () => api.get('/bet-data/heat-map');

// Alb
export const getFisheryAlbDataChart = () => api.get('/alb-data/fishery-data');

export const getFisheryAlbProductionData = () =>
  api.get('/alb-data/fishery-production');

export const getAlbHeatmap = () => api.get('/alb-data/heat-map');

// Yft
export const getFisheryYftDataChart = () => api.get('/yft-data/fishery-data');

export const getFisheryYftProductionData = () =>
  api.get('/yft-data/fishery-production');

export const getYftHeatmap = () => api.get('/yft-data/heat-map');

// ByCatch
export const getFisheryByCatchDataChart = () =>
  api.get('/bycatch-data/fishery-data');

export const getFisheryByCatchProductionData = () =>
  api.get('/bycatch-data/fishery-production');

export const getFisheryByCatchSpeciesDistribution = () =>
  api.get('/bycatch-data/bycatch-species');

export const getByCatchHeatmap = () => api.get('/bycatch-data/heat-map');

export const getHomeHeatmap = () => api.get('/home-data/heat-map');
