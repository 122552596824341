/**
 * actions types
 */
export enum ByCatchSpeciesDistributionTypes {
  BYCATCH_SPECIES_DISTRIBUTION_REQUEST = '@chart/BYCATCH_SPECIES_DISTRIBUTION_REQUEST',
  BYCATCH_SPECIES_DISTRIBUTION_SUCCESS = '@chart/BYCATCH_SPECIES_DISTRIBUTION_SUCCESS',
  BYCATCH_SPECIES_DISTRIBUTION_FAILURE = '@chart/BYCATCH_SPECIES_DISTRIBUTION_FAILURE',
}

export type {
  SpeciesDistributionData,
  SpeciesDistributionState,
} from '../../types';
