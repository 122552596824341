import { call, put } from 'redux-saga/effects';
import { mainSpeciesSuccess, mainSpeciesFailure } from './actions';
import { getMainSpeciesChart } from '../../../../../services/api/dataProviderApi';

export function* loadMainSpecies() {
  try {
    const response = yield call(getMainSpeciesChart);
    if (response.status === 200) {
      yield put(mainSpeciesSuccess(response.data.data));
    } else if (response.status === 401) {
      yield put(mainSpeciesFailure());
    } else {
      yield put(mainSpeciesFailure());
    }
  } catch (err) {
    yield put(mainSpeciesFailure());
  }
}
