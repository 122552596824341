import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import Login from './pages/Login';
import ForgotPassword from './pages/Password/ForgotPassword';
import MainPage from './pages/MainPage';
import { AuthState } from './store/modules/auth/types';
import { ApplicationState } from './store';
import PrivateRoute from './components/PrivateRoute';
import PublicMainPage from './pages/PublicMainPage';
import Logout from './pages/Logout';
import ResetPassword from './pages/Password/ResetPassword';
import Redirect from './pages/Redirect';

interface StateProps {
  auth: AuthState;
}

const Routes: React.FC<StateProps> = (props: StateProps) => {
  const { auth } = props;
  return (
    <BrowserRouter>
      <CssBaseline />
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <PrivateRoute
          path="/mainpage*"
          exact
          component={MainPage}
          isSignedIn={auth.signed}
        />

        <Route
          path="/reset-forgotten-password"
          exact
          component={ResetPassword}
        />
        <Route path="/redirect" component={Redirect} />

        <Route path="/" component={PublicMainPage} />

        <Route path="*" component={() => <h1>PAGE NOT FOUND</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Routes);
