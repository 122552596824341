import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    internContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formStyles: {
      width: '50%',
      margin: theme.spacing(5),
    },
    button: {
      width: theme.spacing(15),
      alignSelf: 'flex-end',
      marginRight: theme.spacing(5),
      color: 'white',
      backgroundColor: theme.palette.info.main,
      padding: theme.spacing(1, 3),

      '& .MuiButton-label': {
        fontWeight: 'bold',
      },
    },
  })
);

export default Styles;
