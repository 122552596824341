import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',

      '& label': {
        marginTop: theme.spacing(1),
      },
    },
    title: {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    errorMessage: {
      fontSize: '13px',
      color: 'red',
    },
  })
);

export default Styles;
