import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import Styles from './styles';
import AliancaLogo from '../../assets/footer/AliancaLogo.png';
import OceanaLogo from '../../assets/footer/OceanaLogo.png';
import GlobalFishingWatchLogo from '../../assets/footer/GlobalFishingWatchLogo.png';
import AlbatrozLogo from '../../assets/footer/AlbatrozLogo.png';
import TamarLogo from '../../assets/footer/TamarLogo.png';
import PaicheLogo from '../../assets/footer/PaicheLogo.png';
import UfrpeLogo from '../../assets/footer/UfrpeLogo.png';

interface Props {
  color: string;
}

const Footer: React.FC<Props> = (props: Props) => {
  const { color } = props;
  const classes = Styles({ color });
  const { t } = useTranslation();

  const partnersLine1 = [
    {
      name: 'alianacaLogo',
      url: '# ',
      logo: AliancaLogo,
      altLogo: 'Aliança logo',
      height: '80px',
    },
    {
      name: 'globalFishingWatchLogo',
      url: 'https://globalfishingwatch.org',
      logo: GlobalFishingWatchLogo,
      altLogo: 'Global Fishing Watch logo',
      height: '80px',
    },
    {
      name: 'oceanaLogo',
      url: 'https://brasil.oceana.org/',
      logo: OceanaLogo,
      altLogo: 'Oceana logo',
      height: '50px',
    },
  ];

  const partnersLine2 = [
    {
      name: 'albatrozLogo',
      url: 'https://projetoalbatroz.org.br',
      logo: AlbatrozLogo,
      altLogo: 'Albatroz logo',
      height: '60px',
    },
    {
      name: 'tamarLogo',
      url: 'https://www.tamar.org.br',
      logo: TamarLogo,
      altLogo: 'Tamar logo',
      height: '85px',
    },
    {
      name: 'paicheLogo',
      url: 'https://www.paiche.com.br',
      logo: PaicheLogo,
      altLogo: 'Paiche logo',
      height: '65px',
    },
    {
      name: 'ufrpeLogo',
      url: 'http://www.ufrpe.br',
      logo: UfrpeLogo,
      altLogo: 'UFRPE logo',
      height: '85px',
    },
  ];

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Typography
        variant="body1"
        component="h4"
        color="primary"
        className={classes.footerTitle}
      >
        {t('part_of_the_initiative')}
      </Typography>
      <Grid
        container
        className={classes.partnerLine}
        justify="space-evenly"
        alignItems="center"
      >
        {partnersLine1.map((partner) => (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.partner}
            item
            key={partner.name}
            md={3}
            sm={6}
            xs={12}
          >
            <a href={partner.url} target="_blank" rel="noopener noreferrer">
              <img
                height={partner.height}
                src={partner.logo}
                alt={partner.altLogo}
              />
            </a>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        className={classes.partnerLine}
        justify="space-evenly"
        alignItems="center"
      >
        {partnersLine2.map((partner) => (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.partner}
            item
            key={partner.name}
            md={3}
            sm={6}
            xs={12}
          >
            <a href={partner.url} target="_blank" rel="noopener noreferrer">
              <img
                height={partner.height}
                src={partner.logo}
                alt={partner.altLogo}
              />
            </a>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default Footer;
