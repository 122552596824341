import { createStyles, makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      width: '50px',
      height: '50px',
      color: 'red',
    },
    message: {
      alignItems: 'center',
      textAlign: 'center',
    },
  })
);

export default Styles;
