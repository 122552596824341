import { action } from 'typesafe-actions';
import { FisheryAlbDataTypes, FisheryData } from './types';

export const fisheryAlbDataRequest = () =>
  action(FisheryAlbDataTypes.FISHERY_ALB_DATA_REQUEST);

export const fisheryAlbDataSuccess = (data: FisheryData[]) =>
  action(FisheryAlbDataTypes.FISHERY_ALB_DATA_SUCCESS, data);

export const fisheryAlbDataFailure = () =>
  action(FisheryAlbDataTypes.FISHERY_ALB_DATA_FAILURE);
