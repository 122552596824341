import { action } from 'typesafe-actions';
import { SwoHeatmapTypes, Point } from './types';

export const swoHeatmapRequest = () =>
  action(SwoHeatmapTypes.SWO_HEATMAP_REQUEST);

export const swoHeatmapSuccess = (data: Point[]) =>
  action(SwoHeatmapTypes.SWO_HEATMAP_SUCCESS, data);

export const swoHeatmapFailure = () =>
  action(SwoHeatmapTypes.SWO_HEATMAP_FAILURE);
