import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { AuthState } from './modules/auth/types';
import { UsersState } from './modules/users/types';
import { SpeciesState } from './modules/species/types';
import { FiltersState } from './modules/filters/types';
import { ReportState } from './modules/report/types';
import { ErrorState } from './modules/errors/types';
import {
  FisheryDataState,
  FisheryProductionDataState,
  SpeciesDistributionState,
  HeatmapState,
} from './modules/dataProvider/types';
import { PasswordState } from './modules/password/types';
import { HomeContentState } from './modules/content/home/types';
import { AboutContentState } from './modules/content/about/types';
import { PublicDataGeneratorContentState } from './modules/content/publicDataGenerator/types';
import { FishingTripState } from './modules/fishingTrip/types';
import { LogbookState } from './modules/logbook/types';
import { VesselState } from './modules/vessel/types';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import { SpeciesContentState } from './modules/content/types';
import { ByCatchContentState } from './modules/content/byCatch/types';

export interface ApplicationState {
  auth: AuthState;
  password: PasswordState;
  users: UsersState;
  species: SpeciesState;
  filters: FiltersState;
  report: ReportState;
  errors: ErrorState;
  mainSpecies: SpeciesDistributionState;
  fisheryData: FisheryDataState;
  fisheryProduction: FisheryProductionDataState;
  homeHeatmap: HeatmapState;
  fisherySwoData: FisheryDataState;
  fisherySwoProduction: FisheryProductionDataState;
  swoHeatmap: HeatmapState;
  fisheryBetData: FisheryDataState;
  fisheryBetProduction: FisheryProductionDataState;
  betHeatmap: HeatmapState;
  fisheryAlbData: FisheryDataState;
  fisheryAlbProduction: FisheryProductionDataState;
  albHeatmap: HeatmapState;
  fisheryYftData: FisheryDataState;
  fisheryYftProduction: FisheryProductionDataState;
  yftHeatmap: HeatmapState;
  fisheryByCatchData: FisheryDataState;
  fisheryByCatchProduction: FisheryProductionDataState;
  byCatchSpeciesDistribution: SpeciesDistributionState;
  byCatchHeatmap: HeatmapState;
  homeContent: HomeContentState;
  aboutContent: AboutContentState;
  swoContent: SpeciesContentState;
  yftContent: SpeciesContentState;
  betContent: SpeciesContentState;
  albContent: SpeciesContentState;
  byCatchContent: ByCatchContentState;
  publicDataGeneratorContent: PublicDataGeneratorContentState;
  fishingTrip: FishingTripState;
  logbook: LogbookState;
  vessel: VesselState;
}

const sagaMiddleware = createSagaMiddleware();
const store: Store<ApplicationState> = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;
