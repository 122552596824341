import { action } from 'typesafe-actions';
import { FiltersTypes, Filter } from './types';

export * from '../errors/action';

export const getFilterRequest = (id: string) =>
  action(FiltersTypes.GET_FILTER_REQUEST, id);
export const getFilterSuccess = (filter: Filter) =>
  action(FiltersTypes.GET_FILTER_SUCCESS, filter);

export const listFiltersRequest = () =>
  action(FiltersTypes.LIST_FILTERS_REQUEST);
export const listFiltersSuccess = (data: Filter[], totalFilters: number) =>
  action(FiltersTypes.LIST_FILTERS_SUCCESS, { data, totalFilters });
export const listFiltersFailure = () =>
  action(FiltersTypes.LIST_FILTERS_FAILURE);
export const clearFilterState = () => action(FiltersTypes.CLEAR_FILTER_STATE);

export const filterSuccess = () => action(FiltersTypes.FILTER_SUCCESS);
export const filterFailure = () => action(FiltersTypes.FILTER_FAILURE);

export const addNewFilterRequest = (filter: Filter) =>
  action(FiltersTypes.ADD_FILTER, filter);
export const updateFilterRequest = (filter: Filter) =>
  action(FiltersTypes.UPDATE_FILTER, filter);
export const deleteFilterRequest = (id: string) =>
  action(FiltersTypes.DELETE_FILTER, id);
