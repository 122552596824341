import { Point, HeatmapState } from '../../types';

/**
 * actions types
 */
export enum YftHeatmapTypes {
  YFT_HEATMAP_REQUEST = '@yft/YFT_HEATMAP_REQUEST',
  YFT_HEATMAP_SUCCESS = '@yft/YFT_HEATMAP_SUCCESS',
  YFT_HEATMAP_FAILURE = '@yft/YFT_HEATMAP_FAILURE',
}

/**
 * data types
 */

export type { HeatmapState, Point };
