import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    formControl: {
      margin: theme.spacing(0.3),
      minWidth: 120,
      width: '100%',

      specie: {
        bottom: '0',
      },
    },
    slideDiv: {
      display: 'flex',
      flexWrap: 'wrap',

      '& label': {
        width: '45%',
        margin: theme.spacing(1),
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 1,
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.light,
      fontSize: '1.4rem',
    },
    title: {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    errorMessage: {
      fontSize: '13px',
      color: 'red',
    },
  })
);

export default Styles;
