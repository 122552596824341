import { call, put } from 'redux-saga/effects';
import { fisheryYftDataSuccess, fisheryYftDataFailure } from './actions';
import { getFisheryYftDataChart } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryYftDataChart() {
  try {
    const response = yield call(getFisheryYftDataChart);
    if (response.status === 200) {
      yield put(fisheryYftDataSuccess(response.data.data));
    } else if (response.status === 401) {
      yield put(fisheryYftDataFailure());
    } else {
      yield put(fisheryYftDataFailure());
    }
  } catch (err) {
    yield put(fisheryYftDataFailure());
  }
}
