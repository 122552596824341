import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type';
import Styles from './styles';
import { ApplicationState } from '../../store';
import * as FisheryActions from '../../store/modules/dataProvider/homeData/fisheryData/actions';
import * as FisheryProductionActions from '../../store/modules/dataProvider/homeData/fisheryProduction/actions';
import * as MainSpeciesActions from '../../store/modules/dataProvider/homeData/mainSpecies/actions';
import * as homeHeatmapActions from '../../store/modules/dataProvider/homeData/heatmap/actions';
import * as HomeContentActions from '../../store/modules/content/home/actions';
import { SpeciesDistributionState } from '../../store/modules/dataProvider/homeData/mainSpecies/types';
import { FisheryDataState } from '../../store/modules/dataProvider/homeData/fisheryData/types';
import Footer from '../../components/Footer';
import LogoFishSmall from '../../assets/fishSmallIcon.png';
import LogoBoatSmall from '../../assets/boatSmallIcon.png';
import LogoFishHookSmall from '../../assets/fishHookSmallIcon.png';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import { HomeContentState } from '../../store/modules/content/home/types';
import { HeatmapState } from '../../store/modules/dataProvider/homeData/heatmap/types';
import ErrorComponent from '../../components/ErrorComponent';
import Cards from '../../components/Cards';
import ContentBlock from '../../components/ContentBlock';
import PictureChart from '../../components/Charts/Sliced';
import FisheryDataChart from '../../components/Charts/XYChart';
import { FisheryProductionDataState } from '../../store/modules/dataProvider/homeData/fisheryProduction/types';

interface StateProps {
  homeHeatmap: HeatmapState;
  fisheryData: FisheryDataState;
  homeContent: HomeContentState;
  mainSpecies: SpeciesDistributionState;
  fisheryProduction: FisheryProductionDataState;
}

interface DispatchProps {
  mainSpeciesRequest(): void;
  fisheryDataRequest(): void;
  homeContentRequest(): void;
  homeHeatmapRequest(): void;
  fisheryProductionDataRequest(): void;
}

type Props = StateProps & DispatchProps;
const Home: React.FC<Props> = (props: Props) => {
  const classes = Styles();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    mainSpecies,
    fisheryData,
    homeContent,
    homeHeatmap,
    fisheryProduction,
    mainSpeciesRequest,
    fisheryDataRequest,
    homeContentRequest,
    homeHeatmapRequest,
    fisheryProductionDataRequest,
  } = props;

  const cards = [
    {
      title: t('total_catch'),
      icon: LogoFishSmall,
      value: `${toNumber(fisheryProduction.data.totalCatch).toLocaleString(
        'pt-BR'
      )} Kg`,
      iconAlt: 'Total catch icon',
    },
    {
      title: t('catching_fish'),
      icon: LogoFishSmall,
      value: `${toNumber(fisheryProduction.data.catchingFish).toLocaleString(
        'pt-BR'
      )} ${t('un')}`,
      iconAlt: 'Catching fish icon',
    },
    {
      title: t('fishing_trips'),
      icon: LogoBoatSmall,
      value: `${toNumber(fisheryProduction.data.fishingTrips).toLocaleString(
        'pt-BR'
      )}`,
      iconAlt: 'Fishing trips icon',
    },
    {
      title: t('fishing_throws'),
      icon: LogoFishHookSmall,
      value: `${toNumber(fisheryProduction.data.fishingThrows).toLocaleString(
        'pt-BR'
      )}`,
      iconAlt: 'Fishing throws icon',
    },
  ];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    homeContentRequest();
    mainSpeciesRequest();
    fisheryDataRequest();
    homeHeatmapRequest();
    fisheryProductionDataRequest();
  }, [
    homeContentRequest,
    mainSpeciesRequest,
    fisheryDataRequest,
    homeHeatmapRequest,
    fisheryProductionDataRequest,
  ]);

  useEffect(() => {
    if (
      mainSpecies.error ||
      fisheryData.error ||
      homeContent.error ||
      fisheryProduction.error ||
      homeHeatmap.error
    ) {
      setLoading(false);
      setLoaded(false);
      setError(true);
    } else if (
      mainSpecies.loading ||
      fisheryData.loading ||
      homeContent.loading ||
      fisheryProduction.loading
    ) {
      setLoading(true);
      setLoaded(false);
      setError(false);
    } else {
      setLoading(false);
      setLoaded(true);
      setError(false);
    }
  }, [mainSpecies, fisheryData, homeContent, fisheryProduction, homeHeatmap]);

  return (
    <div>
      {loading && <LoaderTailSpin />}
      {error && <ErrorComponent />}
      {loaded && (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <ContentBlock
              title={homeContent.content.title}
              topText={homeContent.content.introductoryText}
              bottomText={homeContent.content.lineChartText}
            >
              <Grid
                container
                justify="space-around"
                className={classes.cardsRow}
                spacing={3}
              >
                <Cards cards={cards} />
              </Grid>

              <Grid item className={classes.chartContainer}>
                <FisheryDataChart data={fisheryData.data} />
              </Grid>
            </ContentBlock>
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <ContentBlock
              title={homeContent.content.mainSpeciesChartTitle}
              bottomText={homeContent.content.mainSpeciesChartText}
            >
              <Grid item className={classes.mainSpecieschartContainer}>
                <PictureChart data={mainSpecies.data} />
              </Grid>
            </ContentBlock>
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <ContentBlock
              title={homeContent.content.heatMapTitle}
              bottomText={homeContent.content.heatMapText}
            >
              <div className={classes.globalFishingWatchMap}>
                <iframe
                  title="Global Fishing Watch"
                  allowFullScreen
                  width="100%"
                  height="100%"
                  src="https://globalfishingwatch.org/map/fishing-activity/brazil_open_tuna-public?readOnly=true&daysFromLatest=30"
                />
              </div>
            </ContentBlock>
          </Grid>

          <Footer color={theme.palette.background.paper} />
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    mainSpecies: state.mainSpecies,
    fisheryData: state.fisheryData,
    fisheryProduction: state.fisheryProduction,
    homeContent: state.homeContent,
    homeHeatmap: state.homeHeatmap,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...FisheryActions,
      ...FisheryProductionActions,
      ...MainSpeciesActions,
      ...HomeContentActions,
      ...homeHeatmapActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
