/**
 * actions types
 */
export enum FisheryYftDataTypes {
  FISHERY_YFT_DATA_REQUEST = '@chart/FISHERY_YFT_DATA_REQUEST',
  FISHERY_YFT_DATA_SUCCESS = '@chart/FISHERY_YFT_DATA_SUCCESS',
  FISHERY_YFT_DATA_FAILURE = '@chart/FISHERY_YFT_DATA_FAILURE',
}

export type { FisheryData, FisheryDataState } from '../../types';
