/**
 * actions types
 */
export enum FisheryProductionDataTypes {
  FISHERY_PRODUCTION_REQUEST = '@chart/FISHERY_PRODUCTION_REQUEST',
  FISHERY_PRODUCTION_SUCCESS = '@chart/FISHERY_PRODUCTION_SUCCESS',
  FISHERY_PRODUCTION_FAILURE = '@chart/FISHERY_PRODUCTION_FAILURE',
}

export type {
  FisheryProductionData,
  FisheryProductionDataState,
} from '../../types';
