/**
 * actions types
 */
export enum AboutContentTypes {
  ABOUT_CONTENT_SUBMIT = '@aboutContent/ABOUT_CONTENT_SUBMIT',
  ABOUT_CONTENT_REQUEST = '@aboutContent/ABOUT_CONTENT_REQUEST',
  ABOUT_CONTENT_LOADED = '@aboutContent/ABOUT_CONTENT_SUCCESS',
  ABOUT_CONTENT_FAILURE = '@aboutContent/ABOUT_CONTENT_FAILURE',
}

/**
 * data types
 */
export interface AboutContent {
  title: string;
  text: string;
}

/**
 * state type
 */
export interface AboutContentState {
  readonly content: AboutContent;
  readonly loaded: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
