import { TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseForm from '../../../components/BaseForm';
import TextEditor from '../../../components/TextEditor';
import {
  SpeciesContent,
  SpeciesContentState,
} from '../../../store/modules/content/types';
import { ErrorState } from '../../../store/modules/errors/types';

interface StateProps {
  errors: ErrorState;
  speciesContent: SpeciesContentState;
}

interface DispatchProps {
  speciesContentRequest(): void;
  clearSpeciesContentViolation(): void;
  speciesContentSubmit(data: SpeciesContent): void;
}

type Props = StateProps & DispatchProps;

const SpeciesContentManagement: React.FC<Props> = (props: Props) => {
  const {
    errors,
    speciesContent,
    speciesContentSubmit,
    speciesContentRequest,
    clearSpeciesContentViolation,
  } = props;

  const [title, setTitle] = useState('');
  const [introductoryText, setIntroductoryText] = useState('');
  const [lineChartText, setLineChartText] = useState('');
  const [heatMapText, setHeatMapText] = useState('');

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // TODO: Codedebt: Handle with empty texts #241
  // submit
  const onSubmit = useCallback(async () => {
    const data: SpeciesContent = {
      title,
      introductoryText,
      lineChartText,
      heatMapText,
    };

    speciesContentSubmit(data);
    clearSpeciesContentViolation();
  }, [
    title,
    introductoryText,
    lineChartText,
    heatMapText,
    speciesContentSubmit,
    clearSpeciesContentViolation,
  ]);

  useEffect(() => {
    speciesContentRequest();
    clearSpeciesContentViolation();
    setTitle(speciesContent.content.title);
    setIntroductoryText(speciesContent.content.introductoryText);
    setLineChartText(speciesContent.content.lineChartText);
    setHeatMapText(speciesContent.content.heatMapText);
  }, [
    speciesContent.content.heatMapText,
    speciesContent.content.introductoryText,
    speciesContent.content.lineChartText,
    speciesContent.content.title,
    clearSpeciesContentViolation,
    speciesContentRequest,
  ]);

  useEffect(() => {
    setLoading(speciesContent.loading && !speciesContent.error);
  }, [speciesContent.error, speciesContent.loading]);

  return (
    <BaseForm
      loading={loading}
      pageTitle={t('content_manager')}
      formTitle={speciesContent.content.title}
      buttonLabel={t('save')}
      onSubmit={onSubmit}
    >
      <TextField
        label={t('title_label')}
        value={title}
        fullWidth
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        required
        error={errors.speciesContent.title !== ''}
        helperText={errors.speciesContent.title}
      />

      <TextEditor
        required
        label={t('introductory_text_label')}
        textValue={introductoryText}
        setTextValue={setIntroductoryText}
        error={errors.speciesContent.introductoryText}
      />

      <TextEditor
        required
        label={t('line_chart_text_label')}
        textValue={lineChartText}
        setTextValue={setLineChartText}
        error={errors.speciesContent.lineChartText}
      />

      <TextEditor
        required
        label={t('heat_map_text_label')}
        textValue={heatMapText}
        setTextValue={setHeatMapText}
        error={errors.speciesContent.heatMapText}
      />
    </BaseForm>
  );
};

export default SpeciesContentManagement;
