import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Container, Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import FilterComponent from './components/Filter';
import Footer from '../../components/Footer';
import PublicGeneratorTable from './components/PublicGeneratorTable';
import TopBoxContainer from '../../components/TopBoxContainer';
import * as SpeciesAction from '../../store/modules/species/actions';
import * as FiltersAction from '../../store/modules/filters/actions';
import * as ReportAction from '../../store/modules/report/actions';
import * as PublicDataGeneratorContentAction from '../../store/modules/content/publicDataGenerator/actions';
import * as ErrorsAction from '../../store/modules/errors/action';
import { ApplicationState } from '../../store';
import { SpeciesState } from '../../store/modules/species/types';
import { ReportState } from '../../store/modules/report/types';
import { ErrorState } from '../../store/modules/errors/types';
import {
  Filter,
  PeriodFilter,
  GrouperFilter,
  VariableFilter,
  FiltersState,
} from '../../store/modules/filters/types';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import { PublicDataGeneratorContentState } from '../../store/modules/content/publicDataGenerator/types';
import ContentBlock from '../../components/ContentBlock';

interface StateProps {
  report: ReportState;
  species: SpeciesState;
  filters: FiltersState;
  errors: ErrorState;
  publicDataGeneratorContent: PublicDataGeneratorContentState;
}

interface DispatchProps {
  speciesResquest(): void;
  listFiltersRequest(): void;
  loadRequestReport(filters: Filter): void;
  setPeriodFilter(period: PeriodFilter): void;
  setGroupersFilter(groupers: GrouperFilter): void;
  setVariablesFilter(variables: VariableFilter): void;
  setFilter(filter: Filter): void;
  loadRequestCsv(filter: Filter): void;
  publicDataGeneratorContentRequest(): void;
  clearFilterViolation(): void;
}

type Props = StateProps & DispatchProps;

const PublicDataGenerator: React.FC<Props> = (props: Props) => {
  const classes = Styles();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    species,
    filters,
    report,
    errors,
    publicDataGeneratorContent,
    speciesResquest,
    listFiltersRequest,
    loadRequestReport,
    setPeriodFilter,
    setGroupersFilter,
    setVariablesFilter,
    setFilter,
    loadRequestCsv,
    publicDataGeneratorContentRequest,
    clearFilterViolation,
  } = props;

  useEffect(() => {
    publicDataGeneratorContentRequest();
    speciesResquest();
    listFiltersRequest();
  }, [publicDataGeneratorContentRequest, speciesResquest, listFiltersRequest]);

  const csvDownload = () => {
    loadRequestCsv(report.filter);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="space-between"
      >
        {(species.loading || filters.listFiltersLoading || report.loading) && (
          <LoaderTailSpin />
        )}
        <ContentBlock
          title={publicDataGeneratorContent.content.title}
          topText={publicDataGeneratorContent.content.text}
        />
      </Grid>
      {(species.error || filters.listFiltersError) &&
        (!species.loading || filters.listFiltersLoading) && (
          <div>
            <h2 className={classes.error}>{t('something_wrong')}</h2>
          </div>
        )}
      <div className={classes.root}>
        <TopBoxContainer title={t('report')}>
          <FilterComponent
            species={species.data}
            setPeriodFilter={setPeriodFilter}
            setGroupersFilter={setGroupersFilter}
            setVariablesFilter={setVariablesFilter}
            setFilter={setFilter}
            periodErrors={errors.filter.period}
            groupersErrors={errors.filter.groupers}
            variablesErrors={errors.filter.variables}
            filters={filters.data}
            filter={report.filter}
            loadRequestReport={(filter: Filter) => {
              loadRequestReport(filter);
              clearFilterViolation();
            }}
          />
        </TopBoxContainer>
        {(!species.error || !filters.listFiltersError) &&
          (!species.loading || !filters.listFiltersLoading) && (
            <Container className={classes.contentContainer}>
              <PublicGeneratorTable
                dataHeader={report.dataHeader}
                data={report.reportData}
                countData={report.countData}
                isLoading={report.loading}
                error={report.error}
                csvDownload={csvDownload}
              />
            </Container>
          )}
        <Footer color={theme.palette.background.default} />
      </div>
    </>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    report: state.report,
    species: state.species,
    filters: state.filters,
    errors: state.errors,
    publicDataGeneratorContent: state.publicDataGeneratorContent,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...ReportAction,
      ...SpeciesAction,
      ...FiltersAction,
      ...PublicDataGeneratorContentAction,
      ...ErrorsAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicDataGenerator);
