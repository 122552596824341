import React from 'react';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Grid } from '@material-ui/core';
import { PeriodFilter } from '../../store/modules/filters/types';
import Styles from './styles';

interface Props {
  title: string;
  period: PeriodFilter;
  setPeriodFilter(period: PeriodFilter): void;
  errors?: string;
}

const Period: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { t } = useTranslation();

  const { title, period, setPeriodFilter, errors } = props;

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      {errors !== '' && <p className={styles.errorMessage}>{errors}</p>}
      <Grid item container justify="space-between">
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            label={t('period_initial')}
            className={styles.dateField}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={period.dateArrivalStart ? period.dateArrivalStart : null}
            onChange={(date) => {
              setPeriodFilter({
                ...period,
                dateArrivalStart:
                  date !== null
                    ? `${date?.toUTCString()}`
                    : ((null as unknown) as string),
              });
            }}
          />

          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            label={t('period_final')}
            className={styles.dateField}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={period.dateArrivalEnd ? period.dateArrivalEnd : null}
            onChange={(date) => {
              setPeriodFilter({
                ...period,
                dateArrivalEnd:
                  date !== null
                    ? `${date?.toUTCString()}`
                    : ((null as unknown) as string),
              });
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </div>
  );
};

export default Period;
