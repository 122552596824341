import { makeStyles, createStyles } from '@material-ui/core/styles';

export const Styles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  })
);
