/**
 * actions types
 */
export enum VesselTypes {
  VESSEL_REQUEST = '@vessel/VESSEL_REQUEST',
  VESSEL_SUCCESSS = '@vessel/VESSEL_SUCCESSS',
  VESSEL_ERROR = '@vessel/VESSEL_ERROR',
}

/**
 * data types
 */
export interface Vessel {
  externalId: string;
  name: string;
}

/**
 * state type
 */
export interface VesselState {
  readonly vessels: Vessel[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
}
