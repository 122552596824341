import { Point, HeatmapState } from '../../types';

/**
 * actions types
 */
export enum BetHeatmapTypes {
  BET_HEATMAP_REQUEST = '@bet/BET_HEATMAP_REQUEST',
  BET_HEATMAP_SUCCESS = '@bet/BET_HEATMAP_SUCCESS',
  BET_HEATMAP_FAILURE = '@bet/BET_HEATMAP_FAILURE',
}

/**
 * data types
 */

export type { HeatmapState, Point };
