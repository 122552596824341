import { call, put } from 'redux-saga/effects';
import {
  byCatchSpeciesDistributionSuccess,
  byCatchSpeciesDistributionFailure,
} from './actions';
import { getFisheryByCatchSpeciesDistribution } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryByCatchSpeciesDistribution() {
  try {
    const response = yield call(getFisheryByCatchSpeciesDistribution);
    if (response.status === 200) {
      yield put(byCatchSpeciesDistributionSuccess(response.data.data));
    } else if (response.status === 401) {
      yield put(byCatchSpeciesDistributionFailure());
    } else {
      yield put(byCatchSpeciesDistributionFailure());
    }
  } catch (err) {
    yield put(byCatchSpeciesDistributionFailure());
  }
}
