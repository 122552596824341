import { Reducer } from 'redux';
import {
  SpeciesDistributionState,
  ByCatchSpeciesDistributionTypes,
} from './types';

const INITIAL_STATE: SpeciesDistributionState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<SpeciesDistributionState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_REQUEST:
      return { ...state, loading: true };
    case ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    }
    case ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
