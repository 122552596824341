import { call, put } from 'redux-saga/effects';
import { signSuccess, signFailure, logoutSuccess } from './actions';
import { Credentials, AuthTypes } from './types';
import {
  saveAccessToken,
  deleteAccessToken,
} from '../../../services/localStorageService';
import { authLogin } from '../../../services/api/authApi';
import { removeMe } from '../users/actions';

export function* signIn({
  payload,
}: {
  type: typeof AuthTypes.SIGN_REQUEST;
  payload: Credentials;
}) {
  try {
    const response = yield call(authLogin, payload);
    if (response.status === 201) {
      const { accessToken } = response.data;
      saveAccessToken(accessToken);
      yield put(signSuccess());
    } else {
      yield put(signFailure());
    }
  } catch (err) {
    yield put(signFailure());
  }
}

export function* logout() {
  deleteAccessToken();
  yield put(removeMe());
  yield put(logoutSuccess());
}
