import i18n from '../i18n';
import { ReportData } from '../store/modules/report/types';

export default (data: ReportData[]) => {
  if (data === null || data.length < 1) {
    return [];
  }

  const keys = Object.keys(data[0]);
  const labels = keys.map((key) => {
    switch (key) {
      case 'name':
        return i18n.t('name_filter');
      case 'portArrival':
        return i18n.t('county');
      case 'species':
        return i18n.t('species_singular');
      case 'year':
        return i18n.t('year');
      case 'month':
        return i18n.t('month');
      case 'catchQuilograms':
        return i18n.t('catch_kg');
      case 'fishQuantity':
        return i18n.t('catch_units');
      case 'fishingTripQuantity':
        return i18n.t('trips');
      case 'setQuantity':
        return i18n.t('throws');
      case 'vesselsQuantity':
        return i18n.t('vessels');
      default:
        return key;
    }
  });

  return labels;
};
