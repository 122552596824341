import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicDataGenerator from '../PublicDataGenerator';
import Home from '../Home';
import SpeciesSWO from '../Species/speciesSWO.container';
import SpeciesBET from '../Species/speciesBET.container';
import SpeciesALB from '../Species/speciesALB.container';
import SpeciesYFT from '../Species/speciesYFT.container';
import About from '../About';
import ByCatch from '../ByCatch';

export default function Router() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route
        path="/public-data-generator"
        exact
        component={PublicDataGenerator}
      />

      <Route path="/species/SWO" exact component={SpeciesSWO} />
      <Route path="/species/BET" exact component={SpeciesBET} />
      <Route path="/species/ALB" exact component={SpeciesALB} />
      <Route path="/species/YFT" exact component={SpeciesYFT} />

      <Route path="/about" exact component={About} />

      <Route path="/bycatch" exact component={ByCatch} />
    </Switch>
  );
}
