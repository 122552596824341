import { call, put } from 'redux-saga/effects';
import { albHeatmapSuccess, albHeatmapFailure } from './actions';
import { getAlbHeatmap as getAlbHeatmapApi } from '../../../../../services/api/dataProviderApi';

export function* getAlbHeatmap() {
  try {
    const response = yield call(getAlbHeatmapApi);
    if (response.status === 200) {
      yield put(albHeatmapSuccess(response.data.data));
    }
  } catch (err) {
    yield put(albHeatmapFailure());
  }
}
