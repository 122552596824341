import { action } from 'typesafe-actions';
import { FisheryBetDataTypes, FisheryData } from './types';

export const fisheryBetDataRequest = () =>
  action(FisheryBetDataTypes.FISHERY_BET_DATA_REQUEST);

export const fisheryBetDataSuccess = (data: FisheryData[]) =>
  action(FisheryBetDataTypes.FISHERY_BET_DATA_SUCCESS, data);

export const fisheryBetDataFailure = () =>
  action(FisheryBetDataTypes.FISHERY_BET_DATA_FAILURE);
