import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    cellBold: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
    cell: {
      fontSize: '1.4rem',
    },
    TotalCells: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      backgroundColor: theme.palette.background.default,
    },
    table: {
      minWidth: 650,
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
    },
    title: {
      color: 'black',
    },
  })
);

export default Styles;
