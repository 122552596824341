import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FFFFFF',
      padding: theme.spacing(5),
      borderRadius: '5px',
      boxShadow:
        '0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
    },
    message: {
      padding: '20px 0px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      margin: '5px',
    },
  })
);

export default Styles;
