import { call, put } from 'redux-saga/effects';
import {
  fisheryProductionDataSuccess,
  fisheryProductionDataFailure,
} from './actions';
import { getFisheryProductionData } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryProductionData() {
  try {
    const response = yield call(getFisheryProductionData);
    if (response.status === 200) {
      yield put(fisheryProductionDataSuccess(response.data));
    } else if (response.status === 401) {
      yield put(fisheryProductionDataFailure());
    } else {
      yield put(fisheryProductionDataFailure());
    }
  } catch (err) {
    yield put(fisheryProductionDataFailure());
  }
}
