import { action } from 'typesafe-actions';
import { FisheryYftDataTypes, FisheryData } from './types';

export const fisheryYftDataRequest = () =>
  action(FisheryYftDataTypes.FISHERY_YFT_DATA_REQUEST);

export const fisheryYftDataSuccess = (data: FisheryData[]) =>
  action(FisheryYftDataTypes.FISHERY_YFT_DATA_SUCCESS, data);

export const fisheryYftDataFailure = () =>
  action(FisheryYftDataTypes.FISHERY_YFT_DATA_FAILURE);
