/**
 * actions types
 */
export enum FisheryByCatchProductionDataTypes {
  FISHERY_BYCATCH_PRODUCTION_REQUEST = '@chart/FISHERY_BYCATCH_PRODUCTION_REQUEST',
  FISHERY_BYCATCH_PRODUCTION_SUCCESS = '@chart/FISHERY_BYCATCH_PRODUCTION_SUCCESS',
  FISHERY_BYCATCH_PRODUCTION_FAILURE = '@chart/FISHERY_BYCATCH_PRODUCTION_FAILURE',
}

export type {
  FisheryProductionData,
  FisheryProductionDataState,
} from '../../types';
