import { action } from 'typesafe-actions';
import { UsersTypes, User, NewUser, MyUser } from './types';

export * from '../errors/action';

export const getUserRequest = (id: string) =>
  action(UsersTypes.GET_USER_REQUEST, id);
export const getUserSuccess = (user: User) =>
  action(UsersTypes.GET_USER_SUCCESS, user);

export const listUsersRequest = (page: number) =>
  action(UsersTypes.LIST_USERS_REQUEST, page);
export const listUsersSuccess = (data: User[], totalUsers: number) =>
  action(UsersTypes.LIST_USERS_SUCCESS, { data, totalUsers });
export const listUsersFailure = () => action(UsersTypes.LIST_USERS_FAILURE);
export const clearUserState = () => action(UsersTypes.CLEAR_USER_STATE);

export const addNewUserRequest = (newUser: NewUser) =>
  action(UsersTypes.ADD_USER, newUser);
export const updateUserRequest = (user: User) =>
  action(UsersTypes.UPDATE_USER, user);

export const userSuccess = () => action(UsersTypes.USER_SUCCESS);
export const userFailure = () => action(UsersTypes.USER_FAILURE);

export const deleteUserRequest = (id: string) =>
  action(UsersTypes.DELETE_USER, id);

export const meRequest = () => action(UsersTypes.GET_ME_REQUEST);
export const meSuccess = (user: MyUser) =>
  action(UsersTypes.GET_ME_SUCCESS, user);
export const removeMe = () => action(UsersTypes.REMOVE_ME);
