import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import Period from '../../../../components/Period';
import Groupers from '../../../../components/Groupers';
import Variables from '../../../../components/Variables';
import {
  PeriodFilter,
  VariableFilter,
  GrouperFilter,
  Filter,
} from '../../../../store/modules/filters/types';
import PreConfigFilter from './PreConfigFilter';
import GenerateTableButton from './GenerateTableButton';
import { Species } from '../../../../store/modules/species/types';

interface Props {
  species: Species[];
  setPeriodFilter(period: PeriodFilter): void;
  setGroupersFilter(period: GrouperFilter): void;
  setVariablesFilter(period: VariableFilter): void;
  setFilter(filter: Filter): void;
  periodErrors: string;
  groupersErrors: string;
  variablesErrors: string;
  filter: Filter;
  filters: Filter[];
  loadRequestReport(filters: Filter): void;
}

const FilterComponent: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { t } = useTranslation();
  const {
    species,
    setPeriodFilter,
    setGroupersFilter,
    setVariablesFilter,
    setFilter,
    periodErrors,
    groupersErrors,
    variablesErrors,
    filter,
    filters,
    loadRequestReport,
  } = props;

  return (
    <Container className={styles.root}>
      <Grid container direction="row" justify="space-between" spacing={5}>
        <Grid item md={6} sm={3} xs={12}>
          <h3 className={styles.title}>{t('pre_config_filter')}</h3>
          <PreConfigFilter filters={filters} setSelectedFilter={setFilter} />
        </Grid>
        <Grid item md={6} sm={3} xs={12}>
          <Period
            title={t('report_period')}
            period={filter.period}
            setPeriodFilter={setPeriodFilter}
            errors={periodErrors}
          />
        </Grid>
      </Grid>

      <div className={styles.contents}>
        <Groupers
          title={t('groupers')}
          options={species}
          groupers={filter.groupers}
          setGroupersFilter={setGroupersFilter}
          errors={groupersErrors}
        />
      </div>

      <div className={styles.contents}>
        <Variables
          title={t('variables')}
          variables={filter.variables}
          setVariablesFilter={setVariablesFilter}
          errors={variablesErrors}
        />
      </div>

      <div className={styles.generateButtonBox}>
        <GenerateTableButton
          filter={filter}
          loadRequestReport={loadRequestReport}
        />
      </div>
    </Container>
  );
};

export default FilterComponent;
