import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const typography: TypographyOptions = {
  h2: {
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
    fontSize: '5.0rem',
    textAlign: 'center',
  },
  h5: {
    fontSize: '1.8rem',
  },
  body1: {
    fontSize: '1.8rem',
  },
  button: {
    fontSize: '1.4em',
  },
};
