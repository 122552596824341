import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Form } from '@unform/web';
import React, { ReactChild, ReactChildren } from 'react';
import LoaderTailSpin from '../LoaderTailSpin';
import Styles from './styles';

type Props = {
  onSubmit: () => Promise<void>;
  loading: boolean;
  pageTitle: string;
  formTitle: string;
  buttonLabel: string;
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
};

const BaseForm: React.FC<Props> = (props: Props) => {
  const {
    onSubmit,
    loading,
    pageTitle,
    formTitle,
    buttonLabel,
    children,
  } = props;

  const classes = Styles();
  const theme = useTheme();

  return (
    <Container>
      <Typography variant="h1" className={classes.pageTitle}>
        {pageTitle}
      </Typography>
      {loading && <LoaderTailSpin />}
      <Box
        boxShadow={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="3px"
        color="white"
        width={theme.spacing(26)}
        height={theme.spacing(6)}
        position="relative"
        top="2rem"
        className={classes.formTitleBox}
      >
        <Typography variant="h2">{formTitle}</Typography>
      </Box>
      <Box boxShadow={4} marginLeft="2rem" padding={theme.spacing(0.5)}>
        <Form className={classes.form} noValidate onSubmit={onSubmit}>
          {children}
          <div>
            {!loading && (
              <Button
                size="medium"
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submitButton}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </Form>
      </Box>
    </Container>
  );
};

export default BaseForm;
