/**
 * actions types
 */
export enum AlbContentTypes {
  ALB_SUBMIT = '@albContent/ALB_SUBMIT',
  ALB_REQUEST = '@albContent/ALB_REQUEST',
  ALB_LOADED = '@albContent/ALB_SUCCESS',
  ALB_FAILURE = '@albContent/ALB_FAILURE',
}

export type { SpeciesContent, SpeciesContentState } from '../types';
