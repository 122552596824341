import { action } from 'typesafe-actions';
import { FisheryAlbProductionDataTypes, FisheryProductionData } from './types';

export const fisheryAlbProductionDataRequest = () =>
  action(FisheryAlbProductionDataTypes.FISHERY_ALB_PRODUCTION_REQUEST);

export const fisheryAlbProductionDataSuccess = (data: FisheryProductionData) =>
  action(FisheryAlbProductionDataTypes.FISHERY_ALB_PRODUCTION_SUCCESS, data);

export const fisheryAlbProductionDataFailure = () =>
  action(FisheryAlbProductionDataTypes.FISHERY_ALB_PRODUCTION_FAILURE);
