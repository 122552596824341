import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import TitleLine from '../../components/TitleLine';
import * as FishingTripAction from '../../store/modules/fishingTrip/actions';
import * as LogbookActions from '../../store/modules/logbook/actions';
import TopBoxContainer from '../../components/TopBoxContainer';
import { ApplicationState } from '../../store';
import Table from '../../components/Table';
import Styles from './styles';
import { LogbookState } from '../../store/modules/logbook/types';
import { FishingTripState } from '../../store/modules/fishingTrip/types';
import TablePagination from '../../components/Table/components/tablePagination';
import ErrorComponent from '../../components/ErrorComponent';

interface StateProps {
  fishingTrip: FishingTripState;
  logbook: LogbookState;
}

interface DispatchProps {
  fishingTripsRequest(page: number): void;
  logbookPdfRequest(logbookId: string): void;
}

type Props = StateProps & DispatchProps;

const BoardMap: React.FC<Props> = (props: Props) => {
  const {
    fishingTrip,
    fishingTripsRequest,
    logbookPdfRequest,
    logbook,
  } = props;
  const { t } = useTranslation();
  const classes = Styles();

  const header = [
    t('name'),
    t('skipper'),
    t('port_departure'),
    t('date_departure'),
    t('port_arrival'),
    t('date_arrival'),
  ];

  const [customData, setCustomData] = useState<any>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    fishingTripsRequest(page);
  }, [fishingTripsRequest, page]);

  useEffect(() => {
    const modifiedData = new Array<any>();

    if (!fishingTrip.loading) {
      fishingTrip.data.forEach((trip) => {
        modifiedData.push({
          id: trip.externalId,
          vesselName: trip.vessel.name,
          skipperName: trip.skipper.name,
          portDeparture: trip.portDeparture,
          dateDeparture: format(new Date(trip.dateDeparture), 'MMM/yy', {
            locale: ptBR,
          }),
          portArrival: trip.portArrival,
          dateArrival: format(new Date(trip.dateArrival), 'MMM/yy', {
            locale: ptBR,
          }),
        });
      });

      setCustomData(modifiedData);
    }
  }, [fishingTrip.data, fishingTrip.loading]);

  const printFile = (logbookId: string) => {
    logbookPdfRequest(logbookId);
  };

  return (
    <div className={classes.root}>
      <TitleLine title={t('board_map')} />
      {(fishingTrip.loading || logbook.loading) && <LoaderTailSpin />}
      <TopBoxContainer title={t('fishing_trips')}>
        {(fishingTrip.error || logbook.error) && <ErrorComponent />}
        {!fishingTrip.error && (
          <div>
            <Table
              data={customData}
              header={header}
              hasActions={{ print: true }}
              printFile={printFile}
            />
            <TablePagination
              count={fishingTrip.count}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    fishingTrip: state.fishingTrip,
    logbook: state.logbook,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...FishingTripAction, ...LogbookActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BoardMap);
