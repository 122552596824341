import { action } from 'typesafe-actions';
import { SpeciesContent, YftContentTypes } from './types';

export const yftContentSubmit = (content: SpeciesContent) =>
  action(YftContentTypes.YFT_SUBMIT, content);

export const yftContentRequest = () => action(YftContentTypes.YFT_REQUEST);

export const yftContentLoaded = (content: SpeciesContent) =>
  action(YftContentTypes.YFT_LOADED, content);

export const yftContentFailure = () => action(YftContentTypes.YFT_FAILURE);
