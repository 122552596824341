import React, { useState, useEffect } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type';
import Footer from '../../components/Footer';
import LogoFishSmall from '../../assets/fishSmallIcon.png';
import LogoBoatSmall from '../../assets/boatSmallIcon.png';
import LogoFishHookSmall from '../../assets/fishHookSmallIcon.png';
import Styles from './styles';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import ErrorComponent from '../../components/ErrorComponent';
import Cards from '../../components/Cards';
import { FisheryDataState } from '../../store/modules/dataProvider/homeData/fisheryData/types';
import {
  FisheryProductionDataState,
  HeatmapState,
} from '../../store/modules/dataProvider/types';
import FisheryDataChart from '../../components/Charts/XYChart';
import Heatmap from '../../components/Heatmap';
import ContentBlock from '../../components/ContentBlock';
import { SpeciesContentState } from '../../store/modules/content/types';

interface StateProps {
  heatmap: HeatmapState;
  speciesContent: SpeciesContentState;
  fisherySpeciesData: FisheryDataState;
  fisherySpeciesProduction: FisheryProductionDataState;
}

interface DispatchProps {
  getHeatmap(): void;
  fisheryDataRequest(): void;
  speciesContentRequest(): void;
  fisheryProductionDataRequest(): void;
}

type Props = StateProps & DispatchProps;

const Species: React.FC<Props> = (props: Props) => {
  const classes = Styles();
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    heatmap,
    speciesContent,
    fisherySpeciesData: fisheryData,
    fisherySpeciesProduction: fisheryProduction,
    getHeatmap,
    fisheryDataRequest,
    speciesContentRequest,
    fisheryProductionDataRequest,
  } = props;

  const cards = [
    {
      title: t('total_catch'),
      icon: LogoFishSmall,
      value: `${toNumber(fisheryProduction.data.totalCatch).toLocaleString(
        'pt-BR'
      )} Kg`,
      iconAlt: 'Total catch icon',
    },
    {
      title: t('catching_fish'),
      icon: LogoFishSmall,
      value: `${toNumber(fisheryProduction.data.catchingFish).toLocaleString(
        'pt-BR'
      )} ${t('un')}`,
      iconAlt: 'Catching fish icon',
    },
    {
      title: t('fishing_trips'),
      icon: LogoBoatSmall,
      value: `${toNumber(fisheryProduction.data.fishingTrips).toLocaleString(
        'pt-BR'
      )}`,
      iconAlt: 'Fishing trips icon',
    },
    {
      title: t('fishing_throws'),
      icon: LogoFishHookSmall,
      value: `${toNumber(fisheryProduction.data.fishingThrows).toLocaleString(
        'pt-BR'
      )}`,
      iconAlt: 'Fishing throws icon',
    },
  ];

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHeatmap();
    fisheryDataRequest();
    speciesContentRequest();
    fisheryProductionDataRequest();
  }, [
    getHeatmap,
    fisheryDataRequest,
    speciesContentRequest,
    fisheryProductionDataRequest,
  ]);

  useEffect(() => {
    setError(
      heatmap.error ||
        fisheryData.error ||
        speciesContent.error ||
        fisheryProduction.error
    );

    setLoading(
      heatmap.loading ||
        fisheryData.loading ||
        speciesContent.loading ||
        fisheryProduction.loading
    );

    setLoaded(!error && !loading);
  }, [
    error,
    loading,
    heatmap.error,
    heatmap.loading,
    fisheryData.error,
    fisheryData.loading,
    speciesContent.error,
    speciesContent.loading,
    fisheryProduction.error,
    fisheryProduction.loading,
  ]);

  return (
    <div>
      {loading && <LoaderTailSpin />}
      {error && <ErrorComponent />}
      {loaded && (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <ContentBlock
              title={speciesContent.content.title}
              topText={speciesContent.content.introductoryText}
            >
              <Grid container justify="space-around" spacing={3}>
                <Cards cards={cards} />
              </Grid>
            </ContentBlock>

            <Grid
              container
              direction="column"
              alignItems="center"
              justify="space-between"
            >
              <ContentBlock topText={speciesContent.content.lineChartText}>
                <Grid item className={classes.chartContainer}>
                  <FisheryDataChart data={fisheryData.data} />
                </Grid>
              </ContentBlock>
            </Grid>

            <Grid
              container
              direction="column"
              alignItems="center"
              justify="space-between"
            >
              <ContentBlock topText={speciesContent.content.heatMapText}>
                <Grid item className={classes.chartHeatMap}>
                  {!heatmap.loading && <Heatmap points={heatmap.data} />}
                </Grid>
              </ContentBlock>
            </Grid>
          </Grid>

          <Footer color={theme.palette.background.paper} />
        </>
      )}
    </div>
  );
};

export default Species;
