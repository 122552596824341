import React from 'react';
import { FormControlLabel, Switch, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { VariableFilter } from '../../store/modules/filters/types';

interface Props {
  title: string;
  variables: VariableFilter;
  setVariablesFilter(period: VariableFilter): void;
  errors?: string;
}

const Variables: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { t } = useTranslation();
  const { title, variables, setVariablesFilter, errors } = props;

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      {errors !== '' && <p className={styles.errorMessage}>{errors}</p>}
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="center"
        sm={3}
        md={10}
        xs={12}
        className={styles.root}
      >
        <Grid item>
          <FormControlLabel
            value="catchQuilograms"
            control={
              <Switch
                color="secondary"
                checked={variables.catchQuilograms}
                onChange={(e) =>
                  setVariablesFilter({
                    ...variables,
                    catchQuilograms: e.target.checked,
                  })
                }
              />
            }
            label={t('catches_kg')}
            labelPlacement="end"
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            value="fishQuantity"
            control={
              <Switch
                color="secondary"
                checked={variables.fishQuantity}
                onChange={(e) =>
                  setVariablesFilter({
                    ...variables,
                    fishQuantity: e.target.checked,
                  })
                }
              />
            }
            label={t('catches_units')}
            labelPlacement="end"
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            value="fishingTripQuantity"
            control={
              <Switch
                color="secondary"
                checked={variables.fishingTripQuantity}
                onChange={(e) =>
                  setVariablesFilter({
                    ...variables,
                    fishingTripQuantity: e.target.checked,
                  })
                }
              />
            }
            label={t('fishing_trips')}
            labelPlacement="end"
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            value="setQuantity"
            control={
              <Switch
                color="secondary"
                checked={variables.setQuantity}
                onChange={(e) =>
                  setVariablesFilter({
                    ...variables,
                    setQuantity: e.target.checked,
                  })
                }
              />
            }
            label={t('fishing_throws')}
            labelPlacement="end"
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            value="setVesselsQuantity"
            control={
              <Switch
                color="secondary"
                checked={variables.vesselsQuantity}
                onChange={(e) =>
                  setVariablesFilter({
                    ...variables,
                    vesselsQuantity: e.target.checked,
                  })
                }
              />
            }
            label={t('vessels_quantity')}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Variables;
