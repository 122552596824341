import { call, put } from 'redux-saga/effects';
import { byCatchHeatmapSuccess, byCatchHeatmapFailure } from './actions';
import { getByCatchHeatmap as getByCatchHeatmapApi } from '../../../../../services/api/dataProviderApi';

export function* getByCatchHeatmap() {
  try {
    const response = yield call(getByCatchHeatmapApi);
    if (response.status === 200) {
      yield put(byCatchHeatmapSuccess(response.data.data));
    }
  } catch (err) {
    yield put(byCatchHeatmapFailure());
  }
}
