import { action } from 'typesafe-actions';
import { ByCatchHeatmapTypes, Point } from './types';

export const byCatchHeatmapRequest = () =>
  action(ByCatchHeatmapTypes.BYCATCH_HEATMAP_REQUEST);

export const byCatchHeatmapSuccess = (data: Point[]) =>
  action(ByCatchHeatmapTypes.BYCATCH_HEATMAP_SUCCESS, data);

export const byCatchHeatmapFailure = () =>
  action(ByCatchHeatmapTypes.BYCATCH_HEATMAP_FAILURE);
