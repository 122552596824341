import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Styles from './styles';
import Router from './Router';
import Navbar from '../../components/Navbar';

const PublicMainPage: React.FC = () => {
  const classes = Styles();

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <Navbar />
        <main className={classes.content}>
          <Router />
        </main>
      </div>
    </BrowserRouter>
  );
};

export default PublicMainPage;
