/**
 * actions types
 */
export enum FisheryByCatchDataTypes {
  FISHERY_BYCATCH_DATA_REQUEST = '@chart/FISHERY_BYCATCH_DATA_REQUEST',
  FISHERY_BYCATCH_DATA_SUCCESS = '@chart/FISHERY_BYCATCH_DATA_SUCCESS',
  FISHERY_BYCATCH_DATA_FAILURE = '@chart/FISHERY_BYCATCH_DATA_FAILURE',
}

export type { FisheryData, FisheryDataState } from '../../types';
