import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { deleteAccessToken } from '../localStorageService';
import i18n from '../../i18n';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// apply interceptor on response
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 500) {
      confirmAlert({
        title: i18n.t('error'),
        message: i18n.t('something_wrong'),
        buttons: [
          {
            label: i18n.t('ok'),
            onClick: () => null,
          },
        ],
      });
    } else if (error.response.status === 401 || error.response.status === 403) {
      deleteAccessToken();
    }

    throw error;
  }
);

export default api;
