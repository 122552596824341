import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    dateField: {
      width: 200,
      marginTop: theme.spacing(1.3),
      '& input': { color: 'rgba(0, 0, 0, 0.54)' },
    },
    title: {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    errorMessage: {
      fontSize: '13px',
      color: 'red',
    },
  })
);

export default Styles;
