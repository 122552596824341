import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import * as YftContentActions from '../../store/modules/content/yft/actions';
import * as FisheryYftActions from '../../store/modules/dataProvider/yftData/fisheryData/actions';
import * as FisheryYftProductionActions from '../../store/modules/dataProvider/yftData/fisheryProduction/actions';
import * as yftHeatmapActions from '../../store/modules/dataProvider/yftData/heatmap/actions';
import Species from './index';

const mapStateToProps = (state: ApplicationState) => {
  return {
    speciesContent: state.yftContent,
    fisherySpeciesData: state.fisheryYftData,
    fisherySpeciesProduction: state.fisheryYftProduction,
    heatmap: state.yftHeatmap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      speciesContentRequest: YftContentActions.yftContentRequest,
      fisheryDataRequest: FisheryYftActions.fisheryYftDataRequest,
      fisheryProductionDataRequest:
        FisheryYftProductionActions.fisheryYftProductionDataRequest,
      getHeatmap: yftHeatmapActions.yftHeatmapRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Species);
