import { action } from 'typesafe-actions';
import {
  ByCatchSpeciesDistributionTypes,
  SpeciesDistributionData,
} from './types';

export const byCatchSpeciesDistributionRequest = () =>
  action(ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_REQUEST);

export const byCatchSpeciesDistributionSuccess = (
  data: SpeciesDistributionData[]
) =>
  action(
    ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_SUCCESS,
    data
  );

export const byCatchSpeciesDistributionFailure = () =>
  action(ByCatchSpeciesDistributionTypes.BYCATCH_SPECIES_DISTRIBUTION_FAILURE);
