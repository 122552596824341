import { Reducer } from 'redux';
import { ErrorState, ErrorTypes } from './types';

const INITIAL_STATE: ErrorState = {
  user: {
    notFound: '',
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    ownedVessels: '',
    role: '',
  },
  filter: {
    notFound: '',
    name: '',
    period: '',
    groupers: '',
    variables: '',
  },
  password: {
    passwordRecovery: {
      email: '',
    },
    passwordChange: {
      keypass: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  },
  publicGeneratorContent: {
    title: '',
    text: '',
  },
  homeContent: {
    title: '',
    introductoryText: '',
    lineChartText: '',
    mainSpeciesChartTitle: '',
    mainSpeciesChartText: '',
    heatMapTitle: '',
    heatMapText: '',
  },
  aboutContent: {
    title: '',
    text: '',
  },
  byCatchContent: {
    title: '',
    introductoryText: '',
    lineChartText: '',
    mainSpeciesChartText: '',
    heatMapText: '',
  },
  speciesContent: {
    title: '',
    introductoryText: '',
    lineChartText: '',
    heatMapText: '',
  },
};

const reducer: Reducer<ErrorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorTypes.USER_VIOLATION:
      return { ...state, user: action.payload };
    case ErrorTypes.CLEAR_USER_VIOLATION:
      return { ...state, user: INITIAL_STATE.user };
    case ErrorTypes.FILTER_VIOLATION:
      return { ...state, filter: action.payload };
    case ErrorTypes.CLEAR_FILTER_VIOLATION:
      return { ...state, filter: INITIAL_STATE.filter };
    case ErrorTypes.PASSWORD_VIOLATION:
      return { ...state, password: action.payload };
    case ErrorTypes.CLEAR_PASSWORD_VIOLATION:
      return { ...state, password: INITIAL_STATE.password };
    case ErrorTypes.PUBLIC_GENERATOR_CONTENT_VIOLATION:
      return { ...state, publicGeneratorContent: action.payload };
    case ErrorTypes.CLEAR_PUBLIC_GENERATOR_CONTENT_VIOLATION:
      return {
        ...state,
        publicGeneratorContent: INITIAL_STATE.publicGeneratorContent,
      };
    case ErrorTypes.HOME_CONTENT_VIOLATION:
      return { ...state, homeContent: action.payload };
    case ErrorTypes.CLEAR_HOME_CONTENT_VIOLATION:
      return {
        ...state,
        homeContent: INITIAL_STATE.homeContent,
      };
    case ErrorTypes.ABOUT_CONTENT_VIOLATION:
      return { ...state, aboutContent: action.payload };
    case ErrorTypes.CLEAR_ABOUT_CONTENT_VIOLATION:
      return {
        ...state,
        aboutContent: INITIAL_STATE.aboutContent,
      };
    case ErrorTypes.BYCATCH_CONTENT_VIOLATION:
      return { ...state, byCatchContent: action.payload };
    case ErrorTypes.CLEAR_BYCATCH_CONTENT_VIOLATION:
      return {
        ...state,
        byCatchContent: INITIAL_STATE.byCatchContent,
      };
    case ErrorTypes.SPECIES_CONTENT_VIOLATION:
      return { ...state, speciesContent: action.payload };
    case ErrorTypes.CLEAR_SPECIES_CONTENT_VIOLATION:
      return {
        ...state,
        speciesContent: INITIAL_STATE.speciesContent,
      };

    default:
      return state;
  }
};

export default reducer;
