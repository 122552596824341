/**
 * actions types
 */
export enum PasswordTypes {
  PASSWORD_REQUEST = '@password/PASSWORD_REQUEST',
  PASSWORD_SUCCESS = '@password/PASSWORD_SUCCESS',
  PASSWORD_FAILURE = '@password/PASSWORD_FAILURE',
  CHANGE_PASSWORD_REQUEST = '@password/CHANGE_PASSWORD_REQUEST',
  RESET_PASSWORD_REQUEST = '@password/RESET_PASSWORD_REQUEST',
  CLEAR_PASSWORD_STATE = '@password/CLEAR_PASSWORD_STATE',
}

/**
 * data types
 */
export interface Password {
  keypass: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

/**
 * state type
 */
export interface PasswordState {
  readonly success: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
