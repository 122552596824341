/**
 * actions types
 */
export enum FisheryYftProductionDataTypes {
  FISHERY_YFT_PRODUCTION_REQUEST = '@chart/FISHERY_YFT_PRODUCTION_REQUEST',
  FISHERY_YFT_PRODUCTION_SUCCESS = '@chart/FISHERY_YFT_PRODUCTION_SUCCESS',
  FISHERY_YFT_PRODUCTION_FAILURE = '@chart/FISHERY_YFT_PRODUCTION_FAILURE',
}

export type {
  FisheryProductionData,
  FisheryProductionDataState,
} from '../../types';
