import { call, put } from 'redux-saga/effects';
import { vesselSuccess, vesselFailure } from './actions';
import { getOwnVessels as getOwnVesselsApi } from '../../../services/api/vesselsApi';

export function* getVessels() {
  try {
    const response = yield call(getOwnVesselsApi);
    if (response.status === 200) {
      yield put(vesselSuccess(response.data.items));
    }
  } catch (err) {
    yield put(vesselFailure());
  }
}
