import { call, put } from 'redux-saga/effects';
import {
  PublicDataGeneratorContentTypes,
  PublicDataGeneratorContent,
} from './types';
import {
  getPublicDataGeneratorContent as getPublicDataGeneratorContentApi,
  setPublicDataGeneratorContent as setPublicDataGeneratorContentApi,
} from '../../../../services/api/contentApi';

import {
  publicDataGeneratorContentLoaded,
  publicDataGeneratorContentFailure,
} from './actions';

import { publicGeneratorContentViolationParser } from '../../../../utils/errorParser';
import { publicGeneratorContentViolation } from '../../errors/action';

export function* setPublicDataGeneratorContent({
  payload,
}: {
  type: typeof PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_SUBMIT;
  payload: PublicDataGeneratorContent;
}) {
  try {
    const response = yield call(setPublicDataGeneratorContentApi, payload);

    if (response.status === 200) {
      yield put(publicDataGeneratorContentLoaded(response.data));
    } else {
      yield put(publicDataGeneratorContentFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(publicDataGeneratorContentFailure());
      yield put(
        publicGeneratorContentViolation(
          publicGeneratorContentViolationParser(err.response.data)
        )
      );
    }

    yield put(publicDataGeneratorContentFailure());
  }
}

export function* getPublicDataGeneratorContent() {
  try {
    const response = yield call(getPublicDataGeneratorContentApi);
    if (response.status === 200) {
      yield put(publicDataGeneratorContentLoaded(response.data));
    } else {
      yield put(publicDataGeneratorContentFailure());
    }
  } catch (err) {
    yield put(publicDataGeneratorContentFailure());
  }
}
