import { Point, HeatmapState } from '../../types';

/**
 * actions types
 */
export enum ByCatchHeatmapTypes {
  BYCATCH_HEATMAP_REQUEST = '@byCatch/BYCATCH_HEATMAP_REQUEST',
  BYCATCH_HEATMAP_SUCCESS = '@byCatch/BYCATCH_HEATMAP_SUCCESS',
  BYCATCH_HEATMAP_FAILURE = '@byCatch/BYCATCH_HEATMAP_FAILURE',
}

/**
 * data types
 */

export type { HeatmapState, Point };
