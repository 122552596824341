import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import * as BetContentActions from '../../store/modules/content/bet/actions';
import * as FisheryBetActions from '../../store/modules/dataProvider/betData/fisheryData/actions';
import * as FisheryBetProductionActions from '../../store/modules/dataProvider/betData/fisheryProduction/actions';
import * as betHeatmapActions from '../../store/modules/dataProvider/betData/heatmap/actions';
import Species from './index';

const mapStateToProps = (state: ApplicationState) => {
  return {
    speciesContent: state.betContent,
    fisherySpeciesData: state.fisheryBetData,
    fisherySpeciesProduction: state.fisheryBetProduction,
    heatmap: state.betHeatmap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getHeatmap: betHeatmapActions.betHeatmapRequest,
      speciesContentRequest: BetContentActions.betContentRequest,
      fisheryDataRequest: FisheryBetActions.fisheryBetDataRequest,
      fisheryProductionDataRequest:
        FisheryBetProductionActions.fisheryBetProductionDataRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Species);
