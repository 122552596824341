import { ResponseType } from 'axios';
import api from './api';
import { getAccessToken } from '../localStorageService';

export const getPdf = (id: string) => {
  const config = {
    responseType: 'blob' as ResponseType,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return api.get(`/logbook/pdf/${id}`, config);
};
