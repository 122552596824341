import React from 'react';
import Loader from 'react-loader-spinner';
import { Backdrop } from '@material-ui/core';
import Styles from './styles';

const LoaderTailSpin: React.FC = () => {
  const styles = Styles();

  return (
    <Backdrop className={styles.backdrop} open>
      <Loader type="TailSpin" color="#8d8d8d" />
    </Backdrop>
  );
};
export default LoaderTailSpin;
