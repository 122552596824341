import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
  tableContainer: {
    height: '100%',
  },
  table: {
    minWidth: 700,
    height: '100%',
  },
  cell: {
    fontSize: '1.6rem',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0px 16px',
    padding: '0',
  },
  iconBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boldLabel: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
  },
});

export default Styles;
