import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import Styles from './styles';

interface Props {
  cards: {
    title: string;
    icon: string;
    value: string;
    iconAlt: string;
  }[];
}

const Cards: React.FC<Props> = (props: Props) => {
  const { cards } = props;
  const classes = Styles();

  return (
    <>
      {cards.map((card) => (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          key={card.title}
          item
          lg={3}
          md={6}
          sm={8}
          xs={12}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="space-between"
                  className={classes.cardsGrid}
                >
                  <Typography
                    variant="body1"
                    component="h3"
                    className={classes.cardTitle}
                  >
                    {card.title}
                  </Typography>
                  <img
                    src={card.icon}
                    alt={card.iconAlt}
                    className={classes.cardTitleIcon}
                  />
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Typography
                    variant="body1"
                    component="span"
                    className={classes.cardValue}
                  >
                    {card.value}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default Cards;
