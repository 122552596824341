import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Table from '../../components/Table';
import { Styles } from './styles';
import { ApplicationState } from '../../store';
import { UsersState } from '../../store/modules/users/types';
import * as UsersAction from '../../store/modules/users/actions';
import TopBoxContainer from '../../components/TopBoxContainer';
import Button from '../../components/Button';
import TitleLine from '../../components/TitleLine';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import ConfirmationBox from '../../components/ConfirmationBox';

interface StateProps {
  users: UsersState;
}

interface DispatchProps {
  listUsersRequest(page: number): void;
  deleteUserRequest(id: string): void;
  clearUserState(): void;
}

type Props = StateProps & DispatchProps;

const Users: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { users, listUsersRequest, deleteUserRequest, clearUserState } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const header = [t('name'), t('email'), t('profile')];

  const [customData, setCustomData] = useState<any>([]);

  useEffect(() => {
    const modifiedData: any[] = [];

    users.data.forEach((user) => {
      modifiedData.push({
        id: user.id,
        name: user.name,
        email: user.email,
        profile: t(user.role),
      });
    });

    setCustomData(modifiedData);
  }, [users.data, t]);

  const deleteItemWithConfirmation = (id: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            title={t('confirmation')}
            message={t('sure_to_delete_user', {
              user: users.data.find((e) => e.id === id)?.name,
            })}
            okLabel={t('delete')}
            okAction={() => {
              deleteUserRequest(id);
              onClose();
            }}
            cancelLabel={t('cancel')}
            cancelAction={() => onClose()}
          />
        );
      },
    });
  };

  const updateItem = (id: string) => {
    history.push(`/mainpage/users/update-user/${id}`);
  };

  useEffect(() => {
    listUsersRequest(0);
  }, [listUsersRequest]);

  useEffect(() => {
    if (!users.listUsersLoading) {
      clearUserState();
    }
  }, [clearUserState, users.listUsersLoading]);

  return (
    <div className={styles.root}>
      <TitleLine title={t('users')} />
      <Button onClick={() => history.push('/mainpage/users/new-user')}>
        {t('new_user')}
      </Button>
      {users.listUsersLoading && <LoaderTailSpin />}
      <TopBoxContainer title={t('list_users')}>
        <div>
          <Table
            data={customData}
            header={header}
            hasActions={{
              edit: true,
              delete: true,
            }}
            deleteItem={deleteItemWithConfirmation}
            updateItem={updateItem}
          />
        </div>
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    users: state.users,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UsersAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Users);
