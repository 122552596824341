import { call, put } from 'redux-saga/effects';
import { betHeatmapSuccess, betHeatmapFailure } from './actions';
import { getBetHeatmap as getBetHeatmapApi } from '../../../../../services/api/dataProviderApi';

export function* getBetHeatmap() {
  try {
    const response = yield call(getBetHeatmapApi);
    if (response.status === 200) {
      yield put(betHeatmapSuccess(response.data.data));
    }
  } catch (err) {
    yield put(betHeatmapFailure());
  }
}
