import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CountData } from '../../../../../store/modules/report/types';
import Styles from './styles';

interface TablePropsItens {
  Header: string[];
  Rows: Array<any>;
  countData: CountData;
}

const variables = [
  'catchQuilograms',
  'fishingTripQuantity',
  'fishQuantity',
  'setQuantity',
  'vesselsQuantity',
];

const TableGenerator: React.FC<TablePropsItens> = (props: TablePropsItens) => {
  const styles = Styles();
  const { t } = useTranslation();
  const { Header, Rows, countData } = props;

  return (
    <div className={styles.root}>
      <TableContainer>
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow key={-1}>
              {Header.map((item) => (
                <TableCell
                  key={item}
                  align="center"
                  className={styles.cellBold}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.map((row, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`keyRow${i}`}>
                {Object.keys(row).map((key, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableCell
                    key={`keyCell${row[key]}${j + i}`}
                    align="center"
                    className={styles.cell}
                  >
                    {variables.includes(key)
                      ? parseFloat(row[key]).toLocaleString('pt-BR')
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {Rows[0] && (
              <TableRow key="totalRow">
                {Object.keys(Rows[0]).map((key, index) => (
                  <TableCell
                    key={key}
                    align="center"
                    className={styles.TotalCells}
                  >
                    {index === 0 && t('total')}
                    {index !== 0 &&
                      Object(countData)[key] &&
                      parseFloat(Object(countData)[key]).toLocaleString(
                        'pt-BR'
                      )}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableGenerator;
