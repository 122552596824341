import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type';
import { ApplicationState } from '../../store';
import * as FisheryActions from '../../store/modules/dataProvider/byCatch/fisheryData/actions';
import * as FisheryProductionActions from '../../store/modules/dataProvider/byCatch/fisheryProduction/actions';
import * as ByCatchSpeciesDistributionActions from '../../store/modules/dataProvider/byCatch/speciesDistribution/actions';
import * as HeatmapActions from '../../store/modules/dataProvider/byCatch/heatmap/actions';
import * as ByCatchContentActions from '../../store/modules/content/byCatch/actions';
import Styles from './styles';
import Cards from '../../components/Cards';
import LogoFishSmall from '../../assets/fishSmallIcon.png';
import LogoBoatSmall from '../../assets/boatSmallIcon.png';
import LogoFishHookSmall from '../../assets/fishHookSmallIcon.png';
import TreeMapDataChart from '../../components/Charts/TreeMap';
import FisheryDataChart from '../../components/Charts/XYChart';
import Footer from '../../components/Footer';
import { FisheryDataState } from '../../store/modules/dataProvider/byCatch/fisheryData/types';
import { FisheryProductionDataState } from '../../store/modules/dataProvider/byCatch/fisheryProduction/types';
import { SpeciesDistributionState } from '../../store/modules/dataProvider/types';
import { HeatmapState } from '../../store/modules/dataProvider/byCatch/heatmap/types';
import { ByCatchContentState } from '../../store/modules/content/byCatch/types';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import ErrorComponent from '../../components/ErrorComponent';
import Heatmap from '../../components/Heatmap';
import ContentBlock from '../../components/ContentBlock';

interface StateProps {
  byCatchHeatmap: HeatmapState;
  fisheryData: FisheryDataState;
  byCatchContent: ByCatchContentState;
  fisheryProduction: FisheryProductionDataState;
  byCatchSpeciesDistribution: SpeciesDistributionState;
}

interface DispatchProps {
  byCatchContentRequest(): void;
  byCatchHeatmapRequest(): void;
  fisheryByCatchDataRequest(): void;
  byCatchSpeciesDistributionRequest(): void;
  fisheryByCatchProductionDataRequest(): void;
}

type Props = StateProps & DispatchProps;

const ByCatch: React.FC<Props> = (props: Props) => {
  const {
    fisheryData,
    byCatchContent,
    byCatchHeatmap,
    fisheryProduction,
    byCatchSpeciesDistribution,
    byCatchContentRequest,
    byCatchHeatmapRequest,
    fisheryByCatchDataRequest,
    byCatchSpeciesDistributionRequest,
    fisheryByCatchProductionDataRequest,
  } = props;

  const classes = Styles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    byCatchHeatmapRequest();
    byCatchContentRequest();
    fisheryByCatchDataRequest();
    byCatchSpeciesDistributionRequest();
    fisheryByCatchProductionDataRequest();
  }, [
    fisheryByCatchDataRequest,
    fisheryByCatchProductionDataRequest,
    byCatchSpeciesDistributionRequest,
    byCatchHeatmapRequest,
    byCatchContentRequest,
  ]);

  useEffect(() => {
    setLoading(
      fisheryData.loading ||
        fisheryProduction.loading ||
        byCatchSpeciesDistribution.loading
    );

    setLoaded(
      !(
        fisheryData.loading ||
        fisheryProduction.loading ||
        byCatchSpeciesDistribution.loading
      ) &&
        !(
          fisheryData.error ||
          fisheryProduction.error ||
          byCatchSpeciesDistribution.error ||
          byCatchHeatmap.error
        )
    );

    setError(
      fisheryData.error ||
        fisheryProduction.error ||
        byCatchSpeciesDistribution.error ||
        byCatchHeatmap.error
    );
  }, [
    fisheryData,
    fisheryProduction,
    byCatchSpeciesDistribution,
    byCatchHeatmap.error,
  ]);

  const cards = [
    {
      title: t('total_catch'),
      icon: LogoFishSmall,
      value: `${toNumber(fisheryProduction.data.totalCatch).toLocaleString(
        'pt-BR'
      )} Kg`,
      iconAlt: 'Total catch icon',
    },
    {
      title: t('catching_fish'),
      icon: LogoFishSmall,
      value: `${toNumber(fisheryProduction.data.catchingFish).toLocaleString(
        'pt-BR'
      )} ${t('un')}`,
      iconAlt: 'Catching fish icon',
    },
    {
      title: t('fishing_trips'),
      icon: LogoBoatSmall,
      value: `${toNumber(fisheryProduction.data.fishingTrips).toLocaleString(
        'pt-BR'
      )}`,
      iconAlt: 'Fishing trips icon',
    },
    {
      title: t('fishing_throws'),
      icon: LogoFishHookSmall,
      value: `${toNumber(fisheryProduction.data.fishingThrows).toLocaleString(
        'pt-BR'
      )}`,
      iconAlt: 'Fishing throws icon',
    },
  ];

  return (
    <div>
      {loading && <LoaderTailSpin />}
      {error && <ErrorComponent />}
      {loaded && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="space-between"
        >
          <ContentBlock
            title={byCatchContent.content.title}
            topText={byCatchContent.content.introductoryText}
          >
            <Grid container justify="space-around" spacing={3}>
              <Cards cards={cards} />
            </Grid>
          </ContentBlock>

          <ContentBlock topText={byCatchContent.content.mainSpeciesChartText}>
            <Grid item className={classes.chartTreeContainer}>
              <TreeMapDataChart data={byCatchSpeciesDistribution.data} />
            </Grid>
          </ContentBlock>

          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <ContentBlock topText={byCatchContent.content.lineChartText}>
              <Grid item className={classes.chartContainer}>
                <FisheryDataChart data={fisheryData.data} />
              </Grid>
            </ContentBlock>
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <ContentBlock topText={byCatchContent.content.heatMapText}>
              <Grid item className={classes.chartHeatMap}>
                {!byCatchHeatmap.loading && (
                  <Heatmap points={byCatchHeatmap.data} />
                )}
              </Grid>
            </ContentBlock>
          </Grid>
        </Grid>
      )}
      <Footer color={theme.palette.background.paper} />
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    byCatchHeatmap: state.byCatchHeatmap,
    byCatchContent: state.byCatchContent,
    fisheryData: state.fisheryByCatchData,
    fisheryProduction: state.fisheryByCatchProduction,
    byCatchSpeciesDistribution: state.byCatchSpeciesDistribution,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...FisheryActions,
      ...HeatmapActions,
      ...ByCatchContentActions,
      ...FisheryProductionActions,
      ...ByCatchSpeciesDistributionActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ByCatch);
