import { call, put } from 'redux-saga/effects';
import {
  fisherySwoProductionDataSuccess,
  fisherySwoProductionDataFailure,
} from './actions';
import { getFisherySwoProductionData } from '../../../../../services/api/dataProviderApi';

export function* loadFisherySwoProductionData() {
  try {
    const response = yield call(getFisherySwoProductionData);
    if (response.status === 200) {
      yield put(fisherySwoProductionDataSuccess(response.data));
    } else if (response.status === 401) {
      yield put(fisherySwoProductionDataFailure());
    } else {
      yield put(fisherySwoProductionDataFailure());
    }
  } catch (err) {
    yield put(fisherySwoProductionDataFailure());
  }
}
