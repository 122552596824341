import { action } from 'typesafe-actions';
import { MainSpeciesTypes, SpeciesDistributionData } from './types';

export const mainSpeciesRequest = () =>
  action(MainSpeciesTypes.MAIN_SPECIES_REQUEST);

export const mainSpeciesSuccess = (data: SpeciesDistributionData[]) =>
  action(MainSpeciesTypes.MAIN_SPECIES_SUCCESS, data);

export const mainSpeciesFailure = () =>
  action(MainSpeciesTypes.MAIN_SPECIES_FAILURE);
