import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  chartHeatMap: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  chartContainer: {
    width: '100%',
    height: '60rem',
  },
  card: {
    width: '85%',
    height: '10rem',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.2) !important',
  },
}));

export default Styles;
