import { action } from 'typesafe-actions';
import { BetHeatmapTypes, Point } from './types';

export const betHeatmapRequest = () =>
  action(BetHeatmapTypes.BET_HEATMAP_REQUEST);

export const betHeatmapSuccess = (data: Point[]) =>
  action(BetHeatmapTypes.BET_HEATMAP_SUCCESS, data);

export const betHeatmapFailure = () =>
  action(BetHeatmapTypes.BET_HEATMAP_FAILURE);
