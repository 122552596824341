import { createStyles, makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(() =>
  createStyles({
    contentBlock: {
      width: '80%',
      marginTop: '2.5rem',
    },
    contentItem: {
      width: '100%',
      textAlign: 'justify',
    },
    titleBox: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '1.5rem',
    },
  })
);

export default Styles;
