import { call, put } from 'redux-saga/effects';
import { speciesSuccess, speciesFailure } from './actions';
import { getSpecies } from '../../../services/api/speciesApi';

export function* requestSpecies() {
  try {
    const response = yield call(getSpecies);
    if (response.status === 200) {
      yield put(speciesSuccess(response.data.items));
    } else {
      yield put(speciesFailure());
    }
  } catch (err) {
    yield put(speciesFailure());
  }
}
