import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import Styles from './styles';

const ErrorComponent: React.FC = () => {
  const styles = Styles();
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div>
        <ErrorIcon className={styles.icon} />
      </div>
      <div className={styles.message}>
        <p> {t('something_wrong')} </p>
      </div>
    </div>
  );
};

export default ErrorComponent;
