import { action } from 'typesafe-actions';
import { FisherySwoDataTypes, FisheryData } from './types';

export const fisherySwoDataRequest = () =>
  action(FisherySwoDataTypes.FISHERY_SWO_DATA_REQUEST);

export const fisherySwoDataSuccess = (data: FisheryData[]) =>
  action(FisherySwoDataTypes.FISHERY_SWO_DATA_SUCCESS, data);

export const fisherySwoDataFailure = () =>
  action(FisherySwoDataTypes.FISHERY_SWO_DATA_FAILURE);
