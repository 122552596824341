/**
 * actions types
 */
export enum ByCatchContentTypes {
  BYCATCH_CONTENT_SUBMIT = '@byCatchContent/BYCATCH_CONTENT_SUBMIT',
  BYCATCH_CONTENT_REQUEST = '@byCatchContent/BYCATCH_CONTENT_REQUEST',
  BYCATCH_CONTENT_LOADED = '@byCatchContent/BYCATCH_CONTENT_SUCCESS',
  BYCATCH_CONTENT_FAILURE = '@byCatchContent/BYCATCH_CONTENT_FAILURE',
}

/**
 * data types
 */
export interface ByCatchContent {
  title: string;
  introductoryText: string;
  mainSpeciesChartText: string;
  lineChartText: string;
  heatMapText: string;
}

/**
 * state type
 */
export interface ByCatchContentState {
  readonly content: ByCatchContent;
  readonly loaded: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
