import React from 'react';
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Grid,
  Chip,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import Styles from './styles';
import { GrouperFilter } from '../../store/modules/filters/types';
import { Species } from '../../store/modules/species/types';

interface Props {
  title: string;
  options: Species[];
  groupers: GrouperFilter;
  setGroupersFilter(groupers: GrouperFilter): void;
  errors?: string;
}

const Groupers: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { t } = useTranslation();

  const { title, options, groupers, setGroupersFilter, errors } = props;
  const counties = [
    'Belém',
    'Fernando de Noronha',
    'Fortaleza',
    'Natal',
    'Recife',
  ];

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      {errors !== '' && <p className={styles.errorMessage}>{errors}</p>}
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="center"
        sm={6}
        md={12}
        className={styles.root}
      >
        <Grid item sm={3} md={6} className={styles.slideDiv}>
          <FormControlLabel
            value="year"
            control={
              <Switch
                color="secondary"
                checked={groupers.year}
                onChange={(e) =>
                  setGroupersFilter({ ...groupers, year: e.target.checked })
                }
              />
            }
            label={t('year')}
            labelPlacement="end"
          />

          <FormControlLabel
            value="booleanPortArrival"
            control={
              <Switch
                color="secondary"
                checked={groupers.portArrival}
                onChange={(e) =>
                  setGroupersFilter({
                    ...groupers,
                    portArrival: e.target.checked,
                  })
                }
              />
            }
            label={t('counties')}
            labelPlacement="end"
          />

          <FormControlLabel
            value="month"
            control={
              <Switch
                color="secondary"
                checked={groupers.month}
                onChange={(e) =>
                  setGroupersFilter({ ...groupers, month: e.target.checked })
                }
              />
            }
            label={t('month')}
            labelPlacement="end"
          />

          <FormControlLabel
            value="booleanSpecies"
            control={
              <Switch
                color="secondary"
                checked={groupers.species}
                onChange={(e) =>
                  setGroupersFilter({ ...groupers, species: e.target.checked })
                }
              />
            }
            label={t('species_plural')}
            labelPlacement="end"
          />
        </Grid>
        <Grid item container sm={3} md={5}>
          {groupers.portArrival && (
            <FormControl className={styles.formControl}>
              <InputLabel id="input-portArrival">{t('counties')}</InputLabel>
              <Select
                labelId="portArrival-select-label"
                id="portArrival-select-id"
                multiple
                value={groupers.portArrivalOptions}
                onChange={(e) =>
                  setGroupersFilter({
                    ...groupers,
                    portArrivalOptions: e.target.value as string[],
                  })
                }
                renderValue={() => (
                  <div className={styles.chips}>
                    {groupers.portArrivalOptions.map((value) => (
                      <Chip key={value} label={value} className={styles.chip} />
                    ))}
                  </div>
                )}
              >
                {counties.map((county) => (
                  <MenuItem value={county} key={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {groupers.species && (
            <>
              {!groupers.portArrival && (
                <FormControl className={styles.formControl}>
                  <InputLabel />
                  <Select style={{ visibility: 'hidden' }} />
                </FormControl>
              )}
              <FormControl className={styles.formControl}>
                <InputLabel id="input-specie">{t('species_plural')}</InputLabel>
                <Select
                  labelId="specie-select-label"
                  id="specie-select-id"
                  multiple
                  value={groupers.speciesOptions}
                  onChange={(e) =>
                    setGroupersFilter({
                      ...groupers,
                      speciesOptions: e.target.value as string[],
                    })
                  }
                  renderValue={() => (
                    <div className={styles.chips}>
                      {groupers.speciesOptions.map((value) => (
                        <Chip
                          key={value}
                          label={
                            options.find((e) => e.externalId === value)
                              ?.portugueseName
                          }
                          className={styles.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {options.map((row) => (
                    <MenuItem key={row.externalId} value={row.externalId}>
                      {row.portugueseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Groupers;
