import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Button, Container, TextField } from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory } from 'react-router-dom';
import logoImg from '../../../assets/logoOpenTuna.png';
import { ApplicationState } from '../../../store';
import * as PasswordAction from '../../../store/modules/password/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { Password, PasswordState } from '../../../store/modules/password/types';
import { ErrorState } from '../../../store/modules/errors/types';
import Styles from './styles';
import ConfirmationBox from '../../../components/ConfirmationBox';

interface StateProps {
  password: PasswordState;
  errors: ErrorState;
}

interface DispatchProps {
  passwordResetRequest(paramsPassword: Password): void;
  clearPasswordState(): void;
  clearPasswordViolation(): void;
}

type Props = StateProps & DispatchProps;

const ResetPassword: React.FC<Props> = (props: Props) => {
  const {
    password,
    errors,
    passwordResetRequest,
    clearPasswordState,
    clearPasswordViolation,
  } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const token = `${urlParams.get('token')}`;

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const styles = Styles();
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const handleSubmit = async () => {
    await passwordResetRequest({
      keypass: token,
      newPassword,
      newPasswordConfirmation,
    });
  };

  useEffect(() => {
    clearPasswordState();
    clearPasswordViolation();
  }, [clearPasswordViolation, clearPasswordState]);

  const sendAlert = useCallback(
    (message: string, redirect: boolean) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmationBox
              title={message}
              okLabel={t('ok')}
              okAction={() => {
                if (redirect) {
                  history.push('/login');
                }
                onClose();
              }}
            />
          );
        },
        onClickOutside: () => {
          if (redirect) {
            history.push('/login');
          }
        },
      });
    },
    [history, t]
  );

  useEffect(() => {
    if (password.success) {
      sendAlert(t('password_change_success'), true);
    } else if (errors.password.passwordChange.keypass) {
      sendAlert(errors.password.passwordChange.keypass, false);
    }

    clearPasswordState();
    clearPasswordViolation();
  }, [
    sendAlert,
    password.success,
    errors.password.passwordChange.keypass,
    clearPasswordState,
    clearPasswordViolation,
    t,
  ]);

  return (
    <div className={styles.root}>
      <Container className={styles.content}>
        <img className={styles.img} src={logoImg} alt="OpenTuna" />

        <Form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
          <h1 className={styles.h1}>{t('reset_password')}</h1>

          <div className={styles.inputDiv}>
            <TextField
              autoFocus
              fullWidth
              name="new-password"
              label={t('password')}
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              required
              error={errors.password.passwordChange.newPassword !== ''}
              helperText={errors.password.passwordChange.newPassword}
            />
          </div>

          <div className={styles.inputDiv}>
            <TextField
              fullWidth
              name="confirm-password"
              label={t('confirm_password')}
              type="password"
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              required
              error={
                errors.password.passwordChange.newPasswordConfirmation !== ''
              }
              helperText={
                errors.password.passwordChange.newPasswordConfirmation
              }
            />
          </div>

          <div className={styles.bottomDiv}>
            <Link className={styles.a} to="/login">
              {t('back')}
            </Link>

            <Button className={styles.button} type="submit">
              {t('save')}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    password: state.password,
    errors: state.errors,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PasswordAction, ...ErrorsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
