import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import * as PublicDataGeneratorContentAction from '../../../store/modules/content/publicDataGenerator/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { ApplicationState } from '../../../store';
import {
  PublicDataGeneratorContentState,
  PublicDataGeneratorContent,
} from '../../../store/modules/content/publicDataGenerator/types';
import { ErrorState } from '../../../store/modules/errors/types';
import TextEditor from '../../../components/TextEditor';
import BaseForm from '../../../components/BaseForm';

interface StateProps {
  errors: ErrorState;
  publicDataGeneratorContent: PublicDataGeneratorContentState;
}

interface DispatchProps {
  publicDataGeneratorContentRequest(): void;
  clearPublicGeneratorContentViolation(): void;
  publicDataGeneratorContentSubmit(data: PublicDataGeneratorContent): void;
}

type Props = StateProps & DispatchProps;

const PublicGenetatorContentManagement: React.FC<Props> = (props: Props) => {
  const {
    errors,
    publicDataGeneratorContent,
    publicDataGeneratorContentSubmit,
    publicDataGeneratorContentRequest,
    clearPublicGeneratorContentViolation,
  } = props;

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    publicDataGeneratorContentRequest();
    clearPublicGeneratorContentViolation();
    setTitle(publicDataGeneratorContent.content.title);
    setText(publicDataGeneratorContent.content.text);
  }, [
    publicDataGeneratorContent.content.title,
    publicDataGeneratorContent.content.text,
    clearPublicGeneratorContentViolation,
    publicDataGeneratorContentRequest,
  ]);

  // TODO: Codedebt: Handle with empty texts #241
  // submit
  const onSubmit = useCallback(async () => {
    const data: PublicDataGeneratorContent = {
      title,
      text,
    };

    publicDataGeneratorContentSubmit(data);
    clearPublicGeneratorContentViolation();
  }, [
    text,
    title,
    publicDataGeneratorContentSubmit,
    clearPublicGeneratorContentViolation,
  ]);

  useEffect(() => {
    setLoading(
      publicDataGeneratorContent.loading && !publicDataGeneratorContent.error
    );
  }, [publicDataGeneratorContent.error, publicDataGeneratorContent.loading]);

  return (
    <BaseForm
      loading={loading}
      pageTitle={t('content_manager')}
      formTitle={t('database')}
      buttonLabel={t('save')}
      onSubmit={onSubmit}
    >
      <TextField
        label={t('title_label')}
        value={title}
        fullWidth
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        required
        error={errors.publicGeneratorContent.title !== ''}
        helperText={errors.publicGeneratorContent.title}
      />

      <TextEditor
        required
        label={t('text_label')}
        textValue={text}
        setTextValue={setText}
        error={errors.publicGeneratorContent.text}
      />
    </BaseForm>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    publicDataGeneratorContent: state.publicDataGeneratorContent,
    errors: state.errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...PublicDataGeneratorContentAction,
      ...ErrorsAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicGenetatorContentManagement);
