import { makeStyles, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    error: {
      color: 'red',
    },
    contentContainer: {
      paddingBottom: '50px',
    },
  })
);

export default Styles;
