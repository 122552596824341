import { action } from 'typesafe-actions';
import { AuthTypes, Credentials } from './types';

export const signResquest = (user: Credentials) =>
  action(AuthTypes.SIGN_REQUEST, user);
export const signSuccess = () => action(AuthTypes.SIGN_SUCCESS);
export const signFailure = () => action(AuthTypes.SIGN_FAILURE);

export const logoutResquest = () => action(AuthTypes.LOGOUT_REQUEST);
export const logoutSuccess = () => action(AuthTypes.LOGOUT_SUCCESS);

export const clearAuthState = () => action(AuthTypes.CLEAR_AUTH_STATE);
