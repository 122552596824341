import { action } from 'typesafe-actions';
import { SpeciesContent, BetContentTypes } from './types';

export const betContentSubmit = (content: SpeciesContent) =>
  action(BetContentTypes.BET_SUBMIT, content);

export const betContentRequest = () => action(BetContentTypes.BET_REQUEST);

export const betContentLoaded = (content: SpeciesContent) =>
  action(BetContentTypes.BET_LOADED, content);

export const betContentFailure = () => action(BetContentTypes.BET_FAILURE);
