import { action } from 'typesafe-actions';
import { YftHeatmapTypes, Point } from './types';

export const yftHeatmapRequest = () =>
  action(YftHeatmapTypes.YFT_HEATMAP_REQUEST);

export const yftHeatmapSuccess = (data: Point[]) =>
  action(YftHeatmapTypes.YFT_HEATMAP_SUCCESS, data);

export const yftHeatmapFailure = () =>
  action(YftHeatmapTypes.YFT_HEATMAP_FAILURE);
