import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const palette: PaletteOptions = {
  primary: {
    main: '#497091',
    light: '#2196F3',
  },
  secondary: {
    main: '#18A0FB',
  },
  background: {
    default: '#FFF',
    paper: '#F7F8FC',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.8)',
  },
};
