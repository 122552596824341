import { action } from 'typesafe-actions';
import {
  PublicDataGeneratorContent,
  PublicDataGeneratorContentTypes,
} from './types';

export const publicDataGeneratorContentSubmit = (
  content: PublicDataGeneratorContent
) =>
  action(PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_SUBMIT, content);
export const publicDataGeneratorContentRequest = () =>
  action(PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_REQUEST);
export const publicDataGeneratorContentLoaded = (
  content: PublicDataGeneratorContent
) =>
  action(PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_LOADED, content);
export const publicDataGeneratorContentFailure = () =>
  action(PublicDataGeneratorContentTypes.PUBLIC_DATA_GENERATOR_FAILURE);
