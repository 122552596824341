import React from 'react';
import { Table, TableContainer } from '@material-ui/core';
import Styles from './styles';
import TableHeader from './components/tableHeader';
import TableBody from './components/tableBody';

interface Props {
  data: any[];
  header: string[];
  hasActions?: {
    edit?: boolean;
    delete?: boolean;
    print?: boolean;
  };
  updateItem?: (id: string) => void;
  deleteItem?: (id: string) => void;
  printFile?: (id: string) => void;
}

const CustomTable: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const {
    data,
    header,
    hasActions,
    updateItem = () => null,
    deleteItem = () => null,
    printFile = () => null,
  } = props;

  return (
    <TableContainer className={styles.tableContainer}>
      <Table className={styles.table}>
        <TableHeader header={header} hasActions={hasActions} />
        <TableBody
          rows={data}
          hasActions={hasActions}
          updateItem={updateItem}
          deleteItem={deleteItem}
          printFile={printFile}
        />
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
