import { Reducer } from 'redux';
import { AboutContentState, AboutContentTypes } from './types';

const INITIAL_STATE: AboutContentState = {
  content: {
    text: '',
    title: '',
  },
  loaded: false,
  loading: false,
  error: false,
};

const reducer: Reducer<AboutContentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AboutContentTypes.ABOUT_CONTENT_SUBMIT:
    case AboutContentTypes.ABOUT_CONTENT_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: false,
      };
    case AboutContentTypes.ABOUT_CONTENT_LOADED:
      return {
        ...state,
        content: action.payload,
        loaded: true,
        loading: false,
        error: false,
      };
    case AboutContentTypes.ABOUT_CONTENT_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
