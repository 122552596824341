import { call, put } from 'redux-saga/effects';
import { AboutContentTypes, AboutContent } from './types';
import {
  getAboutContent as getAboutContentApi,
  setAboutContent as setAboutContentApi,
} from '../../../../services/api/contentApi';
import { aboutContentLoaded, aboutContentFailure } from './actions';
import { aboutContentViolation } from '../../errors/action';
import { aboutContentViolationParser } from '../../../../utils/errorParser';

export function* setAboutContent({
  payload,
}: {
  type: typeof AboutContentTypes.ABOUT_CONTENT_SUBMIT;
  payload: AboutContent;
}) {
  try {
    const response = yield call(setAboutContentApi, payload);
    if (response.status === 200) {
      yield put(aboutContentLoaded(response.data));
    } else {
      yield put(aboutContentFailure());
    }
  } catch (err) {
    yield put(
      aboutContentViolation(aboutContentViolationParser(err.response.data))
    );
    yield put(aboutContentFailure());
  }
}

export function* getAboutContent() {
  try {
    const response = yield call(getAboutContentApi);
    if (response.status === 200) {
      yield put(aboutContentLoaded(response.data));
    } else {
      yield put(aboutContentFailure());
    }
  } catch (err) {
    yield put(aboutContentFailure());
  }
}
