import { Typography } from '@material-ui/core';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Styles from './styles';

interface Props {
  title?: string;
  topText?: string;
  bottomText?: string;
  children?: React.ReactNode;
}

const ContentBlock: React.FC<Props> = (props: Props) => {
  const { title, topText, bottomText, children } = props;

  const classes = Styles();

  return (
    <div className={classes.contentBlock}>
      <div className={classes.titleBox}>
        <Typography variant="h2" color="primary">
          {title}
        </Typography>
      </div>
      {topText && <span className="ql-editor">{ReactHtmlParser(topText)}</span>}
      {children && (
        <span className={classes.contentItem}>
          <>{children}</>
        </span>
      )}
      {bottomText && (
        <span className="ql-editor">{ReactHtmlParser(bottomText)}</span>
      )}
    </div>
  );
};

export default ContentBlock;
