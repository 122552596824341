import { action } from 'typesafe-actions';
import { FisheryBetProductionDataTypes, FisheryProductionData } from './types';

export const fisheryBetProductionDataRequest = () =>
  action(FisheryBetProductionDataTypes.FISHERY_BET_PRODUCTION_REQUEST);

export const fisheryBetProductionDataSuccess = (data: FisheryProductionData) =>
  action(FisheryBetProductionDataTypes.FISHERY_BET_PRODUCTION_SUCCESS, data);

export const fisheryBetProductionDataFailure = () =>
  action(FisheryBetProductionDataTypes.FISHERY_BET_PRODUCTION_FAILURE);
