import { action } from 'typesafe-actions';
import { SpeciesContent, SwoContentTypes } from './types';

export const swoContentSubmit = (content: SpeciesContent) =>
  action(SwoContentTypes.SWO_SUBMIT, content);

export const swoContentRequest = () => action(SwoContentTypes.SWO_REQUEST);

export const swoContentLoaded = (content: SpeciesContent) =>
  action(SwoContentTypes.SWO_LOADED, content);

export const swoContentFailure = () => action(SwoContentTypes.SWO_FAILURE);
