import { call, put } from 'redux-saga/effects';
import {
  fisheryYftProductionDataSuccess,
  fisheryYftProductionDataFailure,
} from './actions';
import { getFisheryYftProductionData } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryYftProductionData() {
  try {
    const response = yield call(getFisheryYftProductionData);
    if (response.status === 200) {
      yield put(fisheryYftProductionDataSuccess(response.data));
    } else if (response.status === 401) {
      yield put(fisheryYftProductionDataFailure());
    } else {
      yield put(fisheryYftProductionDataFailure());
    }
  } catch (err) {
    yield put(fisheryYftProductionDataFailure());
  }
}
