import { action } from 'typesafe-actions';
import { PasswordTypes, Password } from './types';

export const passwordRequest = (email: string) =>
  action(PasswordTypes.PASSWORD_REQUEST, email);
export const passwordSuccess = () => action(PasswordTypes.PASSWORD_SUCCESS);
export const passwordFailure = () => action(PasswordTypes.PASSWORD_FAILURE);
export const clearPasswordState = () =>
  action(PasswordTypes.CLEAR_PASSWORD_STATE);

export const passwordChangeRequest = (newPassword: Password) =>
  action(PasswordTypes.CHANGE_PASSWORD_REQUEST, newPassword);

export const passwordResetRequest = (paramsPassword: Password) =>
  action(PasswordTypes.RESET_PASSWORD_REQUEST, paramsPassword);
