import { call, put } from 'redux-saga/effects';
import { HomeContentTypes, HomeContent } from './types';
import {
  getHomeContent as getHomeContentApi,
  setHomeContent as setHomeContentApi,
} from '../../../../services/api/contentApi';
import { homeContentLoaded, homeContentFailure } from './actions';
import { homeContentViolation } from '../../errors/action';
import { homeContentViolationParser } from '../../../../utils/errorParser';

export function* setHomeContent({
  payload,
}: {
  type: typeof HomeContentTypes.HOME_CONTENT_SUBMIT;
  payload: HomeContent;
}) {
  try {
    const response = yield call(setHomeContentApi, payload);
    if (response.status === 200) {
      yield put(homeContentLoaded(response.data));
    } else {
      yield put(homeContentFailure());
    }
  } catch (err) {
    yield put(
      homeContentViolation(homeContentViolationParser(err.response.data))
    );
    yield put(homeContentFailure());
  }
}

export function* getHomeContent() {
  try {
    const response = yield call(getHomeContentApi);
    if (response.status === 200) {
      yield put(homeContentLoaded(response.data));
    } else {
      yield put(homeContentFailure());
    }
  } catch (err) {
    yield put(homeContentFailure());
  }
}
