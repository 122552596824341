import { action } from 'typesafe-actions';
import { SpeciesContent, AlbContentTypes } from './types';

export const albContentSubmit = (content: SpeciesContent) =>
  action(AlbContentTypes.ALB_SUBMIT, content);

export const albContentRequest = () => action(AlbContentTypes.ALB_REQUEST);

export const albContentLoaded = (content: SpeciesContent) =>
  action(AlbContentTypes.ALB_LOADED, content);

export const albContentFailure = () => action(AlbContentTypes.ALB_FAILURE);
