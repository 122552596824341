/**
 * actions types
 */
export enum FisherySwoProductionDataTypes {
  FISHERY_SWO_PRODUCTION_REQUEST = '@chart/FISHERY_SWO_PRODUCTION_REQUEST',
  FISHERY_SWO_PRODUCTION_SUCCESS = '@chart/FISHERY_SWO_PRODUCTION_SUCCESS',
  FISHERY_SWO_PRODUCTION_FAILURE = '@chart/FISHERY_SWO_PRODUCTION_FAILURE',
}

export type {
  FisheryProductionData,
  FisheryProductionDataState,
} from '../../types';
