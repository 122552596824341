import { Reducer } from 'redux';
import { FishingTripTypes, FishingTripState } from './types';

const INITIAL_STATE: FishingTripState = {
  data: [],
  count: 0,
  loading: false,
  error: false,
};

const reducer: Reducer<FishingTripState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FishingTripTypes.FISHING_TRIP_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case FishingTripTypes.FISHING_TRIP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        count: action.payload.count,
        data: action.payload.data,
      };

    case FishingTripTypes.FISHING_TRIP_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
