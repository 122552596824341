import { action } from 'typesafe-actions';
import { AlbHeatmapTypes, Point } from './types';

export const albHeatmapRequest = () =>
  action(AlbHeatmapTypes.ALB_HEATMAP_REQUEST);

export const albHeatmapSuccess = (data: Point[]) =>
  action(AlbHeatmapTypes.ALB_HEATMAP_SUCCESS, data);

export const albHeatmapFailure = () =>
  action(AlbHeatmapTypes.ALB_HEATMAP_FAILURE);
