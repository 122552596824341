const jwtTokenAuth = 'JWT_TOKEN_AUTH';
const userRole = 'USER_ROLE';

export const saveAccessToken = (token: string) => {
  localStorage.setItem(jwtTokenAuth, token);
  return token;
};

export const saveRole = (role: string) => {
  localStorage.setItem(userRole, role);
  return role;
};

export const deleteUserRole = () => localStorage.removeItem(userRole);
export const getUserRole = () => localStorage.getItem(userRole);

export const deleteAccessToken = () => {
  deleteUserRole();
  localStorage.removeItem(jwtTokenAuth);
};

export const getAccessToken = () => localStorage.getItem(jwtTokenAuth);

export const hasAccessToken = () => getAccessToken() != null;
