import { Filter } from '../filters/types';

/**
 * actions types
 */
export enum ReportTypes {
  LOAD_REQUEST_REPORT = '@report/LOAD_REQUEST_REPORT',
  LOAD_REQUEST_CSV = '@report/LOAD_REQUEST_CSV',
  LOAD_SUCCESS_REPORT = '@report/LOAD_SUCCESS_REPORT',
  LOAD_SUCCESS_CSV = '@report/LOAD_SUCCESS_CSV',
  LOAD_FAILURE = '@report/LOAD_FAILURE',

  SET_NAME_FILTER = '@report/SET_NAME_FILTER',
  SET_PERIOD_FILTER = '@report/SET_PERIOD_FILTER',
  SET_GROUPERS_FILTER = '@report/SET_GROUPERS_FILTER',
  SET_VARIABLES_FILTER = '@report/SET_VARIABLES_FILTER',

  SET_FILTER = '@report/SET_FILTER',
}

/**
 * data types
 */

export interface ReportData {
  portArrival?: string;
  species?: string;
  year?: number;
  month?: number;
  catchQuilograms?: number;
  fishQuantity?: string;
  fishingTripQuantity?: string;
  setQuantity?: string;
  vesselsQuantity?: string;
}

export interface CountData {
  catchQuilograms?: number;
  fishQuantity?: string;
  fishingTripQuantity?: string;
  setQuantity?: string;
  vesselsQuantity?: string;
}

/**
 * state type
 */
export interface ReportState {
  readonly reportData: ReportData[];
  readonly countData: CountData;
  readonly dataHeader: string[];
  readonly filter: Filter;
  readonly loading: boolean;
  readonly error: boolean;
  readonly successCsv: boolean;
}
