import api from './api';
import { getAccessToken } from '../localStorageService';

export const getFishingTrip = (params: { skip: number; take: number }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    params,
  };

  return api.get('/fishing-trips', config);
};
