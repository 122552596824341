import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import * as HomeContentAction from '../../../store/modules/content/home/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { ApplicationState } from '../../../store';
import {
  HomeContentState,
  HomeContent,
} from '../../../store/modules/content/home/types';
import { ErrorState } from '../../../store/modules/errors/types';
import TextEditor from '../../../components/TextEditor';
import BaseForm from '../../../components/BaseForm';

interface StateProps {
  errors: ErrorState;
  homeContent: HomeContentState;
}

interface DispatchProps {
  homeContentRequest(): void;
  clearHomeContentViolation(): void;
  homeContentSubmit(data: HomeContent): void;
}

type Props = StateProps & DispatchProps;

const HomeContentManagement: React.FC<Props> = (props: Props) => {
  const {
    errors,
    homeContent,
    homeContentRequest,
    homeContentSubmit,
    clearHomeContentViolation,
  } = props;

  const [title, setTitle] = useState('');
  const [introductoryText, setIntroductoryText] = useState('');
  const [lineChartText, setLineChartText] = useState('');
  const [mainSpeciesChartTitle, setMainSpeciesChartTitle] = useState('');
  const [mainSpeciesChartText, setMainSpeciesChartText] = useState('');
  const [heatMapTitle, setHeatMapTitle] = useState('');
  const [heatMapText, setHeatMapText] = useState('');
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    homeContentRequest();
    clearHomeContentViolation();
  }, [clearHomeContentViolation, homeContentRequest]);

  useEffect(() => {
    setTitle(homeContent.content.title);
    setIntroductoryText(homeContent.content.introductoryText);
    setLineChartText(homeContent.content.lineChartText);
    setMainSpeciesChartTitle(homeContent.content.mainSpeciesChartTitle);
    setMainSpeciesChartText(homeContent.content.mainSpeciesChartText);
    setHeatMapTitle(homeContent.content.heatMapTitle);
    setHeatMapText(homeContent.content.heatMapText);
  }, [
    homeContent.content.heatMapText,
    homeContent.content.heatMapTitle,
    homeContent.content.introductoryText,
    homeContent.content.lineChartText,
    homeContent.content.mainSpeciesChartText,
    homeContent.content.mainSpeciesChartTitle,
    homeContent.content.title,
  ]);

  // TODO: Codedebt: Handle with empty texts #241
  // submit
  const onSubmit = useCallback(async () => {
    const data: HomeContent = {
      title,
      introductoryText,
      lineChartText,
      mainSpeciesChartTitle,
      mainSpeciesChartText,
      heatMapTitle,
      heatMapText,
    };

    homeContentSubmit(data);
    clearHomeContentViolation();
  }, [
    title,
    introductoryText,
    lineChartText,
    mainSpeciesChartTitle,
    mainSpeciesChartText,
    heatMapTitle,
    heatMapText,
    homeContentSubmit,
    clearHomeContentViolation,
  ]);

  useEffect(() => {
    setLoading(homeContent.loading && !homeContent.error);
  }, [homeContent.error, homeContent.loading]);

  return (
    <BaseForm
      loading={loading}
      pageTitle={t('content_manager')}
      formTitle={t('main_page')}
      buttonLabel={t('save')}
      onSubmit={onSubmit}
    >
      <TextField
        label={t('title_label')}
        value={title}
        fullWidth
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        required
        error={errors.homeContent.title !== ''}
        helperText={errors.homeContent.title}
      />

      <TextEditor
        required
        label={t('introductory_text_label')}
        textValue={introductoryText}
        setTextValue={setIntroductoryText}
        error={errors.homeContent.introductoryText}
      />

      <TextEditor
        required
        label={t('line_chart_text_label')}
        textValue={lineChartText}
        setTextValue={setLineChartText}
        error={errors.homeContent.lineChartText}
      />

      <TextField
        label={t('main_species_chart_title_label')}
        value={mainSpeciesChartTitle}
        fullWidth
        onChange={(e) => {
          setMainSpeciesChartTitle(e.target.value);
        }}
        required
        error={errors.homeContent.mainSpeciesChartTitle !== ''}
        helperText={errors.homeContent.mainSpeciesChartTitle}
      />

      <TextEditor
        required
        label={t('main_species_chart_text_label')}
        textValue={mainSpeciesChartText}
        setTextValue={setMainSpeciesChartText}
        error={errors.homeContent.mainSpeciesChartText}
      />

      <TextField
        label={t('heat_map_title_label')}
        value={heatMapTitle}
        fullWidth
        onChange={(e) => {
          setHeatMapTitle(e.target.value);
        }}
        required
        error={errors.homeContent.heatMapTitle !== ''}
        helperText={errors.homeContent.heatMapTitle}
      />

      <TextEditor
        required
        label={t('heat_map_text_label')}
        textValue={heatMapText}
        setTextValue={setHeatMapText}
        error={errors.homeContent.heatMapText}
      />
    </BaseForm>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    homeContent: state.homeContent,
    errors: state.errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...HomeContentAction,
      ...ErrorsAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContentManagement);
