import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { ApplicationState } from '../../store';
import * as FiltersAction from '../../store/modules/filters/actions';
import TitleLine from '../../components/TitleLine';
import {
  FiltersState,
  PeriodToString,
  GrouperToString,
  VariableToString,
} from '../../store/modules/filters/types';
import Styles from './styles';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import TopBoxContainer from '../../components/TopBoxContainer';
import Table from '../../components/Table';
import Button from '../../components/Button';
import ConfirmationBox from '../../components/ConfirmationBox';

interface StateProps {
  filters: FiltersState;
}

interface DispatchProps {
  listFiltersRequest(): void;
  deleteFilterRequest(id: string): void;
}

type Props = StateProps & DispatchProps;

const Filters: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { filters, listFiltersRequest, deleteFilterRequest } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const header = [t('name'), t('period'), t('groupers'), t('variables')];

  const [customData, setCustomData] = useState<any>([]);

  useEffect(() => {
    const modifiedData = new Array<any>();

    filters.data.forEach((filter) => {
      modifiedData.push({
        id: filter.id,
        name: filter.name,
        period: PeriodToString(filter.period),
        groupers: GrouperToString(filter.groupers),
        variables: VariableToString(filter.variables),
      });
    });

    setCustomData(modifiedData);
  }, [filters.data]);

  const deleteItemWithConfirmation = (id: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            title={t('confirmation')}
            message={t('sure_to_delete_filter', {
              user: filters.data.find((e) => e.id === id)?.name,
            })}
            okLabel={t('delete')}
            okAction={() => {
              deleteFilterRequest(id);
              onClose();
            }}
            cancelLabel={t('cancel')}
            cancelAction={() => onClose()}
          />
        );
      },
    });
  };

  const updateItem = (id: string) => {
    history.push(`/mainpage/filters/update-filter/${id}`);
  };

  useEffect(() => {
    listFiltersRequest();
  }, [listFiltersRequest]);

  return (
    <div className={styles.root}>
      <TitleLine title={t('filters')} />
      <Button onClick={() => history.push('/mainpage/filters/new-filter')}>
        {t('new_filter')}
      </Button>
      {filters.listFiltersLoading && <LoaderTailSpin />}
      <TopBoxContainer title={t('list_filters')}>
        <div>
          <Table
            data={customData}
            header={header}
            hasActions={{
              edit: true,
              delete: true,
            }}
            deleteItem={deleteItemWithConfirmation}
            updateItem={updateItem}
          />
        </div>
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    filters: state.filters,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(FiltersAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
