import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Link, useHistory } from 'react-router-dom';
import { Container, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { ApplicationState } from '../../../store';
import * as PasswordAction from '../../../store/modules/password/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { PasswordState } from '../../../store/modules/password/types';
import Styles from './styles';

import logoImg from '../../../assets/logoOpenTuna.png';
import { ErrorState } from '../../../store/modules/errors/types';
import LoaderTailSpin from '../../../components/LoaderTailSpin';
import ConfirmationBox from '../../../components/ConfirmationBox';

interface StateProps {
  password: PasswordState;
  errors: ErrorState;
}

interface DispatchProps {
  passwordRequest(email: string): void;
  clearPasswordState(): void;
  clearPasswordViolation(): void;
}

type Props = StateProps & DispatchProps;

const ForgotPassword: React.FC<Props> = (props: Props) => {
  const {
    passwordRequest,
    password,
    errors,
    clearPasswordState,
    clearPasswordViolation,
  } = props;

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const styles = Styles();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  useEffect(() => {
    clearPasswordState();
    clearPasswordViolation();
  }, [clearPasswordViolation, clearPasswordState]);

  const handleSubmit = async () => {
    await passwordRequest(email);
  };

  const sendAlert = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            title={t('email_sent')}
            okLabel={t('ok')}
            okAction={() => {
              history.push('/login');
              onClose();
            }}
          />
        );
      },
      onClickOutside: () => {
        history.push('/login');
      },
    });
  }, [history, t]);

  useEffect(() => {
    if (password.success) {
      clearPasswordState();
      sendAlert();
    }
  }, [password.success, clearPasswordState, sendAlert]);

  return (
    <div className={styles.root}>
      <Container className={styles.content}>
        <img className={styles.img} src={logoImg} alt="OpenTuna" />

        <Form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
          <h1 className={styles.h1}>{t('recovery_password')}</h1>

          <div className={styles.inputDiv}>
            <TextField
              autoFocus
              fullWidth
              name="email"
              label={t('insert_email')}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              error={errors.password.passwordRecovery.email !== ''}
              helperText={errors.password.passwordRecovery.email}
            />
          </div>

          {password.loading && <LoaderTailSpin />}

          <div className={styles.bottomDiv}>
            <Link className={styles.a} to="/login">
              {t('remember_password')}
            </Link>

            <Button className={styles.button} type="submit">
              {t('recovery')}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    password: state.password,
    errors: state.errors,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PasswordAction, ...ErrorsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
