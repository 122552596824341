import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Form } from '@unform/web';
import { useTranslation } from 'react-i18next';
import { Button, Grid, TextField } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { confirmAlert } from 'react-confirm-alert';
import TopBoxContainer from '../../../components/TopBoxContainer';
import { ApplicationState } from '../../../store';
import * as PasswordAction from '../../../store/modules/password/actions';
import * as ErrorsAction from '../../../store/modules/errors/action';
import { Password, PasswordState } from '../../../store/modules/password/types';
import Styles from './styles';
import { ErrorState } from '../../../store/modules/errors/types';
import ConfirmationBox from '../../../components/ConfirmationBox';
import TitleLine from '../../../components/TitleLine';

interface StateProps {
  password: PasswordState;
  errors: ErrorState;
}

interface DispatchProps {
  passwordChangeRequest(newPassword: Password): void;
  clearPasswordState(): void;
  clearPasswordViolation(): void;
}

type Props = StateProps & DispatchProps;

const ChangePassword: React.FC<Props> = (props: Props) => {
  const {
    password,
    passwordChangeRequest,
    clearPasswordState,
    clearPasswordViolation,
    errors,
  } = props;
  const { t } = useTranslation();
  const classes = Styles();
  const formRef = useRef<FormHandles>(null);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const handleSubmit = async () => {
    await passwordChangeRequest({
      keypass: oldPassword,
      newPassword,
      newPasswordConfirmation,
    });
  };

  useEffect(() => {
    clearPasswordState();
    clearPasswordViolation();
  }, [clearPasswordViolation, clearPasswordState]);

  const changeConfirmation = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            title={t('password_change_success')}
            okLabel={t('ok')}
            okAction={() => onClose()}
          />
        );
      },
    });
  }, [t]);

  useEffect(() => {
    if (password.success) {
      clearPasswordState();
      clearPasswordViolation();
      changeConfirmation();

      setNewPassword('');
      setOldPassword('');
      setNewPasswordConfirmation('');
    }
  }, [
    changeConfirmation,
    clearPasswordState,
    clearPasswordViolation,
    password.success,
  ]);

  return (
    <div className={classes.root}>
      <TitleLine title={t('change_password')} />
      <TopBoxContainer title={t('change_password')}>
        <Form
          noValidate
          ref={formRef}
          onSubmit={handleSubmit}
          className={classes.internContent}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
            item
            sm={8}
            md={12}
          >
            <div className={classes.formStyles}>
              <TextField
                id="old-password"
                name="old-password"
                label={t('old_password')}
                type="password"
                value={oldPassword}
                fullWidth
                onChange={(e) => setOldPassword(e.target.value)}
                required
                error={errors.password.passwordChange.keypass !== ''}
                helperText={errors.password.passwordChange.keypass}
              />
            </div>

            <div className={classes.formStyles}>
              <TextField
                id="new-password"
                name="new-password"
                label={t('new_password')}
                type="password"
                value={newPassword}
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)}
                required
                error={errors.password.passwordChange.newPassword !== ''}
                helperText={errors.password.passwordChange.newPassword}
              />
            </div>

            <div className={classes.formStyles}>
              <TextField
                id="new-password-confirmation"
                name="new-password-confirmation"
                label={t('confirm_password')}
                type="password"
                value={newPasswordConfirmation}
                fullWidth
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                required
                error={
                  errors.password.passwordChange.newPasswordConfirmation !== ''
                }
                helperText={
                  errors.password.passwordChange.newPasswordConfirmation
                }
              />
            </div>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            disabled={password.loading}
            className={classes.button}
          >
            {t('save')}
          </Button>
        </Form>
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    password: state.password,
    errors: state.errors,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PasswordAction, ...ErrorsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
