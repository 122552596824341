import { combineReducers } from 'redux';
import auth from './auth/reducer';
import password from './password/reducer';
import users from './users/reducer';
import species from './species/reducer';
import filters from './filters/reducer';
import report from './report/reducer';
import errors from './errors/reducer';
import mainSpecies from './dataProvider/homeData/mainSpecies/reducer';
import fisheryData from './dataProvider/homeData/fisheryData/reducer';
import fisheryProduction from './dataProvider/homeData/fisheryProduction/reducer';
import fisherySwoData from './dataProvider/swoData/fisheryData/reducer';
import fisherySwoProduction from './dataProvider/swoData/fisheryProduction/reducer';
import swoHeatmap from './dataProvider/swoData/heatmap/reducer';
import fisheryBetData from './dataProvider/betData/fisheryData/reducer';
import fisheryBetProduction from './dataProvider/betData/fisheryProduction/reducer';
import betHeatmap from './dataProvider/betData/heatmap/reducer';
import fisheryAlbData from './dataProvider/albData/fisheryData/reducer';
import fisheryAlbProduction from './dataProvider/albData/fisheryProduction/reducer';
import albHeatmap from './dataProvider/albData/heatmap/reducer';
import fisheryYftData from './dataProvider/yftData/fisheryData/reducer';
import fisheryYftProduction from './dataProvider/yftData/fisheryProduction/reducer';
import yftHeatmap from './dataProvider/yftData/heatmap/reducer';
import fisheryByCatchData from './dataProvider/byCatch/fisheryData/reducer';
import fisheryByCatchProduction from './dataProvider/byCatch/fisheryProduction/reducer';
import byCatchSpeciesDistribution from './dataProvider/byCatch/speciesDistribution/reducer';
import byCatchHeatmap from './dataProvider/byCatch/heatmap/reducer';
import homeContent from './content/home/reducer';
import aboutContent from './content/about/reducer';
import swoContent from './content/swo/reducer';
import yftContent from './content/yft/reducer';
import betContent from './content/bet/reducer';
import albContent from './content/alb/reducer';
import byCatchContent from './content/byCatch/reducer';
import homeHeatmap from './dataProvider/homeData/heatmap/reducer';
import publicDataGeneratorContent from './content/publicDataGenerator/reducer';
import fishingTrip from './fishingTrip/reducer';
import logbook from './logbook/reducer';
import vessel from './vessel/reducer';

export default combineReducers({
  auth,
  password,
  users,
  species,
  filters,
  report,
  errors,
  mainSpecies,
  fisheryData,
  fisheryProduction,
  fisherySwoData,
  fisherySwoProduction,
  swoHeatmap,
  fisheryBetData,
  fisheryBetProduction,
  betHeatmap,
  fisheryAlbData,
  fisheryAlbProduction,
  albHeatmap,
  fisheryYftData,
  fisheryYftProduction,
  yftHeatmap,
  fisheryByCatchData,
  fisheryByCatchProduction,
  byCatchSpeciesDistribution,
  byCatchHeatmap,
  homeContent,
  aboutContent,
  swoContent,
  yftContent,
  betContent,
  albContent,
  byCatchContent,
  homeHeatmap,
  publicDataGeneratorContent,
  fishingTrip,
  logbook,
  vessel,
});
