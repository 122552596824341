import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as AuthAction from '../../store/modules/auth/actions';
import { AuthState } from '../../store/modules/auth/types';
import { ApplicationState } from '../../store';

interface StateProps {
  auth: AuthState;
}

interface DispatchProps {
  logoutResquest(): void;
}

type Props = StateProps & DispatchProps;
const Logout: React.FC<Props> = (props: Props) => {
  const { logoutResquest, auth } = props;
  const history = useHistory();

  useEffect(() => {
    logoutResquest();
  }, [logoutResquest]);

  useEffect(() => {
    history.push(`/login`);
  }, [history, auth]);

  return <></>;
};

function mapStateToProps(state: ApplicationState) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
