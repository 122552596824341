import { call, put } from 'redux-saga/effects';
import { fisheryDataSuccess, fisheryDataFailure } from './actions';
import { getFisheryDataChart } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryDataChart() {
  try {
    const response = yield call(getFisheryDataChart);
    if (response.status === 200) {
      yield put(fisheryDataSuccess(response.data.data));
    } else if (response.status === 401) {
      yield put(fisheryDataFailure());
    } else {
      yield put(fisheryDataFailure());
    }
  } catch (err) {
    yield put(fisheryDataFailure());
  }
}
