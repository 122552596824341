import { Point, HeatmapState } from '../../types';

/**
 * actions types
 */
export enum SwoHeatmapTypes {
  SWO_HEATMAP_REQUEST = '@swo/SWO_HEATMAP_REQUEST',
  SWO_HEATMAP_SUCCESS = '@swo/SWO_HEATMAP_SUCCESS',
  SWO_HEATMAP_FAILURE = '@swo/SWO_HEATMAP_FAILURE',
}

/**
 * data types
 */

export type { HeatmapState, Point };
