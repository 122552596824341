/**
 * actions types
 */
export enum YftContentTypes {
  YFT_SUBMIT = '@yftContent/YFT_SUBMIT',
  YFT_REQUEST = '@yftContent/YFT_REQUEST',
  YFT_LOADED = '@yftContent/YFT_SUCCESS',
  YFT_FAILURE = '@yftContent/YFT_FAILURE',
}

export type { SpeciesContent, SpeciesContentState } from '../types';
