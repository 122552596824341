import React, { MouseEventHandler } from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import Styles from './styles';

interface Props {
  children?: string;
  onClick?: MouseEventHandler<unknown>;
}

const Button: React.FC<Props> = (props: Props) => {
  const { children, onClick } = props;
  const styles = Styles();
  return (
    <MaterialButton
      className={styles.button}
      variant="contained"
      color="primary"
      type="submit"
      onClick={onClick}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
