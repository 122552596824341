import { call, put } from 'redux-saga/effects';
import { Failure } from '../errors/types';
import { userViolationParser } from '../../../utils/errorParser';
import { userViolation } from '../errors/action';
import {
  getUserSuccess,
  listUsersRequest,
  listUsersSuccess,
  listUsersFailure,
  userSuccess,
  userFailure,
  meSuccess,
} from './actions';
import {
  getUser as getUserApi,
  getUsers,
  addNewUser,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  getMe as getMeApi,
} from '../../../services/api/usersApi';
import { UsersTypes, NewUser, User } from './types';
import { saveRole } from '../../../services/localStorageService';

export function* getUser({
  payload,
}: {
  type: typeof UsersTypes.GET_USER_REQUEST;
  payload: string;
}) {
  try {
    const response = yield call(getUserApi, payload);
    if (response.status === 200) {
      yield put(getUserSuccess(response.data));
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(userFailure());
    } else if (err.response.status === 404) {
      const notFound: Failure = {
        violations: [{ target: 'notFound', type: 'any' }],
        type: 'any',
      };

      yield put(userFailure());
      yield put(userViolation(userViolationParser(notFound)));
    }
  }
}

export function* loadUsers({
  payload,
}: {
  type: typeof UsersTypes.LIST_USERS_REQUEST;
  payload: number;
}) {
  try {
    const params = { skip: payload * 10, take: 10 };
    const response = yield call(getUsers, params); // use pagination
    if (response.status === 200) {
      yield put(listUsersSuccess(response.data.items, response.data.count));
    } else {
      yield put(listUsersFailure());
    }
  } catch (err) {
    yield put(listUsersFailure());
  }
}

export function* addUser({
  payload,
}: {
  type: typeof UsersTypes.ADD_USER;
  payload: NewUser;
}) {
  try {
    const response = yield call(addNewUser, payload);
    if (response.status === 201) {
      yield put(userSuccess());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(userFailure());
      yield put(userViolation(userViolationParser(err.response.data)));
    } else {
      yield put(userFailure());
    }
  }
}

export function* updateUser({
  payload,
}: {
  type: typeof UsersTypes.UPDATE_USER;
  payload: User;
}) {
  try {
    const response = yield call(updateUserApi, payload);
    if (response.status === 200) {
      yield put(userSuccess());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(userFailure());
      yield put(userViolation(userViolationParser(err.response.data)));
    } else {
      yield put(userFailure());
    }
  }
}

export function* deleteUser({
  payload,
}: {
  type: typeof UsersTypes.DELETE_USER;
  payload: string;
}) {
  try {
    const response = yield call(deleteUserApi, payload);
    if (response.status === 204) {
      yield put(listUsersRequest(0));
    }
  } catch (err) {
    yield put(listUsersFailure());
  }
}

export function* getMe() {
  try {
    const response = yield call(getMeApi);
    if (response.status === 200) {
      const { role } = response.data;
      saveRole(role);
      yield put(meSuccess(response.data));
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(userFailure());
      yield put(userViolation(userViolationParser(err.response.data)));
    } else {
      yield put(userFailure());
    }
  }
}
