import api from './api';
import { getAccessToken } from '../localStorageService';
import { NewUser, User } from '../../store/modules/users/types';

export const getAllUsers = () => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.get('/users', config);
};

export const getUser = (id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return api.get(`/users/${id}`, config);
};

export const getUsers = (params: { skip: number; take: number }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    params,
  };
  return api.get('/users', config);
};

export const addNewUser = (newUser: NewUser) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return api.post('/users', newUser, config);
};

export const updateUser = ({ id, name, email, role, ownedVessels }: User) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  const user = {
    name,
    email,
    role,
    ownedVessels,
  };

  return api.put(`/users/${id}`, user, config);
};

export const deleteUser = (id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return api.delete(`/users/${id}`, config);
};

export const getMe = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return api.get('/users/me', config);
};
