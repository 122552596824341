import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    error: {
      color: 'red',
    },
    ButtonGroup: {
      marginRight: '10px',
      marginBottom: '20px',
      textAlign: 'right',

      '& button': {
        backgroundColor: theme.palette.primary.light,
        color: '#FFFFFF',
        fontWeight: 'bold',
      },
    },
  })
);

export default Styles;
