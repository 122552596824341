import { call, put } from 'redux-saga/effects';
import { Failure } from '../errors/types';
import { filterViolationParser } from '../../../utils/errorParser';
import { filterViolation } from '../errors/action';
import { Filter, FiltersTypes } from './types';

import {
  getFilters,
  addNewFilter,
  getFilter as getFilterApi,
  updateFilter as updateFilterApi,
  deleteFilter as deleteFilterApi,
} from '../../../services/api/filtersApi';
import {
  getFilterSuccess,
  listFiltersSuccess,
  listFiltersFailure,
  listFiltersRequest,
  filterSuccess,
  filterFailure,
} from './actions';

export function* addFilter({
  payload,
}: {
  type: typeof FiltersTypes.ADD_FILTER;
  payload: Filter;
}) {
  try {
    const response = yield call(addNewFilter, payload);
    if (response.status === 201) {
      yield put(filterSuccess());
    } else {
      yield put(filterFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(filterFailure());
      yield put(filterViolation(filterViolationParser(err.response.data)));
    } else {
      yield put(filterFailure());
    }
  }
}

export function* getFilter({
  payload,
}: {
  type: typeof FiltersTypes.GET_FILTER_REQUEST;
  payload: string;
}) {
  try {
    const response = yield call(getFilterApi, payload);
    if (response.status === 200) {
      yield put(getFilterSuccess(response.data));
    } else {
      yield put(filterFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(filterFailure());
    } else if (err.response.status === 404) {
      const notFound: Failure = {
        violations: [{ target: 'notFound', type: 'any' }],
        type: 'any',
      };

      yield put(filterFailure());
      yield put(filterViolation(filterViolationParser(notFound)));
    }
  }
}

export function* updateFilter({
  payload,
}: {
  type: typeof FiltersTypes.UPDATE_FILTER;
  payload: Filter;
}) {
  try {
    const response = yield call(updateFilterApi, payload);
    if (response.status === 200) {
      yield put(filterSuccess());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(filterFailure());
      yield put(filterViolation(filterViolationParser(err.response.data)));
    } else {
      yield put(filterFailure());
    }
  }
}

export function* loadFilters() {
  try {
    const response = yield call(getFilters); // use pagination
    if (response.status === 200) {
      yield put(listFiltersSuccess(response.data.items, response.data.count));
    } else {
      yield put(listFiltersFailure());
    }
  } catch (err) {
    yield put(listFiltersFailure());
  }
}

export function* deleteFilter({
  payload,
}: {
  type: typeof FiltersTypes.DELETE_FILTER;
  payload: string;
}) {
  try {
    const response = yield call(deleteFilterApi, payload);
    if (response.status === 204) {
      yield put(listFiltersRequest());
    }
  } catch (err) {
    yield put(listFiltersFailure());
  }
}
