import { makeStyles, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles(() =>
  createStyles({
    root: {
      color: '#ff3333',
      margin: '10px',
      border: '2px solid #ff3333',
      padding: '10px',
      width: '100%',
      textAlign: 'center',
      borderRadius: '10px',
    },
  })
);

export default Styles;
