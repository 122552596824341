import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ql-container': {
        color: 'black',
        maxHeight: '20rem',
        height: '20rem',
        marginBottom: theme.spacing(2),
        fontFamily: 'Roboto',
      },
    },
    errorMessage: {
      fontSize: '1rem',
      color: 'red',
    },
  })
);

export default Styles;
