import { Reducer } from 'redux';
import { FiltersState, FiltersTypes } from './types';

const INITIAL_STATE: FiltersState = {
  data: [],
  listFiltersLoading: false,
  listFiltersError: false,
  filterLoading: false,
  filterError: false,
  filterSuccess: false,
  filter: {
    id: '',
    name: '',
    period: {
      dateArrivalStart: (null as unknown) as string,
      dateArrivalEnd: (null as unknown) as string,
    },
    groupers: {
      month: false,
      year: false,
      portArrival: false,
      portArrivalOptions: [],
      species: false,
      speciesOptions: [],
    },
    variables: {
      catchQuilograms: false,
      fishQuantity: false,
      fishingTripQuantity: false,
      setQuantity: false,
      vesselsQuantity: false,
    },
  },
};

const reducer: Reducer<FiltersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FiltersTypes.GET_FILTER_REQUEST:
      return {
        ...state,
        filterLoading: true,
        filterError: false,
        filterSuccess: false,
      };
    case FiltersTypes.LIST_FILTERS_REQUEST:
      return { ...state, listFiltersLoading: true };
    case FiltersTypes.LIST_FILTERS_SUCCESS:
      return {
        ...state,
        listFiltersLoading: false,
        listFiltersError: false,
        data: action.payload.data,
      };
    case FiltersTypes.GET_FILTER_SUCCESS:
      return {
        ...state,
        filterLoading: false,
        filterError: false,
        filter: action.payload,
      };
    case FiltersTypes.LIST_FILTERS_FAILURE:
      return {
        ...state,
        listFiltersLoading: false,
        listFiltersError: true,
      };
    case FiltersTypes.ADD_FILTER:
      return {
        ...state,
        filterLoading: true,
      };
    case FiltersTypes.DELETE_FILTER:
      return {
        ...state,
        filterLoading: true,
      };
    case FiltersTypes.UPDATE_FILTER:
      return {
        ...state,
        filterLoading: true,
      };
    case FiltersTypes.FILTER_SUCCESS:
      return {
        ...state,
        filterSuccess: true,
        filterLoading: false,
        filterError: false,
      };
    case FiltersTypes.FILTER_FAILURE:
      return {
        ...state,
        filterSuccess: false,
        filterLoading: false,
        filterError: true,
      };
    case FiltersTypes.CLEAR_FILTER_STATE:
      return {
        ...state,
        filterSuccess: false,
        filterLoading: false,
        filterError: false,
        filter: INITIAL_STATE.filter,
      };
    default:
      return state;
  }
};

export default reducer;
