import React from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Styles from '../styles';

interface Props {
  header: string[];
  hasActions?: {
    edit?: boolean;
    delete?: boolean;
    print?: boolean;
  };
}

const TableHeader: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const styles = Styles();

  const { header, hasActions } = props;

  return (
    <TableHead>
      <TableRow>
        {header.map((title) => (
          <TableCell
            className={styles.boldLabel}
            key={title}
            // TODO: Remove inline style: issue debt code #173
            style={{ fontSize: '1.6rem', fontWeight: 'bold' }}
          >
            {title}
          </TableCell>
        ))}

        {hasActions && (
          <TableCell
            className={styles.boldLabel}
            key="actions"
            align="center"
            // TODO: Remove inline style: issue debt code #173
            style={{ fontSize: '1.6rem', fontWeight: 'bold' }}
          >
            {t('actions')}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
