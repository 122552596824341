import { call, put } from 'redux-saga/effects';
import {
  fisheryByCatchProductionDataSuccess,
  fisheryByCatchProductionDataFailure,
} from './actions';
import { getFisheryByCatchProductionData } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryByCatchProductionData() {
  try {
    const response = yield call(getFisheryByCatchProductionData);
    if (response.status === 200) {
      yield put(fisheryByCatchProductionDataSuccess(response.data));
    } else if (response.status === 401) {
      yield put(fisheryByCatchProductionDataFailure());
    } else {
      yield put(fisheryByCatchProductionDataFailure());
    }
  } catch (err) {
    yield put(fisheryByCatchProductionDataFailure());
  }
}
