import React from 'react';
import { FeatureGroup, Map, TileLayer } from 'react-leaflet';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore<'react-leaflet-heatmap-layer'>
import HeatmapLayer from 'react-leaflet-heatmap-layer';

import { Point } from '../../store/modules/dataProvider/types';
import './styles.css';

interface Points {
  points: Point[];
}

const Heatmap: React.FC<Points> = (props: Points) => {
  const { points } = props;

  return (
    <Map center={[-12.43028, -39.25139]} zoom={2} scrollWheelZoom>
      <TileLayer
        attribution="Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
        url="https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}'"
        maxZoom={10}
      />

      <FeatureGroup color="purple">
        <HeatmapLayer
          fitBoundsOnLoad
          fitBoundsOnUpdate
          points={points}
          radius={10}
          blur={15}
          max={0.5}
          minOpacity={0.25}
          latitudeExtractor={(el: Point) => el.latitude}
          longitudeExtractor={(el: Point) => el.longitude}
          intensityExtractor={() => 1}
        />
      </FeatureGroup>
    </Map>
  );
};

export default Heatmap;
