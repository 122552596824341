/* Imports */
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { useTheme } from '@material-ui/core';
import { FisheryData } from '../../../store/modules/dataProvider/homeData/fisheryData/types';

am4core.useTheme(am4themesAnimated);

interface Props {
  data: FisheryData[];
}

const FisheryDataChart: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const theme = useTheme();

  useEffect(() => {
    const chart = am4core.create('chartdiv3', am4charts.XYChart);

    // Locale defition for numbers formating
    chart.language.locale = am4lang_pt_BR;

    // Increase contrast by taking evey second color
    chart.colors.step = 1;

    // Chart background color
    chart.background.fill = am4core.color(theme.palette.background.paper);

    // Exportation menu
    // TODO - Melhorar o posicionamento do menu e as opções disponíveis
    // chart.exporting.menu = new am4core.ExportMenu();

    // Data
    chart.data = data;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 100;

    // First value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minGridDistance = 100;

    chart.colors.list = [
      am4core.color('#021F59'),
      am4core.color('#0468BF'),
      am4core.color('#025E73'),
      am4core.color('#449CA6'),
    ];

    // Second value axis
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.opposite = false;
    valueAxis2.syncWithAxis = valueAxis;
    valueAxis2.renderer.minGridDistance = 100;

    // Third value axis
    const valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis3.renderer.opposite = true;
    valueAxis3.syncWithAxis = valueAxis;
    valueAxis3.renderer.minGridDistance = 100;

    // Fourth value axis
    const valueAxis4 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis4.renderer.opposite = true;
    valueAxis4.syncWithAxis = valueAxis;
    valueAxis4.renderer.minGridDistance = 100;

    // First series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'weight';
    series.dataFields.dateX = 'date';
    series.name = 'Captura total (kg)';
    series.tooltipText = '{name}: [bold]{valueY}[/]';
    series.strokeWidth = 3;
    series.yAxis = valueAxis;
    series.tensionX = 0.8;
    series.tensionY = 1;
    series.dateFormatter.dateFormat = 'MM/yyyy';
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;

    // Second series
    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = 'yield';
    series2.dataFields.dateX = 'date';
    series2.name = 'Rendimento (CPUE)';
    series2.tooltipText = '{name}: [bold]{valueY}[/]';
    series2.strokeWidth = 1;
    series2.yAxis = valueAxis2;
    series2.tensionX = 1;
    series2.tensionY = 1;
    series2.dateFormatter.dateFormat = 'MM/yyyy';
    valueAxis2.renderer.line.strokeOpacity = 1;
    valueAxis2.renderer.line.strokeWidth = 2;
    valueAxis2.renderer.line.stroke = series2.stroke;
    valueAxis2.renderer.labels.template.fill = series2.stroke;

    // Third series
    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = 'sets';
    series3.dataFields.dateX = 'date';
    series3.name = 'Lances';
    series3.tooltipText = '{name}: [bold]{valueY}[/]';
    series3.strokeWidth = 3;
    series3.yAxis = valueAxis3;
    series3.tensionX = 0.8;
    series3.tensionY = 1;
    series3.dateFormatter.dateFormat = 'MM/yyyy';
    valueAxis3.renderer.line.strokeOpacity = 1;
    valueAxis3.renderer.line.strokeWidth = 2;
    valueAxis3.renderer.line.stroke = series3.stroke;
    valueAxis3.renderer.labels.template.fill = series3.stroke;

    // Fourth series
    const series4 = chart.series.push(new am4charts.LineSeries());
    series4.dataFields.valueY = 'trips';
    series4.dataFields.dateX = 'date';
    series4.name = 'Viagens';
    series4.tooltipText = '{name}: [bold]{valueY}[/]';
    series4.strokeWidth = 3;
    series4.yAxis = valueAxis4;
    series4.tensionX = 0.8;
    series4.tensionY = 1;
    series4.dateFormatter.dateFormat = 'MM/yyyy';
    valueAxis4.renderer.line.strokeOpacity = 1;
    valueAxis4.renderer.line.strokeWidth = 2;
    valueAxis4.renderer.line.stroke = series4.stroke;
    valueAxis4.renderer.labels.template.fill = series4.stroke;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = '[bold {color}]{name}[/]';
    // chart.legend.position = "right"

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  });

  return <div id="chartdiv3" style={{ width: '100%', height: '100%' }} />;
};

export default FisheryDataChart;
