import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      color: 'rgba(0, 0, 0, 0.7);',
    },
    nameFilter: {
      marginBottom: theme.spacing(0.6),
    },
    p: {
      marginTop: theme.spacing(0.1),
      color: 'rgba(0, 0, 0, 0.54);',
      fontSize: '12px',
    },

    period: {
      marginTop: '3rem',
      marginBottom: theme.spacing(1),

      '& .MuiFormControl-root': {
        width: '45%',
      },
    },
    groupers: {
      marginTop: '3rem',
      display: 'flex',
      flexDirection: 'column',
    },
    variables: {
      display: 'flex',
      marginTop: '3rem',
      '& .div:label': {
        marginLeft: '0px',
        marginTop: '0px',
        width: '100%',
      },
    },
    button: {
      marginTop: '15px',
      float: 'right',
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export default Styles;
