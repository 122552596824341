import { call, put } from 'redux-saga/effects';
import { ByCatchContentTypes, ByCatchContent } from './types';
import {
  getByCatchContent as getByCatchContentApi,
  setByCatchContent as setByCatchContentApi,
} from '../../../../services/api/contentApi';
import { byCatchContentLoaded, byCatchContentFailure } from './actions';
import { byCatchContentViolation } from '../../errors/action';
import { byCatchContentViolationParser } from '../../../../utils/errorParser';

export function* setByCatchContent({
  payload,
}: {
  type: typeof ByCatchContentTypes.BYCATCH_CONTENT_SUBMIT;
  payload: ByCatchContent;
}) {
  try {
    const response = yield call(setByCatchContentApi, payload);
    if (response.status === 200) {
      yield put(byCatchContentLoaded(response.data));
    } else {
      yield put(byCatchContentFailure());
    }
  } catch (err) {
    yield put(
      byCatchContentViolation(byCatchContentViolationParser(err.response.data))
    );
    yield put(byCatchContentFailure());
  }
}

export function* getByCatchContent() {
  try {
    const response = yield call(getByCatchContentApi);
    if (response.status === 200) {
      yield put(byCatchContentLoaded(response.data));
    } else {
      yield put(byCatchContentFailure());
    }
  } catch (err) {
    yield put(byCatchContentFailure());
  }
}
