import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import LoaderTailSpin from '../../components/LoaderTailSpin';
import * as AuthAction from '../../store/modules/auth/actions';
import * as UsersAction from '../../store/modules/users/actions';
import { AuthState } from '../../store/modules/auth/types';
import { UsersState } from '../../store/modules/users/types';
import { ApplicationState } from '../../store';

interface StateProps {
  auth: AuthState;
  users: UsersState;
}

interface DispatchProps {
  clearAuthState(): void;
  clearUserState(): void;
  meRequest(): void;
  removeMe(): void;
}

type Props = StateProps & DispatchProps;

const Redirect: React.FC<Props> = (props: Props) => {
  const {
    auth,
    users,
    meRequest,
    removeMe,
    clearAuthState,
    clearUserState,
  } = props;
  const history = useHistory();

  const redirect = useCallback(() => {
    if (
      auth.signed &&
      users.myUser.role &&
      users.userSuccess &&
      !users.userLoading
    ) {
      if (users.myUser.role === 'shipowner') {
        history.push('/mainpage/fishing-trips');
      } else history.push('/mainpage/users');
    } else if (!auth.signed && !auth.loading) {
      history.push('/login');
    }
  }, [
    auth.loading,
    auth.signed,
    history,
    users.myUser.role,
    users.userLoading,
    users.userSuccess,
  ]);

  useEffect(() => {
    if (!users.userError) {
      redirect();
    }
  }, [redirect, auth.signed, users.userError]);

  useEffect(() => {
    if (users.userError) {
      removeMe();
      clearAuthState();
      clearUserState();
    }
  }, [clearAuthState, clearUserState, removeMe, users.userError]);

  useEffect(() => {
    meRequest();
  }, [meRequest]);

  return <LoaderTailSpin />;
};

function mapStateToProps(state: ApplicationState) {
  return {
    auth: state.auth,
    users: state.users,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...AuthAction, ...UsersAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
