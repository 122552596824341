/**
 * actions types
 */
export enum SwoContentTypes {
  SWO_SUBMIT = '@swoContent/SWO_SUBMIT',
  SWO_REQUEST = '@swoContent/SWO_REQUEST',
  SWO_LOADED = '@swoContent/SWO_SUCCESS',
  SWO_FAILURE = '@swoContent/SWO_FAILURE',
}

export type { SpeciesContent, SpeciesContentState } from '../types';
