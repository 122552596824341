import React from 'react';
import { Container, Button } from '@material-ui/core';
import Styles from './styles';

interface Props {
  title?: string;
  message?: string;
  okLabel?: string;
  cancelLabel?: string;
  okAction?: () => void;
  cancelAction?: () => void;
}

const ConfirmationBox: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const {
    title = '',
    message = '',
    okLabel = '',
    cancelLabel = '',
    okAction = () => null,
    cancelAction = () => null,
  } = props;

  return (
    <Container>
      <div className={styles.root}>
        <h1>{title}</h1>
        <p className={styles.message}>{message}</p>
        <div className={styles.buttons}>
          {cancelLabel !== '' && (
            <Button
              className={styles.button}
              variant="contained"
              color="secondary"
              onClick={() => cancelAction()}
            >
              {cancelLabel}
            </Button>
          )}
          {okLabel !== '' && (
            <Button
              className={styles.button}
              variant="contained"
              color="default"
              onClick={() => okAction()}
            >
              {okLabel}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ConfirmationBox;
