import { Point, HeatmapState } from '../../types';

/**
 * actions types
 */
export enum HomeHeatmapTypes {
  HOME_HEATMAP_REQUEST = '@home/HOME_HEATMAP_REQUEST',
  HOME_HEATMAP_SUCCESS = '@home/HOME_HEATMAP_SUCCESS',
  HOME_HEATMAP_FAILURE = '@home/HOME_HEATMAP_FAILURE',
}

/**
 * data types
 */

export type { HeatmapState, Point };
