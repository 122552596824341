import { action } from 'typesafe-actions';
import {
  FisheryByCatchProductionDataTypes,
  FisheryProductionData,
} from './types';

export const fisheryByCatchProductionDataRequest = () =>
  action(FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_REQUEST);

export const fisheryByCatchProductionDataSuccess = (
  data: FisheryProductionData
) =>
  action(
    FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_SUCCESS,
    data
  );

export const fisheryByCatchProductionDataFailure = () =>
  action(FisheryByCatchProductionDataTypes.FISHERY_BYCATCH_PRODUCTION_FAILURE);
