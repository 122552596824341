import { call, put } from 'redux-saga/effects';
import { passwordViolationParser } from '../../../utils/errorParser';
import { passwordViolation } from '../errors/action';

import { passwordSuccess, passwordFailure } from './actions';
import { PasswordTypes, Password } from './types';

import {
  passwordRequest as passwordRequestApi,
  changePasswordRequest as changePasswordRequestApi,
  resetPasswordRequest as resetPasswordRequestApi,
} from '../../../services/api/passwordApi';

export function* passwordRequest({
  payload,
}: {
  type: typeof PasswordTypes.PASSWORD_REQUEST;
  payload: string;
}) {
  try {
    const response = yield call(passwordRequestApi, payload);
    if (response.status === 200) {
      yield put(passwordSuccess());
    } else {
      yield put(passwordFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(passwordFailure());
      yield put(passwordViolation(passwordViolationParser(err.response.data)));
    }
    yield put(passwordFailure());
  }
}

export function* passwordChangeRequest({
  payload,
}: {
  type: typeof PasswordTypes.CHANGE_PASSWORD_REQUEST;
  payload: Password;
}) {
  try {
    const response = yield call(changePasswordRequestApi, payload);
    if (response.status === 200) {
      yield put(passwordSuccess());
    } else {
      yield put(passwordFailure());
    }
  } catch (err) {
    if (err.response.status === 401) {
      yield put(passwordFailure());
      yield put(
        passwordViolation(
          passwordViolationParser({
            type: 'ValidationFailure',
            violations: [{ target: 'oldPassword', type: 'InvalidViolation' }],
          })
        )
      );
    }
    if (err.response.status === 400) {
      yield put(passwordFailure());
      yield put(passwordViolation(passwordViolationParser(err.response.data)));
    }
    yield put(passwordFailure());
  }
}

export function* passwordResetRequest({
  payload,
}: {
  type: typeof PasswordTypes.RESET_PASSWORD_REQUEST;
  payload: Password;
}) {
  try {
    const response = yield call(resetPasswordRequestApi, payload);
    if (response.status === 200) {
      yield put(passwordSuccess());
    } else {
      yield put(passwordFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(passwordFailure());
      yield put(passwordViolation(passwordViolationParser(err.response.data)));
    } else if (err.response.status === 404) {
      yield put(passwordFailure());
      yield put(
        passwordViolation(
          passwordViolationParser({
            type: 'ValidationFailure',
            violations: [{ target: 'token', type: '' }],
          })
        )
      );
    }
    yield put(passwordFailure());
  }
}
