import { action } from 'typesafe-actions';
import { FisherySwoProductionDataTypes, FisheryProductionData } from './types';

export const fisherySwoProductionDataRequest = () =>
  action(FisherySwoProductionDataTypes.FISHERY_SWO_PRODUCTION_REQUEST);

export const fisherySwoProductionDataSuccess = (data: FisheryProductionData) =>
  action(FisherySwoProductionDataTypes.FISHERY_SWO_PRODUCTION_SUCCESS, data);

export const fisherySwoProductionDataFailure = () =>
  action(FisherySwoProductionDataTypes.FISHERY_SWO_PRODUCTION_FAILURE);
