import { Reducer } from 'redux';
import { LogbookState, LogbookTypes } from './types';

const INITIAL_STATE: LogbookState = {
  loading: false,
  error: false,
};

const reducer: Reducer<LogbookState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LogbookTypes.LOGBOOK_PDF_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LogbookTypes.LOGBOOK_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case LogbookTypes.LOGBOOK_PDF_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
