import { Reducer } from 'redux';
import { PasswordState, PasswordTypes } from './types';

const INITIAL_STATE: PasswordState = {
  success: false,
  loading: false,
  error: false,
};

const reducer: Reducer<PasswordState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PasswordTypes.RESET_PASSWORD_REQUEST:
    case PasswordTypes.CHANGE_PASSWORD_REQUEST:
    case PasswordTypes.PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case PasswordTypes.PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case PasswordTypes.PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
      };
    case PasswordTypes.CLEAR_PASSWORD_STATE:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default reducer;
