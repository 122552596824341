import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import * as AlbContentActions from '../../store/modules/content/alb/actions';
import * as FisheryAlbActions from '../../store/modules/dataProvider/albData/fisheryData/actions';
import * as FisheryAlbProductionActions from '../../store/modules/dataProvider/albData/fisheryProduction/actions';
import * as albHeatmapActions from '../../store/modules/dataProvider/albData/heatmap/actions';
import Species from './index';

const mapStateToProps = (state: ApplicationState) => {
  return {
    speciesContent: state.albContent,
    fisherySpeciesData: state.fisheryAlbData,
    fisherySpeciesProduction: state.fisheryAlbProduction,
    heatmap: state.albHeatmap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getHeatmap: albHeatmapActions.albHeatmapRequest,
      speciesContentRequest: AlbContentActions.albContentRequest,
      fisheryDataRequest: FisheryAlbActions.fisheryAlbDataRequest,
      fisheryProductionDataRequest:
        FisheryAlbProductionActions.fisheryAlbProductionDataRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Species);
