import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { shade } from 'polished';

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      alignItems: 'stretch',
      justifyContent: 'center',
      backgroundColor: '#BDBDBD',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      placeContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width: '35%',
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      boxShadow:
        '0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
    },
    img: {
      width: '75%',
      margin: theme.spacing(3),
    },
    form: {
      display: 'contents',
      width: '100%',
      textAlign: 'center',
    },
    h1: {
      color: '#497091',
    },
    inputDiv: {
      width: '80%',
      margin: theme.spacing(3),
    },
    bottomDiv: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: theme.spacing(3),
      width: '100%',
    },
    a: {
      display: 'block',
      color: '#497091',
      marginTop: '24px',
      textDecoration: 'none',
      transition: 'color 0.2s',

      '&:hover': {
        color: shade(0.2, theme.palette.primary.light),
      },
    },
    button: {
      background: theme.palette.primary.light,
      height: theme.spacing(5.5),
      borderRadius: '5px',
      color: '#ffffff',
      width: theme.spacing(12),
      fontWeight: 500,
      transition: 'background-color 0.2s',

      '&:hover': {
        background: shade(0.2, theme.palette.primary.light),
      },
    },
    textField: {
      display: 'flex',
      width: '100%',
      paddingBottom: '10px',

      '&:focus': {
        color: 'cyan',
      },
    },
  })
);

export default Styles;
