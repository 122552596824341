import React from 'react';
import ReactQuill from 'react-quill';
import { FormLabel } from '@material-ui/core';
import Styles from './styles';
import 'react-quill/dist/quill.snow.css';

interface Props {
  error: string;
  label: string;
  required?: boolean;
  textValue: string;
  setTextValue(content: string): void;
}

const TextEditor: React.FC<Props> = (props: Props) => {
  const classes = Styles();

  const { error, label, required, textValue, setTextValue } = props;

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'link', { align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'link',
    'align',
    'list',
    'bullet',
    'indent',
  ];

  return (
    <div className={classes.root}>
      <FormLabel>
        {`${label}${required ? ' *' : ''}`}
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={textValue}
          onChange={setTextValue}
        />
      </FormLabel>
      {error && <p className={classes.errorMessage}>{error}</p>}
    </div>
  );
};

export default TextEditor;
