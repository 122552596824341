/**
 * actions types
 */
export enum FisheryAlbDataTypes {
  FISHERY_ALB_DATA_REQUEST = '@chart/FISHERY_ALB_DATA_REQUEST',
  FISHERY_ALB_DATA_SUCCESS = '@chart/FISHERY_ALB_DATA_SUCCESS',
  FISHERY_ALB_DATA_FAILURE = '@chart/FISHERY_ALB_DATA_FAILURE',
}

export type { FisheryData, FisheryDataState } from '../../types';
