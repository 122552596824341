import api from './api';
import { getAccessToken } from '../localStorageService';
import { Filter } from '../../store/modules/filters/types';

export const getFilter = (id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return api.get(`/filters/${id}`, config);
};

export const getFilters = () => {
  return api.get('/filters');
};

export const addNewFilter = (newFilter: Filter) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return api.post('/filters', newFilter, config);
};

export const updateFilter = (filter: Filter) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return api.put(`/filters/${filter.id}`, filter, config);
};

export const deleteFilter = (id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return api.delete(`/filters/${id}`, config);
};
