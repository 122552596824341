import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import * as SwoContentActions from '../../store/modules/content/swo/actions';
import * as FisherySwoActions from '../../store/modules/dataProvider/swoData/fisheryData/actions';
import * as FisherySwoProductionActions from '../../store/modules/dataProvider/swoData/fisheryProduction/actions';
import * as swoHeatmapActions from '../../store/modules/dataProvider/swoData/heatmap/actions';
import Species from './index';

const mapStateToProps = (state: ApplicationState) => {
  return {
    speciesContent: state.swoContent,
    fisherySpeciesData: state.fisherySwoData,
    fisherySpeciesProduction: state.fisherySwoProduction,
    heatmap: state.swoHeatmap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      speciesContentRequest: SwoContentActions.swoContentRequest,
      fisheryDataRequest: FisherySwoActions.fisherySwoDataRequest,
      fisheryProductionDataRequest:
        FisherySwoProductionActions.fisherySwoProductionDataRequest,
      getHeatmap: swoHeatmapActions.swoHeatmapRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Species);
