import { action } from 'typesafe-actions';
import { FisheryProductionDataTypes, FisheryProductionData } from './types';

export const fisheryProductionDataRequest = () =>
  action(FisheryProductionDataTypes.FISHERY_PRODUCTION_REQUEST);

export const fisheryProductionDataSuccess = (data: FisheryProductionData) =>
  action(FisheryProductionDataTypes.FISHERY_PRODUCTION_SUCCESS, data);

export const fisheryProductionDataFailure = () =>
  action(FisheryProductionDataTypes.FISHERY_PRODUCTION_FAILURE);
