import React from 'react';
import List from '@material-ui/core/List';

import IconPeople from '@material-ui/icons/People';
import IconSearch from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Ballot from '@material-ui/icons/Ballot';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InfoIcon from '@material-ui/icons/Info';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import HomeIcon from '@material-ui/icons/Home';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import { useTranslation } from 'react-i18next';
import { getUserRole } from '../../services/localStorageService';
import MenuItem from './menuItem';
import ExpandableMenuItem from './expandableMenuItem';

interface MenuItem {
  key: string;
  text: string;
  roles: string[];
  link?: string;
  icon: JSX.Element;
  items?: {
    key: string;
    text: string;
    link: string;
    icon: JSX.Element;
  }[];
}

const Sidebar: React.FC = () => {
  const userRole = getUserRole();

  const { t } = useTranslation();

  const menuItems: MenuItem[] = [
    {
      key: 'users',
      text: t('users'),
      roles: ['admin', 'superadmin'],
      link: '/mainpage/users',
      icon: <IconPeople />,
    },
    {
      key: 'board_map',
      text: t('board_map'),
      roles: ['superadmin', 'shipowner'],
      link: '/mainpage/fishing-trips',
      icon: <Ballot />,
    },
    {
      key: 'filters_generator',
      text: t('filters_generator'),
      roles: ['admin', 'superadmin'],
      link: '/mainpage/filters',
      icon: <IconSearch />,
    },
    {
      key: 'content',
      text: t('content'),
      roles: ['admin', 'superadmin'],
      icon: <DescriptionIcon />,
      items: [
        {
          key: 'database',
          text: t('database'),
          link: '/mainpage/public-generator-content',
          icon: <DescriptionIcon />,
        },
        {
          key: 'bycatch',
          text: t('bycatch'),
          link: '/mainpage/bycatch-content',
          icon: <RssFeedIcon />,
        },
        {
          key: 'main_page',
          text: t('main_page'),
          link: '/mainpage/home-content',
          icon: <HomeIcon />,
        },
        {
          key: 'about',
          text: t('about'),
          link: '/mainpage/about-content',
          icon: <InfoIcon />,
        },
        {
          key: 'yft',
          text: t('yft'),
          link: '/mainpage/yft-content',
          icon: <BubbleChartIcon />,
        },
        {
          key: 'bet',
          text: t('bet'),
          link: '/mainpage/bet-content',
          icon: <BubbleChartIcon />,
        },
        {
          key: 'alb',
          text: t('alb'),
          link: '/mainpage/alb-content',
          icon: <BubbleChartIcon />,
        },
        {
          key: 'swo',
          text: t('swo'),
          link: '/mainpage/swo-content',
          icon: <BubbleChartIcon />,
        },
      ],
    },
    {
      key: 'settings',
      text: t('settings'),
      roles: ['admin', 'superadmin', 'shipowner'],
      icon: <SettingsIcon />,
      items: [
        {
          key: 'change_password',
          text: t('change_password'),
          link: '/mainpage/change-password',
          icon: <VpnKeyIcon />,
        },
      ],
    },
    {
      key: 'logout',
      text: t('logout'),
      roles: ['admin', 'superadmin', 'shipowner'],
      link: '/logout',
      icon: <ExitToAppIcon />,
    },
  ];

  return (
    <List>
      {menuItems.map((menuItem) => {
        if (menuItem.roles.includes(userRole ?? '')) {
          if (!menuItem.items) {
            return (
              <MenuItem
                key={menuItem.key}
                text={menuItem.text}
                icon={menuItem.icon}
                link={menuItem.link as string}
              />
            );
          }

          return (
            <ExpandableMenuItem
              key={menuItem.key}
              text={menuItem.text}
              icon={menuItem.icon}
              items={menuItem.items}
            />
          );
        }
        return null;
      })}
    </List>
  );
};

export default Sidebar;
