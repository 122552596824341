import { Reducer } from 'redux';
import { getUserRole } from '../../../services/localStorageService';
import { UsersState, UsersTypes } from './types';

const INITIAL_STATE: UsersState = {
  data: [],
  listUsersLoading: false,
  listUsersError: false,
  totalUsers: 0,
  userLoading: false,
  userError: false,
  userSuccess: false,
  user: {
    id: '',
    name: '',
    email: '',
    role: '',
    ownedVessels: [{ externalId: '', name: '' }],
  },
  myUser: {
    id: '',
    name: '',
    email: '',
    role: getUserRole() ?? '',
  },
};

const reducer: Reducer<UsersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsersTypes.GET_ME_REQUEST:
    case UsersTypes.GET_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
        userSuccess: false,
        userError: false,
      };
    case UsersTypes.LIST_USERS_REQUEST:
      return { ...state, listUsersLoading: true };
    case UsersTypes.LIST_USERS_SUCCESS:
      return {
        ...state,
        listUsersLoading: false,
        listUsersError: false,
        data: action.payload.data,
        totalUsers: action.payload.totalUsers,
      };
    case UsersTypes.LIST_USERS_FAILURE:
      return {
        ...state,
        listUsersLoading: false,
        listUsersError: true,
      };
    case UsersTypes.ADD_USER:
      return {
        ...state,
        userLoading: true,
      };
    case UsersTypes.DELETE_USER:
      return {
        ...state,
        userLoading: true,
      };
    case UsersTypes.UPDATE_USER:
      return {
        ...state,
        userLoading: true,
      };
    case UsersTypes.USER_SUCCESS:
      return {
        ...state,
        userSuccess: true,
        userLoading: false,
        userError: false,
      };
    case UsersTypes.GET_ME_SUCCESS:
      return {
        ...state,
        myUser: action.payload,
        userLoading: false,
        userSuccess: true,
        userError: false,
      };
    case UsersTypes.REMOVE_ME:
      return {
        ...state,
        userSuccess: false,
        userLoading: false,
        userError: false,
        myUser: INITIAL_STATE.myUser,
      };
    case UsersTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userLoading: false,
        userSuccess: false,
        userError: false,
      };
    case UsersTypes.USER_FAILURE:
      return {
        ...state,
        userSuccess: false,
        userLoading: false,
        userError: true,
      };
    case UsersTypes.CLEAR_USER_STATE:
      return {
        ...state,
        userSuccess: false,
        userLoading: false,
        userError: false,
        user: INITIAL_STATE.user,
      };
    default:
      return state;
  }
};

export default reducer;
