import { call, put } from 'redux-saga/effects';
import { BetContentTypes, SpeciesContent } from './types';
import {
  getBetContent as getBetContentApi,
  setBetContent as setBetContentApi,
} from '../../../../services/api/contentApi';

import { betContentLoaded, betContentFailure } from './actions';

import { speciesContentViolationParser } from '../../../../utils/errorParser';
import { speciesContentViolation } from '../../errors/action';

export function* setBetContent({
  payload,
}: {
  type: typeof BetContentTypes.BET_SUBMIT;
  payload: SpeciesContent;
}) {
  try {
    const response = yield call(setBetContentApi, payload);

    if (response.status === 200) {
      yield put(betContentLoaded(response.data));
    } else {
      yield put(betContentFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(betContentFailure());
      yield put(
        speciesContentViolation(
          speciesContentViolationParser(err.response.data)
        )
      );
    }

    yield put(betContentFailure());
  }
}

export function* getBetContent() {
  try {
    const response = yield call(getBetContentApi);
    if (response.status === 200) {
      yield put(betContentLoaded(response.data));
    } else {
      yield put(betContentFailure());
    }
  } catch (err) {
    yield put(betContentFailure());
  }
}
