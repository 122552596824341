import { action } from 'typesafe-actions';
import { HomeHeatmapTypes, Point } from './types';

export const homeHeatmapRequest = () =>
  action(HomeHeatmapTypes.HOME_HEATMAP_REQUEST);

export const homeHeatmapSuccess = (data: Point[]) =>
  action(HomeHeatmapTypes.HOME_HEATMAP_SUCCESS, data);

export const homeHeatmapFailure = () =>
  action(HomeHeatmapTypes.HOME_HEATMAP_FAILURE);
