import { action } from 'typesafe-actions';
import { AboutContent, AboutContentTypes } from './types';

export const aboutContentSubmit = (content: AboutContent) =>
  action(AboutContentTypes.ABOUT_CONTENT_SUBMIT, content);

export const aboutContentRequest = () =>
  action(AboutContentTypes.ABOUT_CONTENT_REQUEST);

export const aboutContentLoaded = (content: AboutContent) =>
  action(AboutContentTypes.ABOUT_CONTENT_LOADED, content);

export const aboutContentFailure = () =>
  action(AboutContentTypes.ABOUT_CONTENT_FAILURE);
