import { makeStyles, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles(() =>
  createStyles({
    chartContainer: {
      width: '100%',
      height: '60rem',
    },
    chartTreeContainer: {
      width: '100%',
      height: '60rem',
    },
    chartHeatMap: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  })
);

export default Styles;
