/**
 * actions types
 */
export enum BetContentTypes {
  BET_SUBMIT = '@betContent/BET_SUBMIT',
  BET_REQUEST = '@betContent/BET_REQUEST',
  BET_LOADED = '@betContent/BET_SUCCESS',
  BET_FAILURE = '@betContent/BET_FAILURE',
}

export type { SpeciesContent, SpeciesContentState } from '../types';
