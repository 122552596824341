import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  cardsGrid: {
    alignItems: 'baseline',
  },
  card: {
    width: '85%',
    height: '85%',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.2) !important',
  },
  cardContent: {
    padding: '15px',
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
  },
  cardTitleIcon: {
    height: '2rem',
  },
  cardValue: {
    fontSize: '3rem',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
}));

export default Styles;
