import { action } from 'typesafe-actions';
import { HomeContent, HomeContentTypes } from './types';

export const homeContentSubmit = (content: HomeContent) =>
  action(HomeContentTypes.HOME_CONTENT_SUBMIT, content);
export const homeContentRequest = () =>
  action(HomeContentTypes.HOME_CONTENT_REQUEST);
export const homeContentLoaded = (content: HomeContent) =>
  action(HomeContentTypes.HOME_CONTENT_LOADED, content);
export const homeContentFailure = () =>
  action(HomeContentTypes.HOME_CONTENT_FAILURE);
