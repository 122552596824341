import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { SpeciesDistributionData } from '../../../store/modules/dataProvider/homeData/mainSpecies/types';
import fishIconJson from './fishIconPictureCoords.json';

am4core.useTheme(am4themesAnimated);

interface Props {
  data: SpeciesDistributionData[];
}

const PictureChart: React.FC<Props> = (props: Props) => {
  const { data } = props;

  useEffect(() => {
    const chart = am4core.create('chartdiv2', am4charts.SlicedChart);
    chart.data = data;

    chart.responsive.enabled = true;

    chart.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: ' Ton' },
      { number: 1e6, suffix: 'M' },
      { number: 1e9, suffix: 'B' },
    ];
    chart.numberFormatter.numberFormat = '#,###.00a';

    const series = chart.series.push(new am4charts.PictorialStackedSeries());
    series.ticks.template.locationX = 1;
    series.ticks.template.locationY = 0.5;
    series.labelsContainer.width = 200;
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.legend.valign = 'bottom';
    chart.legend.labels.template.text = '[bold {color}]{name}[/]';
    chart.legend.maxWidth = 300;
    chart.language.locale = am4lang_pt_BR;
    series.dataFields.value = 'weight';
    series.dataFields.category = 'species';
    series.labels.template.fontSize = 18;
    series.labels.template.disabled = true;
    series.orientation = 'horizontal';

    series.colors.list = [
      am4core.color('#021F59'),
      am4core.color('#0468BF'),
      am4core.color('#449CA6'),
      am4core.color('#025E73'),
    ];

    series.slices.template.inert = true;

    const { iconPath } = fishIconJson;

    series.maskSprite.path = iconPath;

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('mousemove', () => {
        if (chart) {
          chart.dispose();
        }
      });
    };
  });

  return <div id="chartdiv2" style={{ width: '100%', height: '100%' }} />;
};

export default PictureChart;
