import { call, put } from 'redux-saga/effects';
import { swoHeatmapSuccess, swoHeatmapFailure } from './actions';
import { getSwoHeatmap as getSwoHeatmapApi } from '../../../../../services/api/dataProviderApi';

export function* getSwoHeatmap() {
  try {
    const response = yield call(getSwoHeatmapApi);
    if (response.status === 200) {
      yield put(swoHeatmapSuccess(response.data.data));
    }
  } catch (err) {
    yield put(swoHeatmapFailure());
  }
}
