import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import Styles from './styles';
import Router from './Router';
import SideBar from '../../components/Sidebar';
import Logo from '../../assets/logoOpenTuna.png';

const App: React.FC = () => {
  const styles = Styles();

  return (
    <div className={clsx('App', styles.root)}>
      <Drawer
        variant="permanent"
        classes={{
          paper: styles.drawerPaper,
        }}
      >
        <div className={styles.divImg}>
          <Link href="/">
            <img className={styles.img} src={Logo} alt="logo" />
          </Link>
        </div>
        <SideBar />
      </Drawer>
      <main className={styles.content}>
        <Container maxWidth="lg" className={styles.container}>
          <Router />
        </Container>
      </main>
    </div>
  );
};

export default App;
