/**
 * actions types
 */

export enum ErrorTypes {
  USER_VIOLATION = '@error/USER_VIOLATION',
  CLEAR_USER_VIOLATION = '@error/CLEAR_USER_VIOLATION',
  FILTER_VIOLATION = '@error/FILTER_VIOLATION',
  CLEAR_FILTER_VIOLATION = '@error/CLEAR_FILTER_VIOLATION',
  PASSWORD_VIOLATION = '@error/PASSWORD_VIOLATION',
  CLEAR_PASSWORD_VIOLATION = '@error/CLEAR_PASSWORD_VIOLATION',
  PUBLIC_GENERATOR_CONTENT_VIOLATION = '@error/PUBLIC_GENERATOR_CONTENT_VIOLATION',
  CLEAR_PUBLIC_GENERATOR_CONTENT_VIOLATION = '@error/CLEAR_PUBLIC_GENERATOR_CONTENT_VIOLATION',
  HOME_CONTENT_VIOLATION = '@error/HOME_CONTENT_VIOLATION',
  CLEAR_HOME_CONTENT_VIOLATION = '@error/CLEAR_HOME_CONTENT_VIOLATION',
  ABOUT_CONTENT_VIOLATION = '@error/ABOUT_CONTENT_VIOLATION',
  CLEAR_ABOUT_CONTENT_VIOLATION = '@error/CLEAR_ABOUT_CONTENT_VIOLATION',
  BYCATCH_CONTENT_VIOLATION = '@error/BYCATCH_CONTENT_VIOLATION',
  CLEAR_BYCATCH_CONTENT_VIOLATION = '@error/CLEAR_BYCATCH_CONTENT_VIOLATION',
  SPECIES_CONTENT_VIOLATION = '@error/SPECIES_CONTENT_VIOLATION',
  CLEAR_SPECIES_CONTENT_VIOLATION = '@error/CLEAR_SPECIES_CONTENT_VIOLATION',
}

/**
 * data types
 */

export interface Violation {
  target: string;
  type: string;
}

export interface Failure {
  type: string;
  violations: Violation[];
}

export enum Validations {
  MinLengthViolation = 'MinLengthViolation',
  MaxLengthViolation = 'MaxLengthViolation',
  AlreadyInUseViolation = 'AlreadyInUseViolation',
  InvalidViolation = 'InvalidViolation',
  RequiredViolation = 'RequiredViolation',
  NotEmptyObjectViolation = 'NotEmptyObjectViolation',
  NotEmptyArrayViolation = 'NotEmptyArrayViolation',
  InvalidPeriodViolation = 'InvalidPeriodViolation',
  InvalidGroupersViolation = 'InvalidGroupersViolation',
  InvalidVariablesViolation = 'InvalidVariablesViolation',
  DoestNotMatchViolation = 'DoestNotMatchViolation',
  InvalidOptionViolation = 'InvalidOptionViolation',
}

export interface UserErrors {
  notFound: string;
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  ownedVessels: string;
  role: string;
}

export interface FilterErrors {
  notFound: string;
  name: string;
  period: string;
  groupers: string;
  variables: string;
}

export interface PasswordErrors {
  passwordRecovery: {
    email: string;
  };

  passwordChange: {
    keypass: string;
    newPassword: string;
    newPasswordConfirmation: string;
  };
}

export interface PublicGeneratorContentErrors {
  title: string;
  text: string;
}

export interface HomeContentErrors {
  title: string;
  introductoryText: string;
  lineChartText: string;
  mainSpeciesChartTitle: string;
  mainSpeciesChartText: string;
  heatMapTitle: string;
  heatMapText: string;
}

export interface AboutContentErrors {
  title: string;
  text: string;
}

export interface SpeciesContentErrors {
  title: string;
  introductoryText: string;
  lineChartText: string;
  heatMapText: string;
}

export interface ByCatchContentErrors {
  title: string;
  introductoryText: string;
  lineChartText: string;
  mainSpeciesChartText: string;
  heatMapText: string;
}

/**
 * state type
 */
export interface ErrorState {
  readonly user: UserErrors;
  readonly filter: FilterErrors;
  readonly password: PasswordErrors;
  readonly publicGeneratorContent: PublicGeneratorContentErrors;
  readonly homeContent: HomeContentErrors;
  readonly aboutContent: AboutContentErrors;
  readonly speciesContent: SpeciesContentErrors;
  readonly byCatchContent: ByCatchContentErrors;
}
