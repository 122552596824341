/**
 * actions types
 */
export enum PublicDataGeneratorContentTypes {
  PUBLIC_DATA_GENERATOR_SUBMIT = '@publicDataGeneratorContent/PUBLIC_DATA_GENERATOR_SUBMIT',
  PUBLIC_DATA_GENERATOR_REQUEST = '@publicDataGeneratorContent/PUBLIC_DATA_GENERATOR_REQUEST',
  PUBLIC_DATA_GENERATOR_LOADED = '@publicDataGeneratorContent/PUBLIC_DATA_GENERATOR_SUCCESS',
  PUBLIC_DATA_GENERATOR_FAILURE = '@publicDataGeneratorContent/PUBLIC_DATA_GENERATOR_FAILURE',
}

/**
 * data types
 */

export interface PublicDataGeneratorContent {
  title: string;
  text: string;
}

/**
 * state type
 */
export interface PublicDataGeneratorContentState {
  readonly content: PublicDataGeneratorContent;
  readonly loaded: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
