import { Point, HeatmapState } from '../../types';

/**
 * actions types
 */
export enum AlbHeatmapTypes {
  ALB_HEATMAP_REQUEST = '@alb/ALB_HEATMAP_REQUEST',
  ALB_HEATMAP_SUCCESS = '@alb/ALB_HEATMAP_SUCCESS',
  ALB_HEATMAP_FAILURE = '@alb/ALB_HEATMAP_FAILURE',
}

/**
 * data types
 */

export type { HeatmapState, Point };
