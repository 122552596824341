import { call, put } from 'redux-saga/effects';
import { FishingTripTypes } from './types';
import { fishingTripsSuccess, fishingTripsFailure } from './actions';
import { getFishingTrip as getFishingTripApi } from '../../../services/api/fishingTripApi';

export function* getfishingTrips({
  payload,
}: {
  type: typeof FishingTripTypes.FISHING_TRIP_REQUEST;
  payload: number;
}) {
  try {
    const params = { skip: payload * 10, take: 10 };
    const response = yield call(getFishingTripApi, params); // use pagination
    if (response.status === 200) {
      yield put(fishingTripsSuccess(response.data.count, response.data.data));
    }
  } catch (err) {
    yield put(fishingTripsFailure());
  }
}
