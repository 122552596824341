import { call, put } from 'redux-saga/effects';
import { SwoContentTypes, SpeciesContent } from './types';
import {
  getSwoContent as getSwoContentApi,
  setSwoContent as setSwoContentApi,
} from '../../../../services/api/contentApi';

import { swoContentLoaded, swoContentFailure } from './actions';

import { speciesContentViolationParser } from '../../../../utils/errorParser';
import { speciesContentViolation } from '../../errors/action';

export function* setSwoContent({
  payload,
}: {
  type: typeof SwoContentTypes.SWO_SUBMIT;
  payload: SpeciesContent;
}) {
  try {
    const response = yield call(setSwoContentApi, payload);

    if (response.status === 200) {
      yield put(swoContentLoaded(response.data));
    } else {
      yield put(swoContentFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(swoContentFailure());
      yield put(
        speciesContentViolation(
          speciesContentViolationParser(err.response.data)
        )
      );
    }

    yield put(swoContentFailure());
  }
}

export function* getSwoContent() {
  try {
    const response = yield call(getSwoContentApi);
    if (response.status === 200) {
      yield put(swoContentLoaded(response.data));
    } else {
      yield put(swoContentFailure());
    }
  } catch (err) {
    yield put(swoContentFailure());
  }
}
