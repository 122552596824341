import i18n from '../i18n';
import {
  UserErrors,
  Failure,
  Validations,
  FilterErrors,
  PasswordErrors,
  PublicGeneratorContentErrors,
  HomeContentErrors,
  AboutContentErrors,
  SpeciesContentErrors,
  ByCatchContentErrors,
} from '../store/modules/errors/types';

export const userViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: UserErrors = {
    notFound: '',
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    ownedVessels: '',
    role: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'name':
        switch (type) {
          case Validations.MinLengthViolation:
            initialError.name = i18n.t('name_too_short');
            break;
          case Validations.MaxLengthViolation:
            initialError.name = i18n.t('name_too_long');
            break;
          case Validations.RequiredViolation:
            initialError.name = i18n.t('name_required');
            break;
          default:
            initialError.name = i18n.t('something_wrong_field');
        }
        break;

      case 'email':
        switch (type) {
          case Validations.AlreadyInUseViolation:
            initialError.email = i18n.t('email_already_used');
            break;
          case Validations.InvalidViolation:
            initialError.email = i18n.t('email_invalid');
            break;
          case Validations.RequiredViolation:
            initialError.email = i18n.t('email_required');
            break;
          default:
            initialError.email = i18n.t('something_wrong_field');
        }
        break;

      case 'password':
        switch (type) {
          case Validations.MinLengthViolation:
            initialError.password = i18n.t('password_too_short');
            break;
          case Validations.RequiredViolation:
            initialError.password = i18n.t('password_required');
            break;
          default:
            initialError.password = i18n.t('something_wrong_field');
        }
        break;

      case 'passwordConfirmation':
        switch (type) {
          case Validations.MinLengthViolation:
            initialError.passwordConfirmation = i18n.t('password_too_short');
            break;
          case Validations.RequiredViolation:
            initialError.passwordConfirmation = i18n.t('password_required');
            break;
          case Validations.DoestNotMatchViolation:
            initialError.passwordConfirmation = i18n.t('passwords_dont_match');
            break;
          default:
            initialError.passwordConfirmation = i18n.t('something_wrong_field');
        }
        break;

      case 'notFound':
        switch (type) {
          default:
            initialError.notFound = i18n.t('user_not_find');
        }
        break;
      case 'ownedVessels':
        switch (type) {
          case Validations.NotEmptyArrayViolation:
            initialError.ownedVessels = i18n.t('vessel_required');
            break;
          default:
            initialError.ownedVessels = i18n.t('something_wrong_field');
        }
        break;

      case 'role':
        switch (type) {
          case Validations.InvalidOptionViolation:
            initialError.role = i18n.t('invalid_operation');
            break;
          default:
            initialError.ownedVessels = i18n.t('something_wrong_field');
        }
        break;

      default:
    }
  });

  return initialError;
};

export const filterViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: FilterErrors = {
    notFound: '',
    name: '',
    period: '',
    groupers: '',
    variables: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'name':
        switch (type) {
          case Validations.MinLengthViolation:
            initialError.name = i18n.t('name_too_short');
            break;
          case Validations.MaxLengthViolation:
            initialError.name = i18n.t('name_too_long');
            break;
          case Validations.RequiredViolation:
            initialError.name = i18n.t('name_required');
            break;
          default:
            initialError.name = i18n.t('something_wrong_field');
        }
        break;

      case 'period':
      case 'period.dateArrivalStart':
      case 'period.dateArrivalEnd':
        switch (type) {
          case Validations.InvalidViolation:
          case Validations.InvalidPeriodViolation:
            initialError.period = i18n.t('no_empty_period_filters');
            break;
          default:
            initialError.period = i18n.t('something_wrong_field');
        }
        break;
      case 'groupers':
        switch (type) {
          case Validations.InvalidGroupersViolation:
            initialError.groupers = i18n.t('no_empty_groupers_filters');
            break;
          default:
            initialError.groupers = i18n.t('something_wrong_field');
        }
        break;

      case 'variables':
        switch (type) {
          case Validations.InvalidVariablesViolation:
            initialError.variables = i18n.t('no_empty_variables_filters');
            break;
          default:
            initialError.variables = i18n.t('something_wrong_field');
        }
        break;

      case 'notFound':
        switch (type) {
          default:
            initialError.notFound = i18n.t('filter_not_find');
        }
        break;

      default:
    }
  });

  return initialError;
};

export const passwordViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: PasswordErrors = {
    passwordRecovery: {
      email: '',
    },
    passwordChange: {
      keypass: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'email':
        switch (type) {
          case Validations.InvalidViolation:
            initialError.passwordRecovery.email = i18n.t('email_invalid');
            break;
          default:
            initialError.passwordRecovery.email = i18n.t(
              'something_wrong_field'
            );
        }
        break;

      case 'oldPassword':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.passwordChange.keypass = i18n.t('password_required');
            break;
          case Validations.InvalidViolation:
            initialError.passwordChange.keypass = i18n.t('invalid_password');
            break;

          default:
            initialError.passwordChange.keypass = i18n.t(
              'something_wrong_field'
            );
            break;
        }
        break;

      case 'newPassword':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.passwordChange.newPassword = i18n.t(
              'password_required'
            );
            break;

          case Validations.MinLengthViolation:
            initialError.passwordChange.newPassword = i18n.t(
              'password_too_short'
            );
            break;

          default:
            initialError.passwordChange.newPassword = i18n.t(
              'something_wrong_field'
            );
            break;
        }
        break;

      case 'passwordConfirmation':
      case 'newPasswordConfirmation':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.passwordChange.newPasswordConfirmation = i18n.t(
              'password_required'
            );
            break;

          case Validations.DoestNotMatchViolation:
            initialError.passwordChange.newPasswordConfirmation = i18n.t(
              'passwords_dont_match'
            );
            break;

          case Validations.MinLengthViolation:
            initialError.passwordChange.newPasswordConfirmation = i18n.t(
              'password_too_short'
            );
            break;

          default:
            initialError.passwordChange.newPasswordConfirmation = i18n.t(
              'something_wrong_field'
            );
            break;
        }
        break;

      case 'token':
        initialError.passwordChange.keypass = i18n.t('invalid_link');
        break;

      default:
        initialError.passwordRecovery.email = i18n.t('something_wrong_field');
    }
  });

  return initialError;
};

export const publicGeneratorContentViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: PublicGeneratorContentErrors = {
    title: '',
    text: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'text':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.text = i18n.t('text_content_required');
            break;
          default:
            initialError.text = i18n.t('something_wrong_field');
        }
        break;
      case 'title':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.title = i18n.t('title_content_required');
            break;
          default:
            initialError.title = i18n.t('something_wrong_field');
        }
        break;

      default:
        initialError.text = i18n.t('something_wrong_field');
    }
  });

  return initialError;
};

export const homeContentViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: HomeContentErrors = {
    title: '',
    introductoryText: '',
    lineChartText: '',
    mainSpeciesChartTitle: '',
    mainSpeciesChartText: '',
    heatMapTitle: '',
    heatMapText: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'title':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.title = i18n.t('text_content_required');
            break;
          default:
            initialError.title = i18n.t('something_wrong_field');
        }
        break;

      case 'introductoryText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.introductoryText = i18n.t('text_content_required');
            break;
          default:
            initialError.introductoryText = i18n.t('something_wrong_field');
        }
        break;

      case 'lineChartText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.lineChartText = i18n.t('text_content_required');
            break;
          default:
            initialError.lineChartText = i18n.t('something_wrong_field');
        }
        break;

      case 'mainSpeciesChartTitle':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.mainSpeciesChartTitle = i18n.t(
              'text_content_required'
            );
            break;
          default:
            initialError.mainSpeciesChartTitle = i18n.t(
              'something_wrong_field'
            );
        }
        break;

      case 'mainSpeciesChartText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.mainSpeciesChartText = i18n.t('text_content_required');
            break;
          default:
            initialError.mainSpeciesChartText = i18n.t('something_wrong_field');
        }
        break;

      case 'heatMapTitle':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.heatMapTitle = i18n.t('text_content_required');
            break;
          default:
            initialError.heatMapTitle = i18n.t('something_wrong_field');
        }
        break;

      case 'heatMapText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.heatMapText = i18n.t('text_content_required');
            break;
          default:
            initialError.heatMapText = i18n.t('something_wrong_field');
        }
        break;

      default:
    }
  });

  return initialError;
};

export const aboutContentViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: AboutContentErrors = {
    title: '',
    text: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'title':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.title = i18n.t('title_content_required');
            break;
          default:
            initialError.title = i18n.t('something_wrong_field');
        }
        break;

      case 'text':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.text = i18n.t('text_content_required');
            break;
          default:
            initialError.text = i18n.t('something_wrong_field');
        }
        break;

      default:
        initialError.text = i18n.t('something_wrong_field');
    }
  });

  return initialError;
};

export const speciesContentViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: SpeciesContentErrors = {
    title: '',
    introductoryText: '',
    lineChartText: '',
    heatMapText: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'title':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.title = i18n.t('title_content_required');
            break;
          default:
            initialError.title = i18n.t('something_wrong_field');
        }
        break;
      case 'introductoryText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.introductoryText = i18n.t('text_content_required');
            break;
          default:
            initialError.introductoryText = i18n.t('something_wrong_field');
        }
        break;
      case 'lineChartText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.lineChartText = i18n.t('text_content_required');
            break;
          default:
            initialError.lineChartText = i18n.t('something_wrong_field');
        }
        break;

      case 'heatMapText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.heatMapText = i18n.t('text_content_required');
            break;
          default:
            initialError.heatMapText = i18n.t('something_wrong_field');
        }
        break;
      default:
        initialError.title = i18n.t('something_wrong_field');
    }
  });

  return initialError;
};

export const byCatchContentViolationParser = (failure: Failure) => {
  const { violations } = failure;

  const initialError: ByCatchContentErrors = {
    title: '',
    introductoryText: '',
    lineChartText: '',
    mainSpeciesChartText: '',
    heatMapText: '',
  };

  violations.forEach(({ target, type }) => {
    switch (target) {
      case 'title':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.title = i18n.t('text_content_required');
            break;
          default:
            initialError.title = i18n.t('something_wrong_field');
        }
        break;

      case 'introductoryText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.introductoryText = i18n.t('text_content_required');
            break;
          default:
            initialError.introductoryText = i18n.t('something_wrong_field');
        }
        break;

      case 'lineChartText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.lineChartText = i18n.t('text_content_required');
            break;
          default:
            initialError.lineChartText = i18n.t('something_wrong_field');
        }
        break;

      case 'mainSpeciesChartText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.mainSpeciesChartText = i18n.t('text_content_required');
            break;
          default:
            initialError.mainSpeciesChartText = i18n.t('something_wrong_field');
        }
        break;

      case 'heatMapText':
        switch (type) {
          case Validations.RequiredViolation:
            initialError.heatMapText = i18n.t('text_content_required');
            break;
          default:
            initialError.heatMapText = i18n.t('something_wrong_field');
        }
        break;

      default:
    }
  });

  return initialError;
};
