import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface PrivateRouteProps {
  isSignedIn: boolean;
  exact: boolean;
  component: React.ElementType;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (
  props: PrivateRouteProps
) => {
  const { isSignedIn, component: Component, exact, path } = props;

  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (isSignedIn) {
          return <Component />;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
