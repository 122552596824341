import api from './api';
import { getAccessToken } from '../localStorageService';
import { HomeContent } from '../../store/modules/content/home/types';
import { PublicDataGeneratorContent } from '../../store/modules/content/publicDataGenerator/types';
import { AboutContent } from '../../store/modules/content/about/types';
import { ByCatchContent } from '../../store/modules/content/byCatch/types';
import { SpeciesContent } from '../../store/modules/content/types';

export const getAboutContent = () => {
  return api.get('/about-content');
};

export const setAboutContent = (data: AboutContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/about-content', data, config);
};

export const getByCatchContent = () => {
  return api.get('/bycatch-content');
};

export const setByCatchContent = (data: ByCatchContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/bycatch-content', data, config);
};

export const getHomeContent = () => {
  return api.get('/home-content');
};

export const setHomeContent = (data: HomeContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/home-content', data, config);
};

export const getPublicDataGeneratorContent = () => {
  return api.get('/public-data-generator-content');
};

export const setPublicDataGeneratorContent = (
  data: PublicDataGeneratorContent
) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('public-data-generator-content', data, config);
};

export const getSwoContent = () => {
  return api.get('/swo-content');
};

export const setSwoContent = (data: SpeciesContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/swo-content', data, config);
};

export const getYftContent = () => {
  return api.get('/yft-content');
};

export const setYftContent = (data: SpeciesContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/yft-content', data, config);
};

export const getBetContent = () => {
  return api.get('/bet-content');
};

export const setBetContent = (data: SpeciesContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/bet-content', data, config);
};

export const getAlbContent = () => {
  return api.get('/alb-content');
};

export const setAlbContent = (data: SpeciesContent) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  return api.post('/alb-content', data, config);
};
