import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '1rem',
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.spacing(30),
    paddingBottom: theme.spacing(4),
    background: '#363740',
    color: '#fff',
    border: '0px',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    padding: theme.spacing(0.5),
    height: '100%',
  },
  divImg: {
    width: '100%',
    backgroundColor: '#FFF',
  },
  img: {
    width: '90%',
    marginBottom: theme.spacing(3),
  },
}));

export default Styles;
