import { Vessel } from '../vessel/types';
/**
 * actions types
 */
export enum UsersTypes {
  CLEAR_USER_STATE = '@users/CLEAR_USER_STATE',
  GET_USER_REQUEST = '@users/GET_USER_REQUEST',
  GET_USER_SUCCESS = '@users/GET_USER_SUCCESS',
  LIST_USERS_REQUEST = '@users/LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS = '@users/LIST_USERS_SUCCESS',
  LIST_USERS_FAILURE = '@users/LIST_USERS_FAILURE',
  DELETE_USER = '@users/DELETE_USER',
  ADD_USER = '@users/ADD_USER',
  UPDATE_USER = '@users/UPDATE_USER',
  USER_SUCCESS = '@users/USER_SUCCESS',
  USER_FAILURE = '@users/USER_FAILURE',
  GET_ME_REQUEST = '@users/GET_ME_REQUEST',
  GET_ME_SUCCESS = '@users/GET_ME_SUCCESS',
  REMOVE_ME = '@users/REMOVE_ME',
}

/**
 * data types
 */
export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  ownedVessels: Vessel[];
}

export interface NewUser {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  role: string;
  ownedVessels: Vessel[];
}

export interface MyUser {
  id: string;
  name: string;
  email: string;
  role: string;
}

/**
 * state type
 */
export interface UsersState {
  readonly data: User[];
  readonly totalUsers: number;
  readonly listUsersLoading: boolean;
  readonly listUsersError: boolean;
  readonly userLoading: boolean;
  readonly userSuccess: boolean;
  readonly userError: boolean;
  readonly user: User;
  readonly myUser: MyUser;
}
