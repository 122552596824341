import React from 'react';

import { Box, Container } from '@material-ui/core';
import Styles from './styles';

interface Props {
  title: string;
  children: React.ReactNode;
}

const TopBoxContainer: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { title, children } = props;

  return (
    <Container>
      <Box boxShadow={4} className={styles.root}>
        <p>{title}</p>
      </Box>
      <div className={styles.internContent}>{children}</div>
    </Container>
  );
};

export default TopBoxContainer;
