import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import i18n from '../../../i18n';

/**
 * actions types
 */
export enum FiltersTypes {
  CLEAR_FILTER_STATE = '@filters/CLEAR_FILTER_STATE',
  GET_FILTER_REQUEST = '@filters/GET_FILTER_REQUEST',
  GET_FILTER_SUCCESS = '@filters/GET_FILTER_SUCCESS',
  LIST_FILTERS_REQUEST = '@filters/LIST_FILTERS_REQUEST',
  LIST_FILTERS_SUCCESS = '@filters/LIST_FILTERS_SUCCESS',
  LIST_FILTERS_FAILURE = '@filters/LIST_FILTERS_FAILURE',
  DELETE_FILTER = '@filters/DELETE_FILTER',
  ADD_FILTER = '@filters/ADD_FILTER',
  UPDATE_FILTER = '@filters/UPDATE_FILTER',
  FILTER_SUCCESS = '@filters/FILTER_SUCCESS',
  FILTER_FAILURE = '@filters/FILTER_FAILURE',
}

/**
 * data types
 */
export interface PeriodFilter {
  dateArrivalStart: string;
  dateArrivalEnd: string;
}

export interface GrouperFilter {
  year: boolean;
  month: boolean;
  portArrival: boolean;
  portArrivalOptions: string[];
  species: boolean;
  speciesOptions: string[];
}

export interface VariableFilter {
  catchQuilograms: boolean;
  fishQuantity: boolean;
  fishingTripQuantity: boolean;
  setQuantity: boolean;
  vesselsQuantity: boolean;
}

/**
 * ToString methods
 */
export const PeriodToString = (period: PeriodFilter) => {
  return `${
    period.dateArrivalStart === undefined || period.dateArrivalStart === null
      ? ''
      : format(new Date(period.dateArrivalStart), 'MMM/yy', {
          locale: ptBR,
        })
  } - ${
    period.dateArrivalEnd === undefined || period.dateArrivalEnd === null
      ? ''
      : format(new Date(period.dateArrivalEnd), 'MMM/yy', {
          locale: ptBR,
        })
  }`;
};

export const GrouperToString = (groupers: GrouperFilter) => {
  let stringGroupers = '';

  if (groupers.year) {
    stringGroupers = stringGroupers.concat(`${i18n.t('year')}; `);
  }
  if (groupers.month) {
    stringGroupers = stringGroupers.concat(`${i18n.t('month')}; `);
  }
  if (groupers.portArrival) {
    if (
      groupers.portArrivalOptions === undefined ||
      groupers.portArrivalOptions.length === 0
    ) {
      stringGroupers = stringGroupers.concat(`${i18n.t('all_county')}; `);
    } else {
      groupers.portArrivalOptions.forEach((port) => {
        stringGroupers = stringGroupers.concat(`${port},`);
      });
      stringGroupers = stringGroupers.concat(';');
    }
  }
  if (groupers.species) {
    if (
      groupers.speciesOptions === undefined ||
      groupers.speciesOptions.length === 0
    ) {
      stringGroupers = stringGroupers.concat(`${i18n.t('all_species')}; `);
    } else {
      stringGroupers = stringGroupers.concat(
        groupers.speciesOptions.length > 1
          ? `${groupers.speciesOptions.length} ${i18n.t('species_plural')};`
          : `${groupers.speciesOptions.length}  ${i18n.t('species_singular')};`
      );
    }
  }
  return stringGroupers;
};

export const VariableToString = (variables: VariableFilter) => {
  let stringVariables = '';

  if (variables.catchQuilograms) {
    stringVariables = stringVariables.concat(`${i18n.t('weight')}; `);
  }
  if (variables.fishQuantity) {
    stringVariables = stringVariables.concat(`${i18n.t('units')}; `);
  }
  if (variables.fishingTripQuantity) {
    stringVariables = stringVariables.concat(`${i18n.t('trips')}; `);
  }
  if (variables.setQuantity) {
    stringVariables = stringVariables.concat(`${i18n.t('throws')}; `);
  }
  if (variables.vesselsQuantity) {
    stringVariables = stringVariables.concat(`${i18n.t('vessels')}; `);
  }

  return stringVariables;
};

export interface Filter {
  id?: string;
  name?: string;
  period: PeriodFilter;
  groupers: GrouperFilter;
  variables: VariableFilter;
}

/**
 * state type
 */
export interface FiltersState {
  readonly data: Filter[];
  readonly filter: Filter;
  readonly listFiltersLoading: boolean;
  readonly listFiltersError: boolean;
  readonly filterLoading: boolean;
  readonly filterSuccess: boolean;
  readonly filterError: boolean;
}
