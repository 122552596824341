import { call, put } from 'redux-saga/effects';
import { AlbContentTypes, SpeciesContent } from './types';
import {
  getAlbContent as getAlbContentApi,
  setAlbContent as setAlbContentApi,
} from '../../../../services/api/contentApi';

import { albContentLoaded, albContentFailure } from './actions';

import { speciesContentViolationParser } from '../../../../utils/errorParser';
import { speciesContentViolation } from '../../errors/action';

export function* setAlbContent({
  payload,
}: {
  type: typeof AlbContentTypes.ALB_SUBMIT;
  payload: SpeciesContent;
}) {
  try {
    const response = yield call(setAlbContentApi, payload);

    if (response.status === 200) {
      yield put(albContentLoaded(response.data));
    } else {
      yield put(albContentFailure());
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(albContentFailure());
      yield put(
        speciesContentViolation(
          speciesContentViolationParser(err.response.data)
        )
      );
    }

    yield put(albContentFailure());
  }
}

export function* getAlbContent() {
  try {
    const response = yield call(getAlbContentApi);
    if (response.status === 200) {
      yield put(albContentLoaded(response.data));
    } else {
      yield put(albContentFailure());
    }
  } catch (err) {
    yield put(albContentFailure());
  }
}
