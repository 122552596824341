import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(() => ({
  root: {},
  content: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
  },
}));

export default Styles;
