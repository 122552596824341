import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './styles';

const WrongCredentials: React.FC = () => {
  const styles = Styles();
  const { t } = useTranslation();
  return <div className={styles.root}>{t('invalid_credentials')}</div>;
};

export default WrongCredentials;
