import { Reducer } from 'redux';
import { SpeciesContentState, YftContentTypes } from './types';

const INITIAL_STATE: SpeciesContentState = {
  content: {
    title: '',
    introductoryText: '',
    lineChartText: '',
    heatMapText: '',
  },
  loaded: false,
  loading: false,
  error: false,
};

const reducer: Reducer<SpeciesContentState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case YftContentTypes.YFT_SUBMIT:
    case YftContentTypes.YFT_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: false,
      };
    case YftContentTypes.YFT_LOADED:
      return {
        ...state,
        content: action.payload,
        loaded: true,
        loading: false,
        error: false,
      };
    case YftContentTypes.YFT_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
