import { action } from 'typesafe-actions';
import { ReportTypes, ReportData, CountData } from './types';
import {
  Filter,
  PeriodFilter,
  GrouperFilter,
  VariableFilter,
} from '../filters/types';

export const loadRequestReport = (filters: Filter) =>
  action(ReportTypes.LOAD_REQUEST_REPORT, filters);
export const loadSuccessReport = (
  reportData: ReportData[],
  countData: CountData
) => action(ReportTypes.LOAD_SUCCESS_REPORT, { reportData, countData });

export const loadRequestCsv = (filters: Filter) =>
  action(ReportTypes.LOAD_REQUEST_CSV, filters);
export const loadSuccessCsv = () => action(ReportTypes.LOAD_SUCCESS_CSV);

export const loadFailure = () => action(ReportTypes.LOAD_FAILURE);

export const setPeriodFilter = (period: PeriodFilter) =>
  action(ReportTypes.SET_PERIOD_FILTER, { period });
export const setGroupersFilter = (groupers: GrouperFilter) =>
  action(ReportTypes.SET_GROUPERS_FILTER, { groupers });
export const setVariablesFilter = (variables: VariableFilter) =>
  action(ReportTypes.SET_VARIABLES_FILTER, { variables });

export const setFilter = (filter: Filter) =>
  action(ReportTypes.SET_FILTER, { filter });
