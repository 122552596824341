import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../../../../store/modules/filters/types';
import Styles from './styles';

interface Props {
  filter: Filter;
  loadRequestReport(filters: Filter): void;
}

const GenerateTableButton: React.FC<Props> = (props: Props) => {
  const { loadRequestReport, filter } = props;
  const styles = Styles();
  const { t } = useTranslation();

  return (
    <div>
      <Button
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => loadRequestReport(filter)}
        className={styles.root}
      >
        {t('generate_report')}
      </Button>
    </div>
  );
};

export default GenerateTableButton;
