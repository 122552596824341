import { makeStyles, createStyles } from '@material-ui/core/styles';

const Styles = makeStyles(() =>
  createStyles({
    root: {
      color: 'rgba(0, 0, 0, 0.54)',
      letterSpacing: '0.25px',
      borderBottom: '1px solid  #BDBDBD',
      margin: '20px',
    },
  })
);

export default Styles;
