import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@material-ui/core';
import GetAppRounded from '@material-ui/icons/GetAppRounded';
import Styles from './styles';
import { CountData, ReportData } from '../../../../store/modules/report/types';
import TopBoxContainer from '../../../../components/TopBoxContainer';
import Table from './Table';

interface Props {
  data: ReportData[];
  countData: CountData;
  dataHeader: string[];
  isLoading: boolean;
  error: boolean;
  csvDownload: () => void;
}

const PublicGeneratorTable: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { t } = useTranslation();

  const { data, countData, dataHeader, isLoading, error, csvDownload } = props;
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    if (isLoading && !isRender) {
      setIsRender(true);
    }
  }, [isLoading, isRender]);

  return (
    <div>
      {isRender && (
        <TopBoxContainer title={t('result')}>
          <div>
            <div className={styles.ButtonGroup}>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
              >
                <Button startIcon={<GetAppRounded />} onClick={csvDownload}>
                  {t('csv')}
                </Button>
              </ButtonGroup>
            </div>
            {error && !isLoading && (
              <div>
                <h2 className={styles.error}>{t('something_wrong')}</h2>
              </div>
            )}
            {!error && (
              <div>
                {dataHeader.length === 0 && <p>{t('result_not_found')}</p>}
                <Table Header={dataHeader} Rows={data} countData={countData} />
              </div>
            )}
          </div>
        </TopBoxContainer>
      )}
    </div>
  );
};

export default PublicGeneratorTable;
