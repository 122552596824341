import { call, put } from 'redux-saga/effects';
import {
  fisheryByCatchDataSuccess,
  fisheryByCatchDataFailure,
} from './actions';
import { getFisheryByCatchDataChart } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryByCatchDataChart() {
  try {
    const response = yield call(getFisheryByCatchDataChart);
    if (response.status === 200) {
      yield put(fisheryByCatchDataSuccess(response.data.data));
    } else if (response.status === 401) {
      yield put(fisheryByCatchDataFailure());
    } else {
      yield put(fisheryByCatchDataFailure());
    }
  } catch (err) {
    yield put(fisheryByCatchDataFailure());
  }
}
