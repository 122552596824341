import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormHandles } from '@unform/core';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UsersState, NewUser } from '../../../store/modules/users/types';
import TopBoxContainer from '../../../components/TopBoxContainer';
import Styles from './styles';
import * as UsersAction from '../../../store/modules/users/actions';
import { ApplicationState } from '../../../store';
import LoaderTailSpin from '../../../components/LoaderTailSpin';
import { ErrorState } from '../../../store/modules/errors/types';
import TitleLine from '../../../components/TitleLine';
import { Vessel, VesselState } from '../../../store/modules/vessel/types';
import * as VesselAction from '../../../store/modules/vessel/actions';

interface StateProps {
  users: UsersState;
  errors: ErrorState;
  vessels: VesselState;
}

interface DispatchProps {
  addNewUserRequest(newUser: NewUser): void;
  clearUserViolation(): void;
  clearUserState(): void;
  vesselRequest(): void;
}

type Props = StateProps & DispatchProps;

const CreateUser: React.FC<Props> = (props: Props) => {
  const {
    users,
    errors,
    addNewUserRequest,
    clearUserState,
    clearUserViolation,
    vessels,
    vesselRequest,
  } = props;
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [role, setRole] = useState('admin');
  const [ownedVessels, setOwnedVessel] = useState<Vessel[]>([]);
  const [vesselsIdValue, setVesselsIdValue] = useState<string[]>([]);

  const styles = Styles();

  useEffect(() => {
    clearUserState();
    clearUserViolation();
    vesselRequest();
  }, [clearUserState, clearUserViolation, vesselRequest]);

  useEffect(() => {
    if (users.userSuccess) {
      history.goBack();
      clearUserState();
    }
  }, [clearUserState, history, users.userSuccess]);

  useEffect(() => {
    setOwnedVessel(
      vesselsIdValue.map((id) =>
        vessels.vessels.find((vessel) => vessel.externalId === id)
      ) as Vessel[]
    );
  }, [
    clearUserState,
    clearUserViolation,
    vesselRequest,
    vessels.vessels,
    vesselsIdValue,
  ]);

  const handleSubmit = useCallback(async () => {
    await addNewUserRequest({
      name,
      email,
      password,
      passwordConfirmation,
      role,
      ownedVessels,
    });
  }, [
    addNewUserRequest,
    name,
    email,
    password,
    passwordConfirmation,
    role,
    ownedVessels,
  ]);

  return (
    <div className={styles.root}>
      <TitleLine title={t('users')} />
      <TopBoxContainer title={t('register_user')}>
        <div>
          <Form
            noValidate
            ref={formRef}
            className={styles.internContent}
            onSubmit={handleSubmit}
          >
            <div className={styles.dividerContainer}>
              <div className={styles.formStyles}>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  id="name"
                  name="name"
                  label={t('name')}
                  type="text"
                  fullWidth
                  required
                  error={errors.user.name !== ''}
                  helperText={errors.user.name}
                />
              </div>
              <div className={styles.formStyles}>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  label={t('email')}
                  type="text"
                  fullWidth
                  required
                  error={errors.user.email !== ''}
                  helperText={errors.user.email}
                />
              </div>
            </div>
            <div className={styles.dividerContainer}>
              <div className={styles.formStyles}>
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  label={t('password')}
                  type="password"
                  fullWidth
                  required
                  error={errors.user.password !== ''}
                  helperText={errors.user.password}
                />
              </div>
              <div className={styles.formStyles}>
                <TextField
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  label={t('confirm_password')}
                  type="password"
                  fullWidth
                  required
                  error={errors.user.passwordConfirmation !== ''}
                  helperText={errors.user.passwordConfirmation}
                />
              </div>
            </div>
            <Grid item sm={6} md={8} className={styles.roles}>
              <FormControl>
                <FormLabel component="legend">{t('profile')}</FormLabel>
                <RadioGroup
                  defaultValue="admin"
                  aria-label="role"
                  name="customized-radios"
                  className={styles.radioButton}
                  onChange={(button) => {
                    setRole(button.target.value);
                  }}
                >
                  <FormControlLabel
                    value="admin"
                    control={<Radio color="primary" />}
                    label={t('admin')}
                  />
                  <FormControlLabel
                    value="shipowner"
                    control={<Radio color="primary" />}
                    label={t('shipowner')}
                  />
                </RadioGroup>
              </FormControl>

              {role === 'shipowner' && (
                <FormControl className={styles.formControl}>
                  <InputLabel id="input-specie">Embarcações</InputLabel>
                  <Select
                    labelId="specie-select-label"
                    id="specie-select-id"
                    multiple
                    value={vesselsIdValue}
                    onChange={(e) => {
                      setVesselsIdValue(e.target.value as string[]);
                    }}
                    renderValue={() => (
                      <div className={styles.chips}>
                        {vesselsIdValue.map((value) => (
                          <Chip
                            key={value}
                            label={
                              vessels.vessels.find(
                                (e) => e.externalId === value
                              )?.name
                            }
                            className={styles.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {vessels.vessels.map((row) => (
                      <MenuItem key={row.externalId} value={row.externalId}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.user.ownedVessels && (
                    <p className={styles.errorMessage}>
                      {errors.user.ownedVessels}
                    </p>
                  )}
                </FormControl>
              )}
            </Grid>
            {users.userLoading && <LoaderTailSpin />}
            {!users.userLoading && (
              <Button
                type="submit"
                variant="contained"
                className={styles.button}
              >
                {t('save')}
              </Button>
            )}
          </Form>
        </div>
      </TopBoxContainer>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    users: state.users,
    errors: state.errors,
    vessels: state.vessel,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UsersAction, ...VesselAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
