import { call, put } from 'redux-saga/effects';
import {
  fisheryAlbProductionDataSuccess,
  fisheryAlbProductionDataFailure,
} from './actions';
import { getFisheryAlbProductionData } from '../../../../../services/api/dataProviderApi';

export function* loadFisheryAlbProductionData() {
  try {
    const response = yield call(getFisheryAlbProductionData);
    if (response.status === 200) {
      yield put(fisheryAlbProductionDataSuccess(response.data));
    } else if (response.status === 401) {
      yield put(fisheryAlbProductionDataFailure());
    } else {
      yield put(fisheryAlbProductionDataFailure());
    }
  } catch (err) {
    yield put(fisheryAlbProductionDataFailure());
  }
}
