/**
 * actions types
 */
export enum FisheryAlbProductionDataTypes {
  FISHERY_ALB_PRODUCTION_REQUEST = '@chart/FISHERY_ALB_PRODUCTION_REQUEST',
  FISHERY_ALB_PRODUCTION_SUCCESS = '@chart/FISHERY_ALB_PRODUCTION_SUCCESS',
  FISHERY_ALB_PRODUCTION_FAILURE = '@chart/FISHERY_ALB_PRODUCTION_FAILURE',
}

export type {
  FisheryProductionData,
  FisheryProductionDataState,
} from '../../types';
