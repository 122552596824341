import React from 'react';
import { Container } from '@material-ui/core';
import Styles from './styles';

interface Props {
  title: string;
}

const TitleLine: React.FC<Props> = (props: Props) => {
  const styles = Styles();
  const { title } = props;

  return (
    <Container>
      <h1 className={styles.root}>{title}</h1>
    </Container>
  );
};

export default TitleLine;
