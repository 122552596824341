import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Users from '../Users';
import CreateUser from '../Users/CreateUser';
import UpdateUser from '../Users/UpdateUser';
import Filters from '../Filters';
import FormFilter from '../Filters/FormFilter';
import FishingTrip from '../FishingTrip';
import PublicGeneratorContent from '../ContentManagement/PublicGenetatorContent';
import AboutContent from '../ContentManagement/AboutContent';
import ByCatchContent from '../ContentManagement/ByCatch';
import HomeContent from '../ContentManagement/HomeContent';
import SwoContent from '../ContentManagement/SpeciesContent/swoContent.container';
import YftContent from '../ContentManagement/SpeciesContent/yftContent.container';
import BetContent from '../ContentManagement/SpeciesContent/betContent.container';
import AlbContent from '../ContentManagement/SpeciesContent/albContent.container';
import ChangePassword from '../Password/ChangePassword';

export default function Router() {
  return (
    <Switch>
      <Route path="/mainpage/users" exact component={Users} />
      <Route path="/mainpage/users/new-user" exact component={CreateUser} />
      <Route
        path="/mainpage/users/update-user/:userId"
        component={UpdateUser}
      />
      <Route path="/mainpage/filters" exact component={Filters} />
      <Route path="/mainpage/filters/new-filter" component={FormFilter} />
      <Route
        path="/mainpage/filters/update-filter/:filterId"
        component={() => <FormFilter isEdit />}
      />

      <Route path="/mainpage/fishing-trips" component={FishingTrip} />

      <Route
        path="/mainpage/public-generator-content"
        exact
        component={PublicGeneratorContent}
      />

      <Route path="/mainpage/home-content" exact component={HomeContent} />

      <Route path="/mainpage/about-content" exact component={AboutContent} />

      <Route path="/mainpage/swo-content" exact component={SwoContent} />

      <Route path="/mainpage/yft-content" exact component={YftContent} />

      <Route path="/mainpage/bet-content" exact component={BetContent} />

      <Route path="/mainpage/alb-content" exact component={AlbContent} />

      <Route
        path="/mainpage/bycatch-content"
        exact
        component={ByCatchContent}
      />

      <Route
        path="/mainpage/change-password"
        exact
        component={ChangePassword}
      />
    </Switch>
  );
}
