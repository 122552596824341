import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Styles from './styles';

interface Props {
  text: string;
  link: string;
  icon: JSX.Element;
  nested?: boolean;
}

const MenuItem: React.FC<Props> = (props: Props) => {
  const { text, link, icon, nested } = props;

  const classes = Styles();

  return (
    <ListItem
      button
      component={Link}
      to={link}
      className={nested ? classes.nested : ''}
    >
      <ListItemIcon className={classes.menuItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: classes.itemText }}
        primary={text}
      />
    </ListItem>
  );
};

export default MenuItem;
