import { Reducer } from 'redux';
import { ReportState, ReportTypes } from './types';
import extractLabelsFromReportData from '../../../utils/extractLabelsFromReportData';

const INITIAL_STATE: ReportState = {
  reportData: [],
  dataHeader: [],
  countData: {},
  filter: {
    period: {
      dateArrivalStart: '',
      dateArrivalEnd: '',
    },
    groupers: {
      year: false,
      month: false,
      portArrival: false,
      portArrivalOptions: [],
      species: false,
      speciesOptions: [],
    },
    variables: {
      catchQuilograms: false,
      fishQuantity: false,
      fishingTripQuantity: false,
      setQuantity: false,
      vesselsQuantity: false,
    },
  },
  loading: false,
  error: false,
  successCsv: false,
};

const reducer: Reducer<ReportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportTypes.LOAD_REQUEST_REPORT:
      return { ...state, loading: true, error: false };
    case ReportTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        successCsv: false,
      };
    case ReportTypes.LOAD_SUCCESS_REPORT:
      return {
        ...state,
        loading: false,
        error: false,
        reportData: action.payload.reportData,
        countData: action.payload.countData,
        dataHeader: extractLabelsFromReportData(action.payload.reportData),
      };
    case ReportTypes.LOAD_REQUEST_CSV:
      return { ...state, loading: true, error: false, successCsv: false };
    case ReportTypes.LOAD_SUCCESS_CSV:
      return {
        ...state,
        loading: false,
        error: false,
        successCsv: true,
      };
    case ReportTypes.SET_PERIOD_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          period: action.payload.period,
        },
      };
    case ReportTypes.SET_GROUPERS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          groupers: action.payload.groupers,
        },
      };
    case ReportTypes.SET_VARIABLES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          variables: action.payload.variables,
        },
      };
    case ReportTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export default reducer;
